<template>

		<GigPageWrapper
			:gig-id="String($attrs.gigId)" 
			current-route="details"
			@loaded="onCalendarWrapperLoaded"
		>
    <InlineNotification Heading="Gig details" class="">
        <template slot="body">
          <p>Fill in the details below in order to create the gig.</p>
        </template> 
    </InlineNotification> 


                <form class="form gap-3 pb-48" ref="form" novalidate>


                        <h2 class="col-span-full ">Where and when</h2>

                    <div class="col-span-full sm:col-span-3 input-group">
                        <label for="event-start">
                            Start Date/Time
                        </label>
                      <div class="grid grid-cols-3 gap-4">
                        <div class="col-span-3 lg:col-span-1">
                          <input ref="start" type="date" v-model="local_start_date" class="datepick h-full" required/>
                        </div>
                        <div class="col-span-3 lg:col-span-2">
                          <div class="flex flex-row items-center">
                            <TSelect v-model="start_hour" :options="populateTime(24)">

                            </TSelect>
                            <span class="mx-2">:</span>
                            <TSelect v-model="start_minute" ref="" :options="populateTime(60)">

                            </TSelect>
                          </div>
                        </div>
                      </div>
                        <div class="input-error">
                        </div>
                           
                    </div>
 

                    
                    <div class="col-span-full sm:col-span-3 input-group">
                        <label for="event-start">
                            End Date/Time (<span v-if="endDate" v-html="endDate.toFormat('dd/MM/yyyy HH:mm')"></span>)
                        </label>
                        <div class="flex flex-row items-center">
                            <TSelect v-model="end_hour" :options="populateTime(24)">
                            
                            </TSelect>
                            <span class="mx-2">:</span>
                            <TSelect v-model="end_minute" ref="" :options="populateTime(60)">
                            
                            </TSelect>
                        </div>
                    </div>
                    <div class="input-group col-span-full sm:col-span-3">
                        <label for="venue" class="label"> 
                            Venue
                        </label>
                        <div class="mt-1">
                            <TRichSelect 
															required 
															id="venue-selection" 
															name="venue-selection" class="trichselect"                    
															:options="venues"  
															error="A venue must be selected" 
															value-attribute="id" 
															text-attribute="name" 
															v-model="selectedVenueId"
														/>
                        </div>
                                    
                        <div class="input-error">
                        </div>
                    </div>

                    <div  class="sm:col-span-3 hidden">
                        <label for="team-member" class="block text-sm font-medium text-gray-700">
                            Venue's Primary Contact
                        </label>
                        <div class="mt-1">                            
                            <select id="team-member" name="team-member">
                                <option>Select a team member</option>
                            </select>
                        </div>
                     </div>

										<!-- 				
											v-on:input="form.name = $event;" 
											v-bind:value="form.name"  
										-->
                    <div  class="col-span-full sm:col-span-3 input-group" >
											<InputText
												required 
												id="name" 
												v-model="form.name"
												label="Event Name" 
												hint="The event name must be populated e.g. Thursdays at Corkies"
												error="The event name must be completed" 
												name="name"
                      />
                        
                     </div>
                     
                     <div v-if="item == null && gigtemplates && gigtemplates.length > 0" class="col-span-full grid gap-x-24  grid-cols-6" v-show="this.gigtemplates && this.gigtemplates.length > 0">
                        <h2 class="mt-8 col-span-full"><em>(Optional)</em> Speed up the process by using a template</h2>
 
                        <div class=" col-span-3 ">
                            <label for="enabled col-start-full block">
                                Apply an existing gig template
                            </label>
                            <div>
                            <TRichSelect id="template-selection" v-model="gig_template_id" @change="applyTemplate" class="col-span-3 trichselect" ref="templatepicker" name="template-selection"                      
                                :options="gigtemplates"
                                value-attribute="id" placeholder="Choose a template to apply"
                                text-attribute="name" />
                            </div>
                        </div> 
                     
                    </div>

										
										<!-- <div class="form gap-3 col-span-full"> -->
											<h2 class="col-span-full my-4">
												Budget and artist requirements
											</h2>
											<div  class="block col-span-3 input-group" >
												<label  class="block text-sm font-medium text-gray-700">
                            Add a new type of artist:
                        </label>

                        <div class="mt-1">  
                            <TRichSelect 
															id="typeSelection" 
															ref="typeSelection" 
															name="type-selection" 
															class="trichselect" 
															:options="loadedTiers"
                              value-attribute="id" 
															@change="selectArtistType($event)" placeholder="Choose a type of artist"
                              text-attribute="name"                             
                            ></TRichSelect>
                        </div>
											</div>

											<div 
												v-if="selectedVenueMultipleBookings" 
												class="block col-span-2 input-group"
											>
												<label  class="block text-sm font-medium text-gray-700">
														Mark as confirmed when:
													</label>

													<div class="mt-1 fix-input-to-match-select">  
														<InputText 
															type="number" 
															placeholder="1" 
															name="maxBookings"
															:suffix="maxBookingsSuffix"
															v-model="maxBookings"
															:disabled="disableMaxBookings"
															min="0"
															class="fix-input-to-match-select-input"
														/>
													</div>
											</div>
										<!-- </div> -->
										
                
										
										<!-- FEE's -->
										<!-- 
											:tiers="selectedTiers"
											v-on:changeTiers="getFees($event)" 
										-->
                    <Fees 
											ref="fees" 
											:gig-tiers="fees"
										></Fees>
                     
                                        
                    <div id="scrollmove" class="pb-12"></div>

                     <div
                class="hidden  sm:grid-cols-4 grid-flow-col col-span-full sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="paused"
                  class="block text-sm font-medium text-gray-700 sm:mt-px justify-center"
                >
                  Mark this gig as fully confirmed.  This will stop any new offers coming in and show as completed.  Existing offers can still be actioned.
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  
                  <t-toggle name="paused" id="paused" v-model="form.paused" ref="paused"></t-toggle>
                </div>
              </div>  
                </form>
                
                <div class="action-bar">       
										<template v-if="this.item && !this.item.published">
											<button @click="save(true)" class="cta bg-indigo-500">Offer gig to artists</button>
										</template>         
                    
										<template v-else-if="isGigCancelled">
											<span class="text-xs uppercase justify-center h-full text-gray-600">This gig has been cancelled</span>
										</template>
										<template v-else-if="this.item && this.item.published && this.item.bookings.length == 0">
											<span class="text-xs uppercase justify-center h-full text-gp_pink-default-500">GigPig is looking for artists</span>
										</template>
                    
										<GPButton 
											@click.native="save(false)" 
											:disabled="isLoading || isGigCancelled" 
											:loading="isLoading"
											size="lg"
										>{{ this.CreateOrUpdate }}</GPButton> 
                    
										<template v-if="showCancelButton">
											<GPButton color="gray" size="lg" @click.native="onCancelBooking">Cancel gig</GPButton>   
										</template>				       
                    
                </div>

        <GigTemplateCopy ref="TemplateCopyModal" v-on:templateSelection="applyCopyTemplate($event)" />
        <NotificationSmall ref="notification" />


				<!-- Modals -->
				<template v-if="item">
					<CancelBooking	
						identity="cancelBookingModal"
						:is-open="cancelBookingModalIsOpen" 
						:gig-id="item.id"
						:gig-name="item.name"
						:gig-start="item.start"
						:booking="selectedBooking"
						@closed="onModalClosed"
						@deleted="onBookingDeleted"
					></CancelBooking>
				</template>


		</GigPageWrapper>
</template>

<script>  
import Pristine from '../../utils/pristine.js'
import GigTemplateCopy from '../../components/modal/GigTemplateCopy.vue' 
import Fees from '../../components/gigs/Fees.vue'
import '../../utils/pristine.js'
import GigPageWrapper from '@/components/gigs/GigPageWrapper.vue'; 
import NotificationSmall from '../../components/notifications/small.vue';
import InputText from '../../components/form/InputText.vue';
import { TRichSelect, TSelect } from 'vue-tailwind/dist/components';
import { apiComputed, apiMethods } from '@/state/helpers'; 
import InlineNotification from '../../components/notifications/inline.vue';
import formatDate from '../../utils/format-date.js';
import normalizeDate from '../../utils/normalizeDate.js';
import GPButton from '../../components/ui/buttons/Button.vue'
import CancelBooking from '../../components/modal/gigs/CancelBooking.vue'; 
import NProgress from 'nprogress';

const { DateTime } = require("luxon");

import {
  TToggle 
} from 'vue-tailwind/dist/components' 


export default {
    name: "GigDetails",
    components:{
				GigPageWrapper, 
        TRichSelect,
        NotificationSmall,
        InputText, 
        InlineNotification,
        TToggle, Fees, GigTemplateCopy,TSelect,
				GPButton,
				CancelBooking
    }, 
    data () {
 
       return {   
						isLoading:false,
            local_start: null,
            local_start_date: null,
            item: null,
            start_hour: '18',
            start_minute: 0,
            end_hour: '23',
            end_minute: '45',
            fees: [],
						loadedTiers:this.$store.getters['api/tiers'],
            pristine: null,            
            selectedTiers: [],
            copyTemplate: null,
            gig_template_id: null, 
            is_amended_end: false,
            pristineConfig: {
                classTo: 'input-group',
                errorClass: 'has-error',
                successClass: 'has-success',
                errorTextParent: 'input-error',
                errorTextTag: 'span',
                errorTextClass: 'text-help'
            },
						cancelBookingModalIsOpen:false,
						selectedBooking:null,
						selectedVenueId:null,
						selectedVenueMultipleBookings:false,
						maxBookings:'1',
       }
    },
    mounted()
    {   
				this.onCalendarWrapperLoaded();
				this.loadGig();
    },
  computed: {  
    endDate: function()
    {
        if (this.local_start == null)
        return;
        



        let start = DateTime.fromFormat(this.local_start, "yyyy/MM/dd HH:mm");
        let end = DateTime.fromFormat(this.local_start, "yyyy/MM/dd HH:mm");


        end = end.set({hour: this.end_hour}).set({minute: this.end_minute});



        if (new Date(start.toUTC()) > new Date(end.toUTC()))
        {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.is_amended_end = true;
            end = end.plus({days: 1});
        }
        else
        {
             // eslint-disable-next-line vue/no-side-effects-in-computed-properties
             this.is_amended_end = false;
        }



        // let $endDate = end.toUTC();

        return end;
    },
    nextDayMessage: function(){
        return this.formatDate(this.form.end, "dd/MM/yyyy");
    },
    selected_venue: function()
    {
        return this.item ? this.item.venue_id : ''
    },
    form: function() {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
 
        let end = DateTime.now().set({hours: 23, minutes: 45}).toFormat('yyyy/MM/dd HH:mm');
 
        let $existingitem = this.item != null;


 
        return{
            id: $existingitem ? this.item.id : null,
            name: $existingitem? this.item.name : '',
            start: $existingitem? this.item.start : this.local_start,
            end: $existingitem? normalizeDate(this.item.end) : end,
            venue: $existingitem? this.item.venue : '',
            venue_id: $existingitem && this.item.venue ? this.item.venue.id : '',
            paused: $existingitem? this.item.paused : false,
            fees: $existingitem ? this.item.fees : null,
						
        } 
        }, 
        ...apiComputed,
        CreateOrUpdate: function() 
        { 
            return this.item ? 'Save & Next' : 'Create Gig'
        },
			showCancelButton(){
				return !this.item || !this.item.published ? false : !this.item.cancelled;
			},
			isGigCancelled(){
        return this.$store.getters['gig/isCurrentGigCancelled']
			},
			disableMaxBookings(){
				return this.item ? true : false;
			},
			maxBookingsSuffix(){
				return `artist${Number(this.maxBookings) === 1 ? '' : "s"} booked`
			}
    }, 
    watch:{
			selectedVenueId(updatedVenueId){
				this.checkVenue(updatedVenueId)
			},
      local_start_date(val) {
        this.local_start = DateTime.fromFormat(val, "yyyy-MM-dd")
            .set({hour: this.start_hour, minute: this.start_minute})
            .toFormat('yyyy/MM/dd HH:mm');
      },
      start_hour: function (val) {
        let start_hour = DateTime.fromFormat(this.local_start, "yyyy/MM/dd HH:mm").set({hours: val}).toFormat('yyyy/MM/dd HH:mm');
        this.local_start = start_hour;
      },
      start_minute: function (val) {
        let start_min = DateTime.fromFormat(this.local_start, "yyyy/MM/dd HH:mm").set({minutes: val}).toFormat('yyyy/MM/dd HH:mm');
        this.local_start = start_min;
      },
		},
    methods:{
			onCalendarWrapperLoaded(){
				if(!this.pristine && this.$refs.form){
					this.pristine = new Pristine(this.$refs.form, this.pristineConfig);
				}
			},
        loadGig(){

            NProgress.start();

            if (this.$attrs.gigId)
            {
							Promise.all([
								this.$store.dispatch('gig/getCurrentGig', this.$attrs.gigId)
								.then((response) => {
									this.item = response
								})
							])
							.finally(() => {
								if (this.form.venue_id != null)
									{
										this.selectedVenueId = this.form.venue_id;
									}   
					
									if (this.item)
									{
											this.local_start = normalizeDate(this.item.start);
                      this.local_start_date = DateTime.fromFormat(this.local_start, "yyyy/MM/dd HH:mm").toFormat('yyyy-MM-dd');
                      this.start_hour = DateTime.fromFormat(this.local_start, "yyyy/MM/dd HH:mm").toFormat('HH');
                      this.start_minute = DateTime.fromFormat(this.local_start, "yyyy/MM/dd HH:mm").toFormat('mm');
											this.end_hour = DateTime.fromISO(this.item.end).toUTC().toFormat('HH');
											this.end_minute = DateTime.fromISO(this.item.end).toUTC().toFormat('mm');

                                            if (this.item.artist_tags)
                                            {
                                                this.item.artist_tags.forEach(t => {
                                                        this.$refs.tagoutput.addTag({value: t.id, text: t.name});
                                                });
                                            }

											this.$refs.paused.isChecked = this.item.paused;
							

											this.selectedTiers = this.item.gig_tiers.map(t => t.tier);
											this.fees = this.item.gig_tiers;
											// this.$refs.fees.setFees(this.item.gig_tiers); 
											// this.$refs.fees.setTiers(this.item.gig_tiers); 
									}
							});
						}

            this.local_start_date = DateTime.now().toFormat('yyyy-M-dd');
            this.local_start = DateTime.now().set({hours: 18, minutes: 0}).toFormat('yyyy/MM/dd HH:mm');
            if (this.$route.query.start)
            { 
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                const start_iso_date = DateTime.fromISO(this.$route.query.start);
                this.local_start_date = start_iso_date.toFormat('yyyy-MM-dd');
                this.start_hour = start_iso_date.toFormat('HH');
                this.start_minute = start_iso_date.toFormat('mm');
                let end = DateTime.fromISO(this.$route.query.end);

                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.end_hour = end.toFormat("HH");
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.end_minute = end.toFormat("mm");

            }

            NProgress.done();


        },
        normalizeDate,    
        populateTime(limit){
            let hours = [];

            for (let hour = 0; hour < limit; hour++){
                
                let hourOut = hour;
                if (hourOut < 10)
                {
                    hourOut = '0' + hour
                }
                else{
                    hourOut = hour.toString();
                }

                hours.push(hourOut)
            }

            return hours;
        },
        formatDate,
        applyCopyTemplate(copy){
            
            let template = this.gigtemplates.find(f => f.id == this.gig_template_id); 
            
            this.copyTemplate = copy;
            if (copy.use_fees)
            {
                this.selectedTiers = template.gigtemplatetiers.map(t => t.tier); 
                // this.$refs.fees.setFees(template.gigtemplatetiers); 
                // this.$refs.fees.setTiers(template.gigtemplatetiers); 
                this.fees = template.gigtemplatetiers//this.$refs.fees.local_tiers;
                setTimeout(function(){
                    document.getElementById("scrollmove").scrollIntoView({behavior: 'smooth'});
                }, 500)
            }

        },
        applyTemplate(selectedTemplate){
            let template = this.gigtemplates.find(f => f.id == selectedTemplate); 
            
            this.$refs.TemplateCopyModal.updateTemplate(template); 
            this.$refs.TemplateCopyModal.toggleVisibility();
        },     
        getFees($fees)
        { 
            this.fees = $fees;
        },
        selectArtistType($ev){
					const selectedTier = this.loadedTiers.find(t => t.id === $ev)
					if(!selectedTier){
						return null
					}

					if (!this.fees.filter(f => f.tier.id === selectedTier.id).length) {
						/* fees does not contain the tier we're looking for */
						this.fees.push({
							tier: selectedTier, 
							requested_fee: "0", 
							tier_id: selectedTier.id, 
							id: selectedTier.id
						});
					}

					this.selectedTiers = this.fees.map(t => t.tier);
					// this.$refs.fees.setTiers(this.fees);
					
					setTimeout(function(){
							document.getElementById("scrollmove").scrollIntoView({behavior: 'smooth'});
					}, 500)
        },
        ...apiMethods,
        save(published = false){
                               
			

            var valid = this.pristine.validate(); 

            if (!this.selectedVenueId)
            {
                this.$refs.notification.createNotification("A venue is required", true);
                valid = false;
            }

            if (this.fees == false)
            {
                this.$refs.notification.createNotification("You must select at least one type of artist", true);
                valid = false;
            }

            if (this.fees == false || this.fees.length == 0 || this.fees.filter(f => f.requested_fee == 0).length > 0)
            {                
                this.$refs.notification.createNotification("Your fees must be more than £0.00", true);
                valid = false;
            }
            
            if (valid)
            {

                let fallbackPublished = this.item ? this.item.published : published;  
                //let tags = this.$refs.tagoutput.tags;

                let payload = { 
                    id: this.form.id, 
                    venue_id:  this.selectedVenueId,
                    published: published ? published : fallbackPublished, // don't change publish value on save
                    name: this.form.name,
                    start: DateTime.fromFormat(this.local_start, 'yyyy/MM/dd HH:mm'),
                    end:  this.endDate,
                    tags: [],
                    paused: this.form.paused,
                    fees: this.fees
                }
								if(this.selectedVenueMultipleBookings){
									payload.max_bookings = Number(this.maxBookings);
								}

           const self = this; 
            if (this.form.id == null)
            {

                if (this.copyTemplate != null)
                {
                    payload.copyTemplate = this.copyTemplate;
                    payload.gig_template_id = this.gig_template_id;
                }

                let response = null;
                this.createGig(payload).then((resp) => {
                    response = resp;


                    self.$router.push({ path: `/gigs/${response.id}/instructions`});

                        if (this.copyTemplate != null)
                        {
                            this.$notify("Gig created and template applied");
                        }
                        else
                        {
                            this.$notify("Gig created");
                        }
                    

                }).finally(() => {

 
                }).catch(($ex) => {
                    
                            this.$notify("An error occured on our end (" + $ex.message + ")");
                });

            }
            else
            { 

                this.updateGig(payload)
                    .then(response => {        
                        self.$router.push({ path: `/gigs/${response.id}/instructions` });
                        this.$notify("Gig updated");
                    })

						}
								
					} 

						
        },
				//
				// open modal
				onCancelBooking(){
					// get selectedBooking
					if(!this.item || !this.item.published){
						return null
					}
					this.selectedBooking = null;
					setTimeout(() => {
						// trigger an update with the selectedBooking in modal
						this.selectedBooking = this.item.bookings[0] || null;
						this.cancelBookingModalIsOpen = true;
					},50)
					
				},
				// close modals 
				onModalClosed(){
					this.cancelBookingModalIsOpen = false;
				},
				// CRUD methods
				onBookingDeleted(deletedPayload){
					this.onModalClosed();
					this.selectedBooking = null;
					if(deletedPayload.cancel_entirely){
						// No gig, go back to pending gigs
						this.$router.push('/pending-gigs')
					}else{
						this.loadGig();
					}
				},
				checkVenue(venueId){
					const selectedVenue = this.venues.filter(v => v.id === venueId)[0]
					this.selectedVenueMultipleBookings = selectedVenue.feature_multiple_bookings || false;
					// Set the maxBookings if gig data is present, if not then use default
					if(this.item && this.item.max_bookings){
						this.maxBookings = String(this.item.max_bookings);
					}
				}
    },
		//
		
}
</script>
