<template>
    
    <div> 
        <div class="details-view p-4">
            <h3 v-if="item && item.gig && item.gig.venue">Gig: {{item.gig.venue.name}} at {{this.fullDate(item.gig.start)}}  </h3>
            <h4 v-if="item && item.artist">{{item.artist.name}}</h4>

        <InlineNotification Heading="Gig instructions" >
            <template slot="body">
            <p class="mb-4">Double-check it. Here’s a refresher of the venue’s instructions for the gig. Before the venue’s booked you, just make sure you’re happy with everything.</p>
             
            </template>
        </InlineNotification>


<div class="rounded-md  p-4 max-w-4xl" :class="(item && (item.cancelled || item.gig.cancelled)) ? 'bg-red-100' : 'bg-green-50'">
  <div class="flex">
    <div class="flex-shrink-0">
      <!-- Heroicon name: solid/check-circle -->

    </div>
    <div class="ml-3">
        
            <div class="grid grid-cols-1 text-sm" v-if="item">
                
                <span>
                    <strong>Fee:</strong> £{{item.fee}}
                </span> 
                <span v-if="item.date_bid">
                    <strong>You gave your availability on:</strong> {{item.date_bid}} 
                </span>
                <span v-if="item.notified_venue_ok_to_artist">
                    <strong>Venue confirmed your availability:</strong> {{item.notified_venue_ok_to_artist}} 
                </span>
                <span v-if="item.accepted && !item.artist_final_accepted" class="mt-4">
                    <strong>{{item.gig.venue.name}} has said they want you on the gig.</strong><br/> 
                    <strong v-if="!item.artist_final_accepted"> You must now make your final confirmation below to be booked onto the gig.</strong> 
                </span>
                <span v-if="item.artist_final_accepted && !item.cancelled && !item.gig.cancelled" class="mt-4">
                    <strong>This booking is now complete, you are officially on the lineup.</strong><br/> 
                </span>
                <span v-else-if="item.cancelled " class="mt-4">
                    <strong class="uppercase text-lg">You have been removed from the lineup.</strong><br/> 
                </span>
                <span v-else-if="item.gig.cancelled " class="mt-4">
                    <strong class="uppercase text-lg">This gig has been cancelled.</strong><br/> 
                </span>
            </div>
    </div>
  </div>
</div>

<div class="rounded-md bg-green-50 p-4 max-w-4xl"  v-if="item && item.accepted && item.gig && item.gig.is_past">
  <div class="flex">
    <div class="flex-shrink-0">
        <CurrencyPoundIcon class="h-5 w-5 text-green-400" />
    </div>
    <div class="ml-3 text-sm">
        
            <div  v-if="this.item.invoice_id">   
                Your invoice can now be viewed via the <router-link to="/artist/invoices" class="underline">invoice section</router-link>.
            </div>
            <div v-else>
                This gig is awaiting venue sign-off.  Once signed-off a copy of your invoice will be available to download from the invoices section.
            </div>
    </div>
  </div>
</div>


            <div  v-if="item && item.gig">
                <div class=" lg:max-w-5xl"> 

                    <form class="form text-sm mb-56 space-y-4 lg:mb-36" ref="form" novalidate>

                    <div  class="col-span-8 input-group">
                        <label>Date</label>
                        {{this.fullDate(item.gig.start)}} until {{this.fullDate(item.gig.end)}} 
                    </div>    
                    <div  class="col-span-8 input-group" >
                        <label>Venue</label>
                        {{item.gig.venue.name}}<br/>
                        {{item.gig.venue.address.address_line_1}}<br/>
                        {{item.gig.venue.address.address_line_2}}<br/>
                        {{item.gig.venue.address.town}}<br/>
                        {{item.gig.venue.address.county}}<br/>
                        {{item.gig.venue.address.post_code}}<br/>
                    </div>                        


                    <div  class="instructions col-span-8 grid grid-flow-row gap-4 input-group" >
                        <h2 class="font-bold uppercase text-sm">Instructions and equipment at the venue</h2>
                        
                        <div v-if="item.gig.equipment_venue"> 
                            {{item.gig.equipment_venue}}
                        </div>
                        <div v-if="item.gig.equipment_artist">
                            <h3>Equipment the artist needs to bring</h3>
                            {{item.gig.equipment_artist}}
                        </div>  
                        <div v-if="item.gig.repertoire">
                            <h3>Repertoire</h3>
                            {{item.gig.repertoire}}
                        </div>
                        <div v-if="item.gig.dress_code">
                            <h3>Dress code</h3>
                            {{item.gig.dress_code}}
                        </div>
                        <div v-if="item.gig.parking_details">
                        
                            <h3>Parking details</h3>
                            {{item.gig.parking_details}}
                        </div>
                        <div v-if="item.gig.load_in_time">              
                            <h3>Load in times</h3>
                            {{item.gig.load_in_time}}
                        </div>
                        <div  v-if="item.gig.sound_check_time">
                        
                            <h3>Soundcheck times</h3>
                            {{item.gig.sound_check_time}}
                        </div>
                        <div  v-if="item.gig.performance_times">
                            
                            <h3>Performance times</h3>
                            {{item.gig.performance_times}}
                        </div>
                        <div v-if="item.gig.additional_notes">

                            <h3>Additional notes</h3>
                            {{item.gig.additional_notes}}
                        </div> 
 
                        <div v-if="item.gig.invoicing">
                        <h3>Invoicing</h3>
                        
                            {{item.gig.invoicing}} 
                        </div>
                        <div v-if="item.gig.important">

                            <h3 class="uppercase">Important information</h3>
                                {{item.gig.important}}
                        </div>
                        <div v-if="item.gig.payment_terms">

                            <h3>Payment terms</h3>
                            {{item.gig.payment_terms}}
                        </div> 

        </div>
                        
                    
                        
                    </form>
                  <div class="action-bar"
                       v-if="item.date_bid && !item.declined & !item.artist_final_accepted && !item.cancelled && !item.gig.cancelled">
                    <button @click="availability_decline()" class="cta icon bg-black text-white justify-center ">
                      <ThumbDownIcon class="mr-2"/>
                      Decline Availability
                    </button>
                  </div>

                <div class="action-bar justify-between"  v-if="item.notified_initial_artist == null && item.date_bid == null && item.gig.status != 'CONFIRMED'">  
                    <router-link to="/artist/offers" class="hidden lg:inline cta bg-indigo-600 justify-self-start ">Back to list</router-link>    

                    <div class="flex space-x-2 flex-col space-y-2 lg:flex-row items-end w-full lg:w-1/2" > 
                        <button @click="decline()" class="cta icon bg-black text-white "><ThumbDownIcon class="mr-2" />Decline offer</button>
                        <button @click="initial_accept()"  class="cta icon bg-gp_pink-default-500" ><ThumbUpIcon  class="mr-2"/>Confirm availability based on £{{form.fee}} offer </button>
                   
                    </div>
                 </div>

                 
                <div class="action-bar  justify-between" v-else-if="showActionBar">  
                
                    <router-link to="/artist/offers" class="hidden lg:inline cta bg-indigo-600 justify-self-start ">Back to list</router-link>    
                        <button @click="final_decline()" class="cta icon bg-black text-white "><ThumbDownIcon class="mr-2" />Decline offer</button>
                    <button @click="final_accept()"  class="cta icon bg-gp_pink-default-500 justify-center" >
                    <ThumbUpIcon class="mr-4"/>
                        Book onto this gig
                    </button>
                                   
                </div>


                </div>
            </div>
        </div>
        <NotificationSmall ref="notification" />


         
 

    </div>
    

</template>

<style>

    .form label 
    {
        @apply font-bold;
    }

</style>

<script> 
import Pristine from '../../../utils/pristine.js'
import { apiComputed, apiMethods } from '@/state/helpers';  
import NotificationSmall from '../../../components/notifications/small.vue';  
import InlineNotification from '../../../components/notifications/inline.vue'; 
import fullDate from  '@/utils/format-date-full.js';
import format from  '@/utils/format-date.js';
import { canBidBeAccepted } from '@/state/models/bidStatus'
import { CurrencyPoundIcon, ThumbUpIcon, ThumbDownIcon } from "@vue-hero-icons/solid";

export default {
    name: "ArtistBidDetails",
    components:{  
        NotificationSmall,
        InlineNotification, 
        CurrencyPoundIcon,
        ThumbUpIcon,
        ThumbDownIcon, 
    }, 
    data () {
 
       return {
            item: null,
            rowHeight: 5,
            is_enabled: false,
            is_default: false, 
            pristine: null,
            pristineConfig: {
                classTo: 'input-group',
                errorClass: 'has-error',
                successClass: 'has-success',
                errorTextParent: 'input-error',
                errorTextTag: 'span',
                errorTextClass: 'text-help'
            }, 
            form: {
                name: '',
                 
            }
            
       }
    },
    created() {  
        
        this.getArtistBid(this.$attrs.id).then((resp) => { 
            this.item = resp;
            this.form = resp;
        });
      
    },
    mounted()
    {   

        if (this.$refs.form)
        {
            this.pristine = new Pristine(this.$refs.form, this.pristineConfig); 
        }

        if (this.item)
        {
            let deepCopy = JSON.parse(JSON.stringify(this.item));            
            this.form = deepCopy; 
        }
    },
    computed: { 
        
        ...apiComputed,
				showActionBar(){
					return canBidBeAccepted(this.item.status, this.item.gig.status) && this.item.accepted && !this.item.artist_final_accepted;
				}
    },
    methods:{
        format,
        fullDate,
        ...apiMethods,  
        final_accept(){
            
            let payload = this.form;
            this.acceptFinal(payload)
                .then(() => {
                    this.$notify('Booking complete');
                    this.$router.push('/artist/gigs');
                });
        },
        final_decline(){
            let payload = this.form;
            this.declineArtistFinal(payload)
                .finally(() => {
                    this.$notify('Final confirmation declined');
                    this.$router.push('/artist/gigs');
                });
        },
      availability_decline() {
        let payload = this.form;
        this.declineArtistFinal(payload)
            .finally(() => {
              this.$notify('Availability declined');
              this.$router.push({name: 'AwaitingVenue'});
            });
      },
        decline()
        {
            
            let payload = this.form;
            payload.declined = true;
            this.updateArtistBid(payload)
                .then(() => {
                    this.$notify('Bid declined');
                    this.$router.push('/artist/offers');
                });
        },
        initial_accept(){
                              
    
            let payload = this.form;
  
 
                console.log("sending payload");
                this.initialArtistAcceptance(payload)
                    .then(response => {

                            console.log("update response", response);
                        
                        this.$notify("Offer sent to venue to accept/decline");
                        this.$router.push('/artist/offers');

                     }).catch((error) => {
                        alert(error);
                })  
        }
    }
}
</script>

