<template>
    <div>
        
        <div v-if="!$auth.loading" class="max-w-7xl px-4 mt-12 sm:px-6">
        <h1 class="text-2xl font-semibold text-gray-900 mb-5">
            {{ $auth.isAuthenticated ? "Hey, " + $auth.user.name : "" }},            
        </h1>

    <div  class="max-w-3xl">
        <div v-if="!hide_onboarding_video">
            <div class="grid lg:justify-end">
                <button class="cta bg-gp_pink-default-500 mb-4 justify-self-stretch" @click="dismissVideo();">Hide video</button>
            </div>
            <div class="mb-4" style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/748276126?h=6e5cced4ff&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="The Booking Process"></iframe></div>
        </div>
    </div>

        <h2 class="font-semibold text-lg mb-4">Booking gigs with GigPig. Here’s how it’s done.</h2>
    <ul class="artist_dash_instructions">
        <li>A venue asks for your availability for a gig. You’ll find it in ‘New available gigs’.</li>
        <li>Hit the ‘thumbs up’ to tell them you're free. Hit the ‘thumbs down’ if it’s not for you.</li>
        <li>If the venue wants to book you, you’ll get a notification.</li>
        <li>Play the gig. Bring the house down.</li>
        <li>The venue signs off your invoice. You can download it from ‘Invoices’ too.</li>
        <li>You get paid. No messing.</li>
    </ul>


        <div v-if="!this.artists || this.artists.length == 0">
            <p >We’re going on tour, but it’ll only take two ticks – let’s take you around the GigPig dashboard. </p>
            
        
            <div class="max-w-7xl  px-4 sm:px-6 " v-if="$auth.isAuthenticated">
                <!-- Replace with your content -->
                <div class="py-4">
                    <div class="dashboard">
                        <complete-artist-steps 
                        :has_artists="this.artists && this.artists.length > 0"
                        :has_offers="this.artistbids && this.artistbids.length > 0"
                        :has_bookings="this.artistbids && this.artistbids.filter((bid) => bid.artist_final_accepted).length > 0"
                        :has_raised_invoice="this.artistbids && this.artistbids.filter((bid) => bid.invoice_date_raised).length > 0"
                        />
                    </div>
                </div>
            </div>
            <!-- /End replace -->
        </div> 
        <div class="py-4" v-else>
            <h2 class="font-semibold">
                Notifications
            </h2>


            <div class="mt-4 grid grid-flow-row lg:grid-flow-col gap-4">

                    <AlertWithLink color_class="blue" :text="'You have unread messages: ' + $offer.gig.name + ' on ' + format($offer.gig.start)"  :to="'/gigs/' + $offer.gig.id + '/invoices'" v-for="($message, index) in bidsWithUnreadMessages" v-bind:key="'message' + index">
                        <template v-slot:icon> 
                        </template>
                    </AlertWithLink>

                    <AlertWithLink v-if="new_offers" color_class="blue" text="There are new gigs to give your availability for.  Click here to give the venue your availability for the gigs." :to="'/artist/offers'" >
                        <template v-slot:icon>
                            <ReceiptTaxIcon />
                        </template>
                    </AlertWithLink>

                    
                    <AlertWithLink v-if="responses" color_class="red" text="Venues have responded to your availability.  Click here to book yourself onto the gig, or decline" :to="'/artist/final-acceptance'" >
                        <template v-slot:icon>
                            <ReceiptTaxIcon />
                        </template>
                    </AlertWithLink>

                        
                    <div v-if="!new_offers && !responses">
                        <p>You're all up to date, there are no new notifications.</p>
                    </div> 

                </div>

                
            
            </div>
            

    <div v-if="hide_onboarding_video">
        <div class="grid lg:justify-end mt-8">
         <a class="text-gp_pink-default-500" @click="showVideo">View introduction video</a>
              </div>
    </div>


        </div>
      

        
    </div>
</template>

<style scoped>

.artist_dash_instructions ul{
    @apply py-4;
}
    .artist_dash_instructions li{
        list-style:disc;
        list-style-position:inside;
    }

</style>

<script>

import { ReceiptTaxIcon } from '@vue-hero-icons/outline'
import AlertWithLink from '../../components/alert/AlertWithLink.vue'
import { apiMethods, apiComputed } from '@/state/helpers'
import CompleteArtistSteps from '../../components/dashboard/CompleteArtistSteps.vue' 
const video_seen_key = 'artist_onboard_video_seen';
export default {
  components: { CompleteArtistSteps, AlertWithLink, ReceiptTaxIcon },
    name: "Dashboard",
    methods:{
        ...apiMethods,
        dismissVideo(){
            window.localStorage.setItem(video_seen_key, true);
            this.hide_onboarding_video = true;
        },
        showVideo(){
            window.localStorage.removeItem(video_seen_key);
             
            this.hide_onboarding_video = false; 
        }
    },
    computed:{
        ...apiComputed
    }, 
    data(){
        return {
            new_offers: false,
            responses: false,
            bidsWithUnreadMessages: [],
            hide_onboarding_video: false
        }
    },
    mounted(){
        let enableArtist = process.env.VUE_APP_ENABLE_ARTIST_FUNCTIONALITY;
         
        if (enableArtist === "false"){
        console.log(process.env.VUE_APP_ENABLE_ARTIST_FUNCTIONALITY);
            this.$router.push('/artist/profiles');
        }
 
        this.new_offers = this.artistbids && this.artistbids.filter(bid => bid.gig != null && !bid.declined && !bid.gig.cancelled && !bid.cancelled && bid.awaiting_artist_bid).length != 0;
        this.responses = this.artistbids && this.artistbids.filter(bid =>  bid.gig != null && !bid.declined && !bid.gig.cancelled && !bid.cancelled && bid.awaiting_artist_final_acceptance).length != 0;
        this.bidsWithUnreadMessages = this.getArtistUnreadMessages();

        
      let vimeoScript = document.createElement('script')
      vimeoScript.setAttribute('src', 'https://player.vimeo.com/api/player.js')
      document.head.appendChild(vimeoScript);

      if (window.localStorage[video_seen_key]) {
        this.hide_onboarding_video = true;
      }
    },

}
</script>