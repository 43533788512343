<template>
    
    <div class="gigtemplate"> 
        <div class="details-view mb-72">
            <h3>Edit gig templates</h3>

            <div>
                <div class=" lg:max-w-5xl"> 

                    <form class="form" ref="form" novalidate>
                        
            <div class="sm:col-span-3 grid grid-flow-col ">
                    <input-text  required id="name" ref="name" v-on:input="form.name = $event;" v-bind:value="form.name" label="Gig template name" hint="The template name must be populated e.g. Thursdays at Corkies"
                error="The template name must be completed" placeholder="e.g. Thursdays at Corkies" name="name"/>
                </div>



                <div class="sm:col-span-6 grid grid-flow-col ">
                    <input-text-area :rows="rowHeight" type="textarea"  required id="internal_desc" v-on:input="form.internal_desc = $event" v-bind:value="form.internal_desc" label="Internal description (not visible to artists)" hint="The internal description must be populated e.g. Use this for gigs involving the lightning seeds.  This field will only be shown to your team."
                        error="The internal description must be completed" placeholder="e.g. Use this template for any gig taking place at Thursday's with Joan" name="internal_desc"/>
                </div>   
                
                
        <Accordian heading="Artist Instructions" :toggle="accordians.open_artist_instructions">
            <template #contents>
              
           
                        <div class="sm:col-span-6 grid grid-flow-col ">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="load_in_time" label="Load in times" name="load_in_time" v-on:input="form.load_in_time = $event" v-bind:value="form.load_in_time" placeholder="e.g. You can gain access to the room any time during the day. If you are planning on setting up earlier then please check with the venue first" />
                        </div>
                        <div class="sm:col-span-6 grid grid-flow-col ">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="sound_check_time" label="Sound check times" name="sound_check_time" v-on:input="form.sound_check_time = $event" v-bind:value="form.sound_check_time" placeholder="e.g. Must be checked by 7pm"  />
                        </div>
                        <div class="sm:col-span-6 grid grid-flow-col ">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="performance_times" label="Performance times" name="performance_times" 
                            placeholder="e.g. 7:30pm – 8:30pm, 9pm - 10pm "  v-on:input="form.performance_times = $event" v-bind:value="form.performance_times"  />
                        </div>
                        <h2 class="col-span-full pt-4">Equipment Details</h2>
                        <div class="sm:col-span-6 grid grid-flow-col  ">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="equipment_venue"  v-on:input="form.equipment_venue = $event" v-bind:value="form.equipment_venue" placeholder="e.g. Venue will only supply sound system" label="Equipment provided by venue" name="equipment_venue"/>
                        </div>
                        <div class="sm:col-span-6 grid grid-flow-col ">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="equipment_artist" v-on:input="form.equipment_artist = $event" v-bind:value="form.equipment_artist" placeholder="e.g. Must provide own stereo jack cables and mic cables." label="Equipment provided by artist" name="equipment_artist"/>
                        </div>
                        <h2>Set info</h2>
                        <div class="sm:col-span-6 grid grid-flow-col ">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="repertoire" label="Repertoire" name="repertoire"  v-on:input="form.repertoire = $event" v-bind:value="form.repertoire" placeholder="e.g. A good mix of old classics and current tunes is essential. Keep your sets as upbeat as possible, even if the venue doesn’t seem that busy."/>
                        </div>
                        <div class="sm:col-span-6 grid grid-flow-col ">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="dress_code" label="Dress code" name="dress_code" placeholder="e.g. Smart Casual" v-on:input="form.dress_code = $event" v-bind:value="form.dress_code" />
                        </div>
                        <div class="sm:col-span-6 grid grid-flow-col ">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="parking_details" label="Parking details" name="parking_details"  v-on:input="form.parking_details = $event" v-bind:value="form.parking_details" placeholder="e.g. Loading available outside of venue. Free public parking close to venue" />
                        </div>
                        <div class="sm:col-span-6 grid grid-flow-col ">
                            <input-text-area  :rows="rowHeight"  type="textarea"  id="additional_notes" v-on:input="form.additional_notes = $event" v-bind:value="form.additional_notes" label="Additional notes" name="additional_notes"  placeholder="e.g. Musician to ask for the manager on duty upon arrival"/>
                        </div>
                        <h2>Commercial</h2>
                        <div class="sm:col-span-6 grid-flow-col hidden">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="invoicing" v-on:input="form.invoicing = $event" v-bind:value="form.invoicing" label="Invoicing" name="invoicing" placeholder="e.g. If you are completing work on a regular weekly basis we ask that you submit your weekly invoice as promptly as possible, (ideally on a Sunday)." />
                        </div>
                        <div class="sm:col-span-6 grid-flow-col hidden">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="important" v-on:input="form.important = $event" v-bind:value="form.important"  label="IMPORTANT INFORMATION" name="important" placeholder="e.g. Your Invoice must be made out to: ABC Music Ltd, 10 Samson Close" />
                        </div>
                        <div class="sm:col-span-6 grid grid-flow-col ">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="payment_terms" v-on:input="form.payment_terms = $event" v-bind:value="form.payment_terms"  label="Payment terms" name="payment_terms" placeholder="e.g. We settle our invoices on the first Monday that falls at least 21 days from the receipt of your invoice." />
                        </div>

          </template>
        </Accordian> 
 

        <Accordian heading="Fees" :toggle="accordians.open_fees">
            <template #contents>
							<Fees 
								ref="fees" 
								:gig-tiers="gigTiers"
								:is-template="true"
								@updated="getFees"
							></Fees>
                    <!-- <Fees ref="fees" :tiers="tiers" v-on:changeTiers="getFees($event)" :isTemplate="true">

                    </Fees> -->
                            
          </template>
        </Accordian> 
        <Accordian heading="Settings"  :toggle="accordians.open_settings">
            <template #contents>
                        <div class="hidden sm:col-span-1 grid grid-flow-col items-center">
                            <label for="enabled" class="mb-0">
                                Gig template enabled
                            </label>
                            <t-toggle name="enabled" v-model="is_enabled" id="enabled" ref="enabled" class="justify-self-end" />
                            
                        </div>
                        <div class="hidden sm:col-span-1  grid-flow-col items-center">
                            <label for="enabled" class="mb-0">
                                Make this template the default for new Gigs
                            </label>
                            <t-toggle name="default" id="default"  ref="default" v-model="is_default" class="justify-self-end" />
                            
                        </div>

                        <div class="col-span-full py-4">
                            <h2>How do you want to book artists - use artists you already have as favourites, or let GigPig find them for you?</h2>
                        </div>

                        <div class="sm:col-span-1 grid grid-flow-col items-center">
                            <label for="enabled">
                                Use my favourites lists
                            </label>
                            <t-toggle name="usefaves" id="usefaves"  ref="usefaves" v-model="use_faves" @click="switch_filter()" class="justify-self-end" />
                            
                        </div>


                        <div class="sm:col-span-1 grid grid-flow-col items-center">
                            <label for="enabled">
                                Let GigPig find artists for me
                            </label>
                            <t-toggle name="usefaves" id="usefaves"  ref="usesearch" v-model="use_search" @click="switch_filter()" class="justify-self-end" />
                            
                        </div>                        
            </template> 
        </Accordian>
        
        <Accordian heading="Configure Favourites" v-show="use_faves" :toggle="accordians.open_faves">
            <template #contents>


               <SearchByFave   ref="SearchByFave" 
               :artistdirectory="directory"
               :tiers="tiers" 
               :styles="styles"  
               :faveslists="faveslist"  
               :genres="genres"
                :defaultFees="form.gigtemplatetiers" 
               
               />

            </template>
        </Accordian>
        <Accordian heading="Configure Search" v-show="use_search" :toggle="true" bg_class="bg-gp_pink-default-300">
            <template #contents>
                    <SearchByTag ref="SearchByTag"
                    
                    :search_styles="form.search_styles"
                    :search_tiers="form.search_tiers"
                    :search_genres="form.search_genres"
                    
                    :single_tier="true" :tiers="tiers" :styles="styles" :genres="genres"
                    
                    ></SearchByTag>
                      
            </template>
        </Accordian>



                    </form>

                <div class="action-bar items-end justify-between">  
                    <router-link to="/gigtemplates" class="text-center w-full lg:w-1/6 cta bg-indigo-600">Back to template list</router-link>              
                    <button @click="save()" class="text-center w-full lg:w-1/6 cta bg-gp_pink-default-500" >{{ this.item ? 'Save Gig Template' : 'Create Gig Template' }}</button>
                
                 </div>


                </div>
            </div>
        </div>
        <NotificationSmall ref="notification" />


<nav aria-label="Progress" class="hidden xl:fixed right-12 top-24 bg-white p-4 rounded-lg max-h-90vh overflow-y-auto">
  <ol role="list">
    <ProgressStep label="Template name" :isDone="form.name != ''"  /> 
    <ProgressStep label="Interal description " :isDone="form.internal_desc != ''" /> 
    <ProgressStep label="Venue equipment " :isDone="form.equipment_venue != ''" /> 
    <ProgressStep label="Artist equipment" :isDone="form.equipment_artist != ''" /> 
    <ProgressStep label="Repertoire" :isDone="form.repertoire != ''" /> 
    <ProgressStep label="Dress code" :isDone="form.dress_code != ''" /> 
    <ProgressStep label="Parking details" :isDone="form.parking_details != ''" /> 
    <ProgressStep label="Load in times" :isDone="form.load_in_time != ''" /> 
    <ProgressStep label="Sound check times" :isDone="form.sound_check_time != ''" /> 
    <ProgressStep label="Performance times" :isDone="form.performance_times != ''" /> 
    <ProgressStep label="Additional notes" :isDone="form.additional_notes != ''" /> 
    <ProgressStep label="Invoicing" :isCurrent="false" :isDone="form.invoicing != ''" /> 
    <ProgressStep label="Important information" :isDone="form.important != ''" /> 
    <ProgressStep label="Payment terms" :isDone="form.payment_terms != ''" :isLast="true" /> 
  </ol>
</nav>

    </div>
    

</template>


<script> 
import Pristine from '../../utils/pristine.js'
import { apiComputed, apiMethods } from '@/state/helpers'; 
import {
  TToggle 
} from 'vue-tailwind/dist/components'
import InputText from '../../components/form/InputText.vue';
import NotificationSmall from '../../components/notifications/small.vue';
import InputTextArea from '../../components/form/InputTextArea.vue';
import ProgressStep from '../../components/progress/progress-step.vue';
import Accordian from '../../components/accordian/Accordian.vue';
import Fees from '../../components/gigs/Fees.vue';
import SearchByFave from '../../components/gigs/SearchByFave.vue';
import SearchByTag from '../../components/gigs/SearchByTag.vue'; 
export default {
    name: "GigTemplateDetails",
    components:{
    TToggle, 
    InputText, 
    InputTextArea,
    ProgressStep,
    NotificationSmall,
    Accordian,
    Fees,
    SearchByFave,
    SearchByTag
},  
    data () {
 
       return {
				item:null,
            rowHeight: 5,
            is_enabled: true,
            is_default: false, 
            use_faves: true,
            use_search: false,
            selectedFave: null,
            selectedArtist: null,
            search_tiers: null,
            search_genres: null,
            search_styles: null,
            faveslist: null,
            addedArtists: [],
            fees: [], 
						gigTiers:[],
            genres: null,
            styles: null,
            directory: [],
            accordians: {
                open_settings: true,
                open_fees: true,
                open_search: true,
                open_faves: true,
                open_artist_instructions: true                
            },
            pristine: null,
            pristineConfig: {
                classTo: 'input-group',
                errorClass: 'has-error',
                successClass: 'has-success',
                errorTextParent: 'input-error',
                errorTextTag: 'span',
                errorTextClass: 'text-help'
            }, 
            form: {
                name: '',
                internal_desc: '',
                equipment_artist: '',
                equipment_venue: '',
                repertoire: '',
                dress_code: '',
                parking_details: '',
                load_in_time: '',
                sound_check_time: '',
                performance_times: '',
                additional_notes: '',
                invoicing: '',
                important: '',
                payment_terms: '' 
            }
            
       }
    }, 
    created(){
        this.getGigTemplates().then((resp) => {
            this.item = resp.find(item => item.id === Number(this.$attrs.id));
        }).finally(() => {
            this.loadTemplate();
        })
    },
		mounted(){
			this.createGigTiers();
		},
    computed: {
        
        ...apiComputed,
    },
    methods:{
			createGigTiers(){
				const tiers = this.$store.getters['api/tiers'];
				this.gigTiers = tiers.map(t => {
					const existingFee = this.fees.filter(ef => ef.tier_id === t.id)[0]
					return {
						tier: t, 
						requested_fee: existingFee ? existingFee.requested_fee : "0", 
						tier_id: t.id, 
						id: t.id
					}
				})
			},
        loadTemplate(){
            if (!this.faveslist)
            {
                this.getFavesLists().then((resp) => {
                    this.faveslist = resp;
                });
            } 

            this.getArtistDirectorySimple().then((resp) => {
                this.directory = resp; 
            })

            this.pristine = new Pristine(this.$refs.form, this.pristineConfig); 

            this.genres = this.artisttags.filter(tag => tag.tag_type != 100);
            this.styles = this.artisttags.filter(tag => tag.tag_type == 100);

            if (this.item)
            {
                let deepCopy = JSON.parse(JSON.stringify(this.item));            
                this.form = deepCopy;
                
                this.$refs.SearchByFave.setStaged(this.form.staged_artists);


                if (!this.form.tiers)
                {
                    this.form.tiers = [];
                }

                this.is_default = this.item.is_default == 1;
                this.is_enabled = this.item.is_enabled == 1;

                this.use_faves = this.item.search_type == 100;
                this.use_search = this.item.search_type == 200;
                
                this.$refs.SearchByFave.setStaged(this.item.staged_artists);

                this.$refs.SearchByTag.search_tiers = this.item.gig_template_search_tiers.map((tag) => {return tag.tier_id});
                this.$refs.SearchByTag.search_genres = this.item.artist_tags.filter(tag => tag.tag_type != 100).map((tag) => {return tag.id });
                this.$refs.SearchByTag.search_styles = this.item.artist_tags.filter(tag => tag.tag_type == 100).map((tag) => {return tag.id });
            
                // this.$refs.fees.setFees(this.form.gigtemplatetiers);
                // this.$refs.fees.setTiers(this.item.gigtemplatetiers);
								this.fees = this.item.gigtemplatetiers;
								this.createGigTiers()
            }
        },
        getFees()
        { 
					const tempFees = this.gigTiers
						.filter(gt => gt.requested_fee !== "0")
						.map(f => {
							return {
								requested_fee: f.requested_fee, 
								tier_id: f.id, 
								id: f.id
							}
						})
            this.fees = tempFees//$fees;
            this.$refs.SearchByFave.setDefaultFees(tempFees);
        },
        ...apiMethods,
        parseEnabled(){
            return  this.is_enabled ? 1 : 0;
        },
        parseDefault(){
            return  this.is_default ? 1 : 0;
        },
        switch_filter(){
            this.use_faves = !this.use_faves;
            this.use_search = !this.use_search;
        },
        save(){
                             
            var valid = this.pristine.validate(); 
            
            //TODO: validate if fave list has artists, or search criteria filled
            if (valid)
            {     
                let tags = [];

                if (this.$refs.SearchByTag.search_genres != undefined)
                {
                    tags = this.$refs.SearchByTag.search_genres;
                } 

                if (this.$refs.SearchByTag.search_styles != undefined)
                {
                    tags = tags.concat(this.$refs.SearchByTag.search_styles);
                }
 

                let payload = this.form;
 
                payload.is_enabled = this.parseEnabled();
                payload.is_default = this.parseDefault();
                payload.search_type = this.use_faves ? 100 : 200;
                payload.add_artists = this.$refs.SearchByFave.addedArtists;
                payload.tags = tags; 
                payload.fees = this.fees; 
                payload.tiers = this.$refs.SearchByTag.search_tiers;
                
                const self = this; 
                if (payload.id == null)
                {
                    this.createGigTemplate(payload)
                        .then(() => {
                                            
                        this.$refs.notification.createNotification("Gig Template created");

                        self.$router.push({ path: `/gigtemplates` });
                        
                    }).catch((error) => {
                            alert(error);
                    })
                }
                else
                { 
                    this.updateGigTemplate(payload)
                        .then(response => {

                                console.log("update response", response);
                            
                            this.$refs.notification.createNotification("Gig Template updated");

                        }).catch((error) => {
                            alert(error);
                    })
                }
            }
        }
    }
}
</script>