<template>
    <div>         


        <InlineNotification Heading="Adding a new gig template" class="ml-4">
            <template slot="body">
            <p>GigPig templates are designed to make your life easier, especially if you book multiple gigs of similar times and performance information.</p>

<p>Click “add a new template” and follow the instructions to create your GigPig templates</p>
            </template>
            <template slot="cta">              
                <router-link to="/gigtemplates/add" class="gp_cta">Add a new template</router-link>
            </template>
        </InlineNotification>


      <div class="ml-4 flex flex-col mt-12 " v-if="gigtemplates && gigtemplates.length > 0">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="max-w-4xl shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200 text-left">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Template
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3  hidden lg:inline-block  hidden lg:block marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Internal Description
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 max-w-300px  hidden   text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                    ></th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr @click="$router.push('gigtemplates/' + gigtemplate.id)" v-for="(gigtemplate, index) in convertedRows()" :key="index" :row="gigtemplates" class="relative cursor-pointer">
                      <td class="text-gp_pink-default-500 p-6">
                         <router-link :to="'gigtemplates/' + gigtemplate.id">Edit 
                        </router-link>
                      </td>
                      <td class="px-3 hidden ">
                        <TToggle :disabled="true" :checked="gigtemplate.is_default == 1 ? true : false" /> 
                      </td> 
                      <td class="px-3 items-center hidden ">
                        <TToggle :disabled="true"  :checked="gigtemplate.is_enabled == 1 ? true : false"  /> 
                      </td> 
                      <td class="p-6">
                          <strong>{{gigtemplate.name}}</strong> 
                      </td> 
                      <td class="p-6 hidden lg:inline-block   ">
                          {{gigtemplate.internal_desc}} 
                      </td> 
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  <!--
        <div v-if="debug">
          {{ convertedRows() }}
        </div>
-->
    </div>
</template>


<script type="text/javascript">


import { apiComputed } from '@/state/helpers.js'  
import InlineNotification from '../../components/notifications/inline.vue'
import {
  TToggle,
} from 'vue-tailwind/dist/components' ;

export default {
  name: 'GigTemplates',
  components:
  {
    TToggle,
    InlineNotification 
}, 
  computed: {
    ...apiComputed
  }, 
  methods: { 
    convertedRows() {
      let rows = []

      if (this.gigtemplates != null) {
        this.gigtemplates.forEach(gigtemplate => {
            rows.push(gigtemplate);
        });
      }

      // sort by is default desc
      rows.sort((firstItem, secondItem) => secondItem.is_default - firstItem.is_default);
 
      return rows;
      //return rows
    }
  }
};

</script>