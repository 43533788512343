<template>
	<div>
		
		<p class="text-xs p-4"><span class="uppercase text-indigo-500">New</span> Your calendar will only show confirmed gigs, to see gigs you've responded to, see <router-link to="/artist/offers" class="text-gp_pink-default-500 uppercase">new gigs</router-link> and <router-link class="text-gp_pink-default-500 uppercase"  to="/artist/awaiting-venue-response">availability given</router-link>.</p>
		<!-- Calendar -->
		<CalendarWrapper
			calendar-type="ArtistGigs"
			:view-options="calendarViewOptions"
			:available-status-keys="calendarAvailableStatusKeys"
			:schedules="schedules"
			@selected="scheduleSelected"
		>
			<template v-slot:filters>
				<!-- User Artists filter -->
				<TRichSelect 
					id="artist-selection" 
					ref="artistpicker" 
					name="artist-selection" 
					v-model="filteredArtists"
					placeholder="Filter by artist"                    
					:options="artists"
					value-attribute="id" 
					text-attribute="name" 
					class="w-full"
				/>
			</template>
		</CalendarWrapper>
		<!-- Schedule modal -->
		<calendar-preview 
			ref="calendarPreview" 
			:isArtistView="true" 
			:bid-id="selectedBidId"
			:show-as-bid="true"
		/>
	</div>

</template>

<script>
import NProgress from 'nprogress'
import { TRichSelect } from 'vue-tailwind/dist/components';
import CalendarWrapper from '@/components/calendar/CalendarWrapper.vue';
import CalendarPreview from '@/components/modal/CalendarPreview.vue';
import { getStatusesFromTypes, getStatusFromType } from '@/state/models/bidStatus'
import normalizeDate from '@/utils/normalizeDate';
const { DateTime } = require("luxon");

export default {
	components:{
		TRichSelect,
		CalendarWrapper,
		CalendarPreview
	},
	data(){
		return{
			artistbids:null,
			artists:null,
			schedules:[],
			filteredBids:[],
			filteredArtists:null,
			isLoading:false,
			selectedBidId:null,
			calendarViewOptions:[
				{ value: 'month', text: 'Month view'}, 
				{ value: 'week', text: 'Week view'},
				{ value: 'day', text: 'Day view'},
			],
			calendarAvailableStatusKeys: getStatusesFromTypes([
				'CANCELLED',
				'PENDING',
				'CONFIRMED',
				'AWAITING_SIGN_OFF',
				'SIGNED_OFF',
			])
		}
	},
	computed:{},
	watch:{
		filteredArtists(){
			this.setCalendarItems();
		}
	},
	methods:{
		parseBidToSchedule(bid){
			const bidStatus = getStatusFromType(bid.status)
			let end = bid.gig.end;
			if (new Date(bid.gig.end).getHours() < new Date(bid.gig.start).getHours()) {
				end = DateTime.fromISO(bid.gig.start).toUTC().set({hours: 23, minutes: 59});
			}
			return {
				id: bid.id,
				title: bidStatus.name,//bid.gig.name,
				category: 'time',
				start: normalizeDate(bid.gig.start),
				end: normalizeDate(end),
				calendarId: 0,
				raw: bid,
				bgColor: bidStatus.bg,
				color: bidStatus.color,
				customStyle: `background-color:${ bidStatus.bg }; color:${ bidStatus.color }!important;`,
				status: bidStatus.name,
			}
		},
		parseBids(){
			this.filteredBids = this.artistbids
				.filter((ab) => {
					//  ab.status !== 'UNCAUGHT_STATUS' && ab.status !== 'DECLINED'
					return ab.gig && ab.status && this.calendarAvailableStatusKeys.filter(i => i.type === ab.status).length > 0
				})
				.map((fab) => {
					return this.parseBidToSchedule(fab)
				})
			this.setCalendarItems()
		},

		setCalendarItems(){
			let schedules = [ ...this.filteredBids ]
			// check for filter
			if(this.filteredArtists && this.filteredArtists !== "all"){
				schedules = schedules.filter(s => s.raw.artist_id === this.filteredArtists);
			}
			this.schedules = schedules;
		},

		scheduleSelected(schedule){
			
			const selectedBid = this.artistbids.filter(ab => ab.id === schedule.id)[0];
			if(selectedBid){
				this.selectedBidId = selectedBid.id;
				let preview = this.$refs.calendarPreview;
				const obj =  { ...selectedBid.gig, status: selectedBid.status }
				preview.item = obj
				preview.viewModal = true;
			}
			
		}, 

	},

	mounted(){
		this.isLoading = true;
		NProgress.start();
		Promise.all([
			// Artists
			this.$store.dispatch('artists/getArtists')
			.then((response) => {
				this.artists = [
					{ name:"All artists", id:"all" },
					...response
				]
			}),
			// Artist Bids
			this.$store.dispatch('artistBids/loadArtistBids')
			.then((response) => {
				this.artistbids = response;
				this.parseBids()
			})
		]).finally(() => {
			this.isLoading = false;
			NProgress.done();
		});
	}
}
</script>
