<template>
	<div v-if="isOpen" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
		<div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    
			<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
     
			<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    
			<transition appear enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">     

				<div class="inline-block align-bottom bg-white rounded-sm p-2 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-1">

					<div v-if="$slots.header" class="mt-4 px-6">
						<slot name="header"></slot>
					</div>
					
					<div v-if="$slots.body" class="my-4 px-6">
						<slot name="body"></slot>
					</div>
					
					<div v-if="$slots.footer" class="mt-4">
						<slot name="footer"></slot>
					</div>
					

				</div>
			</transition>
		</div>
  
	</div>
</template>

<script>
export default
{
	name: "ModalWrapper",
	props:{
		isOpen:{
			type:Boolean,
			required: true,
			default:false
		},
	},
	// data(){
	// 	return {
	// 		is_open: false
	// 	}
	// },
	methods:{
		// open(){
		// 	this.is_open = true;
		// },
		// cancel(){
		// 	this.is_open = false;
		// },
		// toggleOpen(){
		// 	this.is_open = !this.is_open;
		// }
	},
}
</script>