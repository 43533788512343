<template>
      <div v-if="$store.state.layout.isMobileMenuOpen"
      :class="{ ' opacity-100' : $store.state.layout.isMobileMenuOpen, ' opacity-0' : $store.state.layout.isMobileMenuOpen }" 
      class="fixed inset-0  z-40 transition-opacity ease-linear duration-300 flex"   role="dialog" aria-modal="true" >
    <!--
      Off-canvas menu overlay, show/hide based on off-canvas menu state.

      Entering: "transition-opacity ease-linear duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "transition-opacity ease-linear duration-300"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class=" transition ease-in-out duration-300 transform fixed inset-0 bg-gray-600 bg-opacity-75" :class="{ 'translate-x-0' : $store.state.isMobileMenuOpen, 'translate-x-full' : $store.state.isMobileMenuOpen }"  aria-hidden="true"></div>

    <!--
      Off-canvas menu, show/hide based on off-canvas menu state.

      Entering: "transition ease-in-out duration-300 transform"
        From: "-translate-x-full"
        To: "translate-x-0"
      Leaving: "transition ease-in-out duration-300 transform"
        From: "translate-x-0"
        To: "-translate-x-full"
    -->
    <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4" id="mobile-sidebar" >
      <!--
        Close button, show/hide based on off-canvas menu state.

        Entering: "ease-in-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in-out duration-300"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <div class="absolute top-0 right-0 -mr-12 pt-2">
        <button @click="closeMenu" type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
          <span class="sr-only">Close sidebar</span>
          <!-- Heroicon name: outline/x -->
          <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div class="flex-shrink-0 flex items-center px-4">
        <img class="h-16 w-auto" src="@/assets/images/platform_logo.svg" alt="GigPig.  Gigs made easy.">
      </div>
      <div class="mt-5 flex-1 h-0 overflow-y-auto" >
            <NavContainer/>
      </div>
    </div>

    <div class="flex-shrink-0 w-14" aria-hidden="true">
      <!-- Dummy element to force sidebar to shrink to fit close icon -->
    </div>
  </div>
</template>

<script> 

import {layoutMethods} from '@/state/modules/layout.js' 
import NavContainer from './NavContainer.vue';
export default {
    name: "NavMobile",
    components:{ 
      NavContainer
    },
    methods:{
       ...layoutMethods,
      closeMenu() {        
          this.setToggleMobileMenu(false);
      }
    }
}
</script>