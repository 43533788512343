<template>  
    <div  transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
               
            </div>
    
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    
            <transition appear enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">        
                <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                    <div>
                        <div class="text-left sm:mt-5 flex justify-center flex-col items-center">
                            <div class="text-lg text-center"> 
                                {{message}}
                            </div>
                            <a v-if="urlText && url" :href="url" class="cta text-center mt-6 my-4 items-center bg-gp_pink-default-500">
                                    {{urlText}}
                            </a>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
 
<script>
export default {
    name: "Claim",
    props:{ 
        message: String,
        urlText: String,
        url: String
    },
    methods: {
        closeModal() {
            this.$emit('closeModal',
                false
            )
        }
    }
}
</script>

