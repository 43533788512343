import client from "../../utils/client"


export const state = {
  pastGigs: null,
}

export const mutations = {
  SET_PAST_GIGS(state, pastGigs) {
    state.pastGigs = pastGigs
  },
	UPDATE_PAST_GIG(state, bid) {
		const index = state.pastGigs.findIndex(b => b.id === bid.id)
    if(index > -1){
			// Make sure all the items are in the gig (such as venue etc) as some updated (fee) do not return back the full gig
			const gig = {
				...state.pastGigs[index].gig,
				...bid.gig
			}
			state.pastGigs[index] = {
				...state.pastGigs[index],
				...bid,
				gig
			};
		}
  },
}

export const getters = {
	pastGigs: state => {
    return state.pastGigs;
  },
}

export const actions = {
	storePastGigs({ commit }, pastGigs){
		commit('SET_PAST_GIGS', pastGigs);
	},
  getPastGigs({ getters, dispatch }) {
		return new Promise((resolve) => {
			if(getters.pastGigs){
				resolve(getters.pastGigs)
			}else{
				client
					.get(`/past-gigs?include=gig.venue,gig,artist,invoice`)
					.then((response) => {
						dispatch('storePastGigs', response.data.data)
						resolve(getters.pastGigs)
					})
					.catch(err => {
						console.log(err)
					});
			}
		})
  },
	getGigDetails({ getters, dispatch }, gigId){//commit, 
		return new Promise((resolve) => {
			client
					.get(`/gigs/${gigId}?include=bids,stagedArtists,artistTags,gigTiers,gigSearchTiers,gigTiers,bids,bookings,venue`)
					.then((response) => {
						dispatch('getPastGigs', response.data.data)
						resolve(getters.currentGig)
					})
					.catch(err => {
						console.log(err)
					});
		})
	},
	syncUpdatedBid({ getters, commit }, bid){

		return new Promise((resolve) => {
			console.log("bid")
			console.log(bid)
			if(getters.pastGigs){
				commit('UPDATE_PAST_GIG', bid)
				resolve(getters.pastGigs)
			}else{
				resolve(getters.pastGigs)
			}
		})
	},
} 