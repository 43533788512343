<template>
  <div class="details-view">
    <h3>Add new Venue</h3> 

		<template v-if="isLoading">
			<div class="p-8 flex justify-center items-start">
				<PageSpinner 
					tailwind-diameter="8"
					tailwind-border="4"
					tailwind-color="pink-500"
				/>
			</div>
		</template>
		<template v-else-if="!isLoading && !hasData">
			<NoDataMsgWrapper>Sorry, we could not load the necessary data needed to create a venue</NoDataMsgWrapper>
		</template>
		<template v-else>
<div class="lg:max-w-5xl">
        <form class="mb-24 form input-group" ref="form" novalidate> 
            <div class="mt-6 sm:mt-5 col-span-full">
              <h4 class="text-lg leading-6 font-medium text-gray-900">
                Profile
              </h4>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                This information will be shown on your venue profile page to
                potential artists.
              </p>
            </div>

            <div class="mt-6 sm:mt-5  col-span-full space-y-6 sm:space-y-5">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="venue-name"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Venue name
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">

                  
                       <InputText required id="venue-name" v-on:input="form.name = $event;" v-bind:value="form.name" label="Venue Name" hint="The venue name must be populated"
                       error="The venue name must be completed" name="venue-name"
                        />

                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="about"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  About
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">


                  <input-text-area required aria-required="required" v-on:input="form.about = $event" v-bind:value="form.about" name="about" ref="about" id="about" label="About your venue" rows="10"  />
                   
 
                  <p class="mt-2 text-sm text-gray-500">
                    Write a few sentences about your venue.
                  </p>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="country"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Tags
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <TRichSelect 
                    id="tag-selection"
                    ref="tagpicker"
                    name="tag-selection"
                    v-model="form.venueTags"
                    @change="pickTag" 
                    :options="venueTags"
                    value-attribute="id" 
                    text-attribute="name" 
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />

                  <input-tags ref="tagoutput" class="grid-cols-none grid col-span-full grid-flow-col gap-4 items-start justify-start py-4" />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="country"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Region
                </label>
                <div class="mt-1 input-group sm:mt-0 sm:col-span-2">
                  <select
                    id="country"
                    name="country"
                    v-model="form.region"
                    autocomplete="country"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    required
                  >
                    <option :value="null" selected disabled
                      >-- Select a region --</option
                    >
                    <option
                      v-for="region in regions_dd"
                      :key="region.id"
                      :value="region.id"
                    >
                      {{ region.name }}
                    </option>
                  </select>

                    <div class="input-error ml-10">
                    </div>
                </div>
              </div>
              <div
                class="sm:grid input-group sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="photo"
                  class="block text-sm font-medium text-gray-700"
                >
                  Venue Logo
                </label>

								<div class="input-group mt-1 sm:mt-0 sm:col-span-2">
									<profile-picture-upload 
										:image="icon" 
										@onImageChange="profilePictureUploaded" 
										profile-type="VenueProfilePicture"
									/>
                </div>

              </div> 

              <div class="sm:grid input-group sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
								<div>
									<h4 class="text-sm leading-6 font-bold text-gray-900">
										Gallery
									</h4>
									<p class="mt-1 max-w-2xl text-sm text-gray-500">
										Upload photos for your artist profile page (4mb limit, jpg or png).
									</p>
								</div>
								
								<div class="mt-1 sm:mt-0 sm:col-span-2">
									<upload-gallery 
                    :images="gallery"
										gallery-type="VenueGallery"
                    @onGalleryChange="onGalleryChanged" 
                  />
								</div>

              </div>
            </div>

            <!-- Profile Contact Details -->
            <div class="mt-6 sm:mt-5  col-span-full">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Profile Contact Details
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                This information will be shown on your venue profile page to
                potential artists.
              </p>
            </div>

            <div class="mt-6 sm:mt-5 space-y-6  col-span-full sm:space-y-5">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <span>Venue address</span>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                <label
                  for="venue-address-line1"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Line 1
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">

                  
                  
                       <InputText required id="venue-address-line1" v-on:input="form.address.address_line_1 = $event;" v-bind:value="form.address.address_line_1"
                       error="The venue address line one must be provided" name="venue-address-line1" class="max-w-lg sm:max-w-xs"
                        />  
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-line2"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Line 2
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="venue-address-line2"
                    id="venue-address-line2"
                    v-model="form.address.address_line_2"
                    class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-town"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Town/City
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">

                  
                       <InputText required id="venue-address-town" v-on:input="form.address.town = $event;" v-bind:value="form.address.town"
                       error="The venue town must be provided" name="venue-address-town" class="max-w-lg sm:max-w-xs"
                        /> 
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-county"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  County
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2"> 

                  
                       <InputText required id="venue-address-county" v-on:input="form.address.county = $event;" v-bind:value="form.address.county" 
                       error="The venue county must be provided" name="venue-address-county" class="max-w-lg sm:max-w-xs"
                        />
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-postcode"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Postcode
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  
                       <InputText required id="venue-address-postcode" v-on:input="form.address.post_code = $event;" v-bind:value="form.address.post_code"  
                       error="The venue postcode must be provided" name="venue-address-postcode" class="max-w-lg sm:max-w-xs"
                        />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="email"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Email
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">


                       <InputText required id="email" v-on:input="form.email = $event;" v-bind:value="form.email"  type="email" hint="A primary contact email must be provided"
                       error="A primary contact email must be provided" name="email"
                        />
 
                  <p class="mt-2 text-sm text-gray-500">
                    The primary venue email address.
                  </p>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="tel"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Telephone
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">

                  <InputText id="tel" v-on:input="form.telephone = $event;" v-bind:value="form.telephone"  name="tel"  /> 
                  <p class="mt-2 text-sm text-gray-500">
                    The primary venue telephone number.
                  </p>
                </div>
              </div>
              <div
                class="input-group sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="website"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Website
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">

                                  
                       <InputText  id="website" required v-on:input="form.website = $event;" v-bind:value="form.website" 
                        name="website"   type="url" error="Your website url is required"
                        />
  
                  <p class="mt-2 text-sm text-gray-500">
                    The primary venue website.  
                  </p>
                </div>
              </div>

            <!-- Profile Contact Details -->
            <div class="mt-6 sm:mt-5  col-span-full">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Billing address
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                This information will be shown on your invoices from artists.
              </p>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
            >
              <label
                for="venue-billing-same-as"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                The venue billing address is the same as the venue address
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <t-toggle id="venue-billing-same-as" value="form.billing_same_as" @change="toggleBillingAddress"></t-toggle>
              </div>
            </div>

            <div class="mt-6 sm:mt-5 space-y-6  col-span-full sm:space-y-5" v-if="!form.billing_same_as">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <span>Billing address</span>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                <label
                  for="venue-billing-address-line1"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                 Billing Line 1
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <InputText 
                    id="venue-billing-address-line1" 
                    v-on:input="form.billing_address.address_line_1 = $event;" 
                    v-bind:value="form.billing_address.address_line_1"
                    error="The venue address line one must be provided" 
                    name="venue-address-line1" 
                    class="max-w-lg sm:max-w-xs"
                  />  
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-line2"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                 Billing Line 2
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="venue-billing-address-line2"
                    id="venue-billing-address-line2"
                    v-model="form.billing_address.address_line_2"
                    class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-billing-address-town"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                 Billing Town/City
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <InputText 
                    id="venue-billing-address-town" 
                    v-on:input="form.billing_address.town = $event;" 
                    v-bind:value="form.billing_address.town"
                    error="The venue's billing town must be provided" 
                    name="venue-billing-address-town" 
                    class="max-w-lg sm:max-w-xs"
                  /> 
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-billing-address-county"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                 Billing County
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2"> 
                  <InputText 
                    id="venue-billing-address-county" 
                    v-on:input="form.billing_address.county = $event;" 
                    v-bind:value="form.billing_address.county" 
                    error="The venue's billing county must be provided" 
                    name="venue-billing-address-county" 
                    class="max-w-lg sm:max-w-xs"
                  />
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-postcode"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Billing Postcode
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <InputText 
                    
                    id="venue-billing-address-postcode" 
                    v-on:input="form.billing_address.post_code = $event;" 
                    v-bind:value="form.billing_address.post_code"  
                    error="The venue's billing postcode must be provided" 
                    name="venue-billing-address-postcode" 
                    class="max-w-lg sm:max-w-xs"
                  />
                </div>
              </div>
            </div>
            </div> 
        </form>
        <div class="action-bar">                
          <button class="cta bg-gp_pink-default-500" @click="save">Create Venue</button>                
        </div> 
    </div>
		</template>



      
    <NotificationSmall ref="notification" />
  </div>
</template>

<script>
import { TRichSelect, TToggle } from 'vue-tailwind/dist/components';
import PageSpinner from '../../components/ui/spinner/PageSpinner.vue';
import NoDataMsgWrapper from '../../components/ui/messages/NoData.vue';
import InputTags from '../../components/form/InputTags.vue';
import ProfilePictureUpload from '../../components/uploads/profile-picture-upload.vue'
import UploadGallery from '../../components/uploads/upload-gallery.vue'
import NotificationSmall from '../../components/notifications/small.vue';
import InputText from '../../components/form/InputText.vue';
import InputTextArea from '../../components/form/InputTextArea.vue';
import Pristine from '../../utils/pristine.js'

export default {
  name: "VenueAdd",
  components: {
    TToggle,
    TRichSelect,
		PageSpinner,
		NoDataMsgWrapper,
    InputTags,
    NotificationSmall,
		ProfilePictureUpload,
		UploadGallery,
    InputText,
    InputTextArea
  },
  data() {
    return {
			isLoading:false,
      pristineConfig: {
          classTo: 'input-group',
          errorClass: 'has-error',
          successClass: 'has-success',
          errorTextParent: 'input-error',
          errorTextTag: 'span',
          errorTextClass: 'text-help'
      }, 
      regions_dd: null,
			venueTags:null,
			icon:null,
			gallery:[],
      form: {
        name: "",
        about: "",
        venueTags: [],
        region: "",
        address: {},
        billing_address: {},
        billing_same_as: false,
        email: "",
        telephone: "",
        website: "",
      },
    };
  },
	computed:{
		hasData(){
			if(this.regions_dd && this.venueTags){
				return true;
			}
			return false;
		},
	},
  mounted() {
		this.isLoading = true;
		Promise.all([
			// Venue
			this.$store.dispatch('regions/getRegions')
			.then((response) => {
				this.regions_dd = response
				console.log(response)
			}),
			//  Tags
			this.$store.dispatch('venue/getTags')
			.then((response) => {
				this.venueTags = response
				console.log(response)
			})
		]).finally(() => {
			this.isLoading = false;
			setTimeout(() => {
				this.pristine = new Pristine(this.$refs.form, this.pristineConfig);      
				this.pristine.addValidator('url', function(value) {
						return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
				}, "A valid website is required (including https://)", 1, false);
			}, 500)
			
			
		});


  },
  methods: {
    pickTag(){ 
      this.$refs.tagoutput.addTag(this.$refs.tagpicker.selectedOption);
    },
		profilePictureUploaded(image) {
			this.icon = image
		},
		onGalleryChanged(images) {
			this.gallery = images
		},

    toggleBillingAddress() {
      this.form.billing_same_as = !this.form.billing_same_as
    },

    save() {
      let valid = this.pristine.validate(); 
			if (valid){

				let venue_tags = this.$refs.tagoutput.tags;
				let regions = this.regions_dd.filter(
					(region) => region.id === this.form.region
				);

				const payload = {
					name: this.form.name,
					about: this.form.about,
					venue_tags:venue_tags.map((venueTag) => venueTag.value),
					region_id:regions[0].id,
					icon:this.icon !== null ? this.icon.id : null,
					gallery: this.gallery.map(gallery => gallery.id),
					address: this.form.address,
					billing_same_as: this.form.billing_same_as,
					billing_address: this.form.billing_same_as ? null : this.form.billing_address,
					email: this.form.email,
					telephone: this.form.telephone,
					website: this.form.website,
					published:false
				}

				this.$store.dispatch('venue/createVenue', payload)
				.then((response) => {
					this.$refs.notification.createNotification("Venue created", false);
					this.$router.push({ name: "VenuesDetails", params: { venueId: response.id } });
				}).catch((error) => {
					this.$notify({text: "An error occurred: " + error.message + ".  If this message persists, please contact support@gigpig.uk quoting this error message",  duration: 5000});
				});

      }else{
				this.$refs.notification.createNotification("Please check the form for omissions", true);
      }
    },
  },
};
</script>
