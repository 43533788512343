<template>
  <div> 
    <div  class="max-w-7xl px-4 mt-12 sm:px-6">
      <h1 class="text-2xl font-semibold text-gray-900 mb-5">
            Hi {{ $auth.user.name }}
      </h1>
      
      <h2 class="text-base font-semibold mb-5">Notifications</h2>

      <div v-if="no_notifications == null">
        Loading notifications
      </div>
      <div v-if="notification_count > 0">
        There are {{notification_offers_needing_set_response.length}} responses for action, and  {{notification_offers_invoice_to_pay.length}} invoices needing sign-off:
      </div>
      <div v-if="no_notifications == true">
        You have no notifications at this time.
      </div>
      <div class="notification_actions lg:grid-cols-2 items-start">
          <div class="grid gap-4">
            <AlertWithLink :data-bid-id="$offer.id" color_class="red" :text="$offer.name + ' on ' + format($offer.start) + ' has artists who have shown interest.  Click here to respond'" :to="'/gigs/' + $offer.id + '/offers'" v-for="($offer, index) in notification_offers_needing_set_response" v-bind:key="'interest' + index">
                <template v-slot:icon>
                    <BellIcon />
                </template>
            </AlertWithLink> 
          </div>
          <div class="grid gap-4">
            <AlertWithLink  :data-bid-id="$offer.id"  color_class="blue" :text="$offer.name + ' on ' + format($offer.start) +  ' is ready for sign-off on ' + $offer.name " :to="'/past-gigs'" v-for="($offer, index) in notification_offers_invoice_to_pay" v-bind:key="'invocing' + index">
                <template v-slot:icon>
                    <ReceiptTaxIcon />
                </template>
            </AlertWithLink>
          </div>
          <AlertWithLink  :data-bid-id="$offer.id"  color_class="blue" :text="$offer.gig.name + ' has unread messages'" :to="'/gigs/' + $offer.gig.id + '/invoices'" v-for="($offer, index) in unreadMessages" v-bind:key="'invocing' + index">
              <template v-slot:icon>
                  <ReceiptTaxIcon />
              </template>
          </AlertWithLink>
      </div>

      <div>
        <div class="content"></div>
      </div>
    </div>
    <div class="max-w-3xl grid grid-flow-row grid-cols-13 px-4 sm:px-6 "   v-if="progress && !progress.has_artists">
        <p v-if="!complete && !progress">You've still got steps to complete on your account.</p>
        <div class="col-span-6 pb-4 pt-8 text-center text-gray-500 flex flex-col ">
            <span class="text-5xl  text-gp_pink-default-500 mb-4 font-bold" >{{percentage}}%</span>
            Complete
        </div>
        <div class="col-start-9 col-end-14 flex justify-center items-center pb-4 pt-8">
            <nav aria-label="Progress">
            <ol role="list" class="flex items-center">
          
                <ProgressBarItem Step="One" :IsNextStep="true" :IsComplete="progress ? progress.has_venue : false" />
                <ProgressBarItem Step="Two" :IsNextStep="progress && progress.has_venue" :IsComplete="progress ? progress.has_templates : false" />
                <ProgressBarItem Step="Three" :IsNextStep="progress && progress.has_templates" :IsComplete="progress ? progress.has_gigs : false" />
                <ProgressBarItem Step="Four" :IsLast="true" :IsNextStep="progress && progress.has_gigs" :IsComplete="progress ? progress.has_artists : false" />
 
            </ol>
            </nav>
        </div>
    </div>
    <div class="max-w-7xl  px-4 sm:px-6 "  v-if="progress && !progress.has_artists">
      <!-- Replace with your content -->
      <div class="py-4">
        <div class="">
          <CompleteSteps :progress="progress" :complete="complete" />
        </div>
      </div>
      <!-- /End replace -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { apiMethods, apiComputed } from '@/state/helpers'
 
import CompleteSteps from "../components/dashboard/CompleteSteps.vue";
import ProgressBarItem from '../components/dashboard/ProgressBarItem.vue';
import AlertWithLink from '../components/alert/AlertWithLink.vue';

import {BellIcon, ReceiptTaxIcon} from '@vue-hero-icons/outline';

import format from '../utils/format-date-default';

import NProgress from 'nprogress';

export default {
  name: "Home",
  components: {
    CompleteSteps,
    ProgressBarItem,
    AlertWithLink,
    BellIcon,
    ReceiptTaxIcon
},
  methods: {
    ...apiMethods,
    format,
    loadNotifications: function(){
        let venuesEnabled = process.env.VUE_APP_ENABLE_VENUE_FUNCTIONALITY;
         
        if (venuesEnabled === "false"){ 
            localStorage.setItem('initialRoute', 'artist');
            this.$global.switchRoute('artist');        
            this.$router.push('/artist/profiles');
        }

      

        let $awaiting_response_bids =this.notification_bids;

        this.notification_offers_needing_set_response = $awaiting_response_bids.filter((f) => {
          return !f.is_past && f.bookings_count == 0;
        });
 
        this.notification_offers_invoice_to_pay = this.notification_bids.filter((f) => {
          return f.status == "NEEDS_SIGNOFF";
        })

        this.no_notifications = this.notification_offers_needing_set_response.length == 0 && this.notification_offers_invoice_to_pay.length == 0;

        window.addEventListener('beforeinstallprompt', (e) => { 
          e.preventDefault(); 
          this.deferredPrompt = e; 
        });
    }
  },
  async created() {  
    
    this.getUserEventProgress().then((resp) => {
        this.progress = resp.data;
    });

  },
  data(){
    return {
      deferredPrompt: null,
      progress: false,
      notification_offers_needing_set_response: [],
      notification_offers_invoice_to_pay: [],
      allBids: [],
      no_notifications: null,
      percentageCalculated: false,
      unreadMessages: [],
      notification_bids: null,
      notification_count: 0
    };
  },
  computed:{
    ...apiComputed,
    complete(){
      return this.percentage == 100;
    },
    percentage() {

        if (!this.progress)
          return 'calculating...';
        let array = [this.progress.has_venue, this.progress.has_gigs, this.progress.has_templates, this.progress.has_artists] 
        
        let count = array.filter(Boolean).length;

        let percent = count * (100 / array.length); 

        return percent; 
    }
  },
  mounted: function(){
    
      NProgress.start();

      if (!this.notification_bids){
          this.getDashboard().then((resp) => {
            console.log(resp);
            this.notification_bids = resp;
            this.notification_count = resp.length;
          }).finally(() => {
              NProgress.done();
              this.loadNotifications();
          });
      }else{
        this.loadNotifications();
      }

        
  }
};
</script>
