<template>
	
	<ModalWrapper 
		ref="repeatConfirmModal" 
		:is-open="isOpen"
	>
		<template #header>
			<div class="text-xl">{{ modalTitle }}</div>
			<!-- <p class="mt-2 text-md">{{ introText }}</p> -->
		</template>

		<template #body>
			<div v-if="dates" class="flex flex-col">
				<div 
					v-for="date in dates" 
					:key="getDateKey(date)" 
					class="flex items-center mb-4"
				>
					{{ getDateLabel(date) }}
				</div>
				
				<div class="border-t border-gray-300 my-4 py-2">
					<p class="my-4 text-md">
						This gig will be repeated on the dates listed above.
					</p>
					<div class="flex items-center mb-2">
						<SwitchToggle 
							:enabled="publish"
							:disabled="isLoading"
							@updateEnable="onTogglePublish"
							id="publishToggle" 
							label="Automatically publish repeated gigs"
						/>
					</div>
					<template v-if="publish">
						<p class="my-2 text-md">
							Each gig will be automatically published and you will be deducted <span class="font-semibold">{{ getTokensLabel }}</span>.
						</p>
					</template>
					<template v-else>
						<p class="my-2 text-md">
							You will still need to publish each gig, when you will be deducted <span class="font-semibold">{{ getTokensLabel }}</span>.
						</p>
					</template>
				</div>
				

				
				
				
			</div>
		</template>

		<template #footer>

			<ModalFooterWrapper>
				<template #left>
					<Button 
						@click.native="onClose" 
						:disabled="isLoading" 
						color="gray"
					>Cancel</Button>
				</template>
				<template #right>
					<Button 
						@click.native="onRepeat" 
						:disabled="submitDisabled" 
						:loading="isLoading"
					>
						{{ submitButtonLabel }}
					</Button>
				</template>
			</ModalFooterWrapper>
					
		</template>
	</ModalWrapper>
	
</template>

<script>
import { DateTime } from 'luxon';
import ModalWrapper from '../../ui/modal/ModalWrapper.vue'
import ModalFooterWrapper from '../../ui/modal/ModalFooterWrapper.vue'
import Button from '../../ui/buttons/Button.vue'
import SwitchToggle from '../../ui/form/SwitchToggle.vue'
// import formatDate from '../../../utils/format-date.js';
import client from '../../../utils/client';
export default {
	components: {
		ModalWrapper,
		ModalFooterWrapper,
		Button,
		SwitchToggle,
	},
	props:{
		identity:{
			type:String,
		},
		isOpen:{
			type:Boolean,
			required: true,
			default:false
		},
		gigId:{
			type:String,
			required: true,
		},
		dates:{
			type:[],
			required: true,
		},
	},
	emits:["closed", "confirmed", "error"],
	data(){
		return {
			isLoading:false,
			error:false,
			publish:true,
		}
	},
	computed:{
		modalTitle() {
      return `Confirm repetition of this gig:`
    },
		getDateKey(){
			return (date) => String(new Date(date).getTime())
		},
		getDateLabel(){
			return date => DateTime ? DateTime.fromISO(date).toUTC().toFormat('cccc DDD HH:mm') : ''
		},
		submitButtonLabel() {
      return this.isLoading ? 'Repeating gigs' : 'Repeat gigs'
    },
		submitDisabled() {
      return this.isLoading;
    },
		getTokensLabel(){
			return `${this.dates.length} token${this.dates.length !== 1 ? 's' : ''}`
		}
	},
	methods:{
		onClose(){
			this.error = false;
			this.$emit("closed");
		},
		onTogglePublish(){	
			this.publish = !this.publish;
		},
		onRepeat(){
			this.isLoading = true;
			this.error = false;
			setTimeout(() => {
				this.isLoading = false;
			},3000)
			const payload = {
				dates: this.dates,
				published: this.publish,
			}
			client
				.post(`gigs/${this.gigId}/repeats`, payload)
					.then(() => {
						this.isLoading = false;
						this.$emit("confirmed", payload);
					})
					.catch(err => {
						console.log(err)
						this.isLoading = false;
						this.error = true;
						this.$emit("error", payload);
					})
		},
	},
}
</script>

