<template>
	<PageDetails>
    
	<InlineNotification Heading="Completed gigs">
		<template slot="body">
			<p>Once a gig is completed it will appear in this section. If you are happy that the gig took place and all the details are correct, you just need to sign it off. The invoice will then move to the “Billing and Invoices” section.</p>
		</template>
	</InlineNotification>
     
	<div class="lg:max-w-6xl">

	
		<template v-if="isLoading">
			<div class="p-8 flex justify-center items-start">
				<!-- no need for loading state as using NProgress -->
			</div>
		</template>

		<template v-else-if="!isLoading && !hasData">
			<NoDataMsgWrapper>
				No gigs require sign-off. Signed-off gigs can be found under <router-link class="text-gp_pink-default-500" to="/invoices">'Billing and invoices'</router-link>
			</NoDataMsgWrapper>
		</template>

		<template v-else>

			<GPButton 
				@click.native="openModal" 
				:loading="isSigningOff"
				:disabled="signOffButtonDisabled"
				size="lg"
			>
				{{ this.signOffButtonLabel }}
			</GPButton> 

			<TableWrapper>
				<template #header-rows>
					<tr>
						<th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-6">
							<input type="checkbox" @change="selectAll" />
						</th>
						<th scope="col" class="hidden px-3 py-3.5 lg:table-cell">
							<span class="sr-only">Edit</span>
						</th>
						<th scope="col" class="hidden px-3 py-3.5 lg:table-cell">Artist</th>
						<th scope="col" class="hidden px-3 py-3.5 lg:table-cell">Gig</th>
						<th scope="col" class="hidden px-3 py-3.5 lg:table-cell">Days since gig</th>
						<th scope="col" class="hidden text-right px-3 py-3.5 lg:table-cell pr-4 sm:pr-6">Status</th>
					</tr>
				</template>
				<template #body-rows v-if="rows">
					<tr v-for="bid in bids" :key="bid.id">

						<!-- Mobile -->
						<td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6 lg:hidden">
							<dl class="font-normal lg:hidden my-2">
								<div class="flex items-center gap-x-6">
									<div class="w-4 flex items-center">
										<input ref="check" :id="bid.id"  type="checkbox" @change="toggleOffer($event.target.checked, bid.id)" />
									</div>
									<div class="flex flex-wrap items-center gap-x-6">
										<router-link 	
											:to="'/gigs/' + bid.gig.id"  class="text-gp_pink-default-500 flex items-center"
										>
											<EyeIcon class="pr-2"/> {{bid.gig.name}}
										</router-link>
										{{bid.gig.venue.name}}
									</div>
									
								</div>
								
							</dl>

							<dl class="font-normal lg:hidden pl-10 my-2">
								<div class="flex flex-wrap items-center gap-x-6">
									<div>
										{{  fullDate(bid.gig.start) }}
									</div>
									<div>
										<span class="italic">({{timeAgo(bid.gig.start)}} ago)</span>
									</div>
								</div>
							</dl>


							<dl class="font-normal lg:hidden pl-10 my-2">
								<div class="flex flex-wrap items-center gap-x-6">
									<div>
										{{ getArtistsName(bid) }}
									</div>
									<div>
										<a class="text-gp_pink-default-500 flex items-center" @click="editBidFee(bid)"
										>
											<span>£{{bid.fee}} </span>
											<span class="flex pl-1"><PencilAltIcon class="m-auto" /></span></a>
									</div>
									<div>
										<a v-if="bid.invoice"  class="text-gp_pink-default-500"  @click="generateInvoice(bid)">Preview invoice</a>
										<span v-else>Invoice not generated</span>
									</div>
								</div>
							</dl>
							<dl class="font-normal sm:hidden pl-10 my-2">
								<StatusBadge :status="bidStatus(bid)" />  
							</dl>
						</td>

						<!-- Desktop -->
						<td class="hidden lg:table-cell px-3 text-sm text-gray-500 py-3.5 pl-4 pr-3 sm:pl-6">
							<input ref="check" :id="bid.id"  type="checkbox" @change="toggleOffer($event.target.checked, bid.id)" />
						</td>

					
						<td class="hidden lg:table-cell py-3.5 px-3 sm:pl-6">
							<div class="flex flex-col gap-y-4">
								<router-link :to="'/gigs/' + bid.gig.id"  class="text-gp_pink-default-500">View gig</router-link>
								<a v-if="bid.invoice"  class="text-gp_pink-default-500"  @click="generateInvoice(bid)">Preview invoice</a>
								<span v-else>Invoice not generated</span>
								<a 
									class="text-gp_pink-default-500"
									@click="editBidFee(bid)"
								>Amend fee</a>
							</div>
						</td>
						<td class="hidden lg:table-cell px-3 py-3.5">
							<strong class="block">{{ getArtistsName(bid) }}</strong>
							<strong class="block">£{{bid.fee}}</strong>
						</td>
						<td class="hidden lg:table-cell px-3 py-3.5">
							<span>{{bid.gig.venue.name}}</span>
							<span class="block">{{bid.gig.name}}</span>
							<span class="block">{{  fullDate(bid.gig.start) }}</span>
						</td>
						<td class="hidden lg:table-cell px-3 py-3.5">    
							<span class="block">{{timeAgo(bid.gig.start)}} ago</span>
						</td>
						<td class="hidden text-right sm:table-cell px-3 py-3.5">    
							<StatusBadge :status="bidStatus(bid)" />  
						</td>

					</tr>

				</template>
			</TableWrapper>
		</template>
	</div>

 
    <SignoffArtists ref="signoffModal" :toSignOff="selectedBids" v-on:signoff="signoffArtists()" v-on:cancel="closeModal()" />
    <InvoiceGeneration ref="invoice" :invoiceItems="[invoiceItem]" />

		<template v-if="selectedBid">
			<AmendBidFee 
				:is-open="showAmendBidFeeModal"
				:bid="selectedBid"
				@closed="cancelModal"
				@updated="onBidFeeUpdated"
			/>
		</template>
		
	</PageDetails>
</template>

<script type="text/javascript">
import PageDetails from '@/components/ui/wrappers/PageDetails.vue'
import TableWrapper from '@/components/ui/table/TableWrapper.vue';
import NoDataMsgWrapper from '@/components/ui/messages/NoData.vue';
import GPButton from '@/components/ui/buttons/Button.vue'
import fullDate from  '@/utils/format-date-full.js'  
import timeAgo from  '@/utils/format-date-timeago.js'  
import InlineNotification from '../../components/notifications/inline.vue';   
import InvoiceGeneration from '../../components/invoice/wrapper.vue';
import SignoffArtists from '../../components/modal/SignoffArtists.vue';  
import NProgress from 'nprogress';
import AmendBidFee from '../../components/modal/gigs/AmendBidFee.vue'
import StatusBadge from '../../components/iconsets/StatusBadge.vue'
import { getStatusFromType as getBidStatus } from '@/state/models/bidStatus'
import { PencilAltIcon, EyeIcon } from "@vue-hero-icons/outline"

export default {
	name: "PastGigs",
  components:{ 
		PageDetails,
		TableWrapper,
		NoDataMsgWrapper,
		GPButton,
		InlineNotification, 
		InvoiceGeneration, 
		SignoffArtists,
		AmendBidFee,
		StatusBadge,
		PencilAltIcon,
		EyeIcon
	},
  data() { 
      return { 
				selectedBids: [],
				invoiceItem: null,
				showAmendBidFeeModal: false,
				selectedBid:null,
				rows: [],
				isLoading:false,
     }
  },
  mounted(){
		this.isLoading = true;
    NProgress.start();
		this.$store.dispatch('pastGigs/getPastGigs')
			.then((response) => {
				this.rows = response;
				NProgress.done();
				this.isLoading = false;
			})
			.catch(err => {
				console.log(err);
				NProgress.done();
				this.isLoading = false;
			})
  },
  computed: {
    numberSelected(){ 
			return this.selectedBids.length 
		},
		getArtistsName(){
			return (gig) => gig && gig.artist ? gig.artist.name : "no artists details"
		},
		hasData(){
			if(this.rows){
				if(this.rows.length){
					return true;
				}
			}
			return false;
		},
		signOffButtonDisabled(){
			return !this.numberSelected > 0
		},
		signOffButtonLabel(){
			return `Sign off selected performances (${ this.numberSelected })`
		},
		isSigningOff(){
			return false;//this.$refs.signoffModal.open()
		},
		bids(){
			if(this.rows){
				return this.rows
			}else{
				return []
			}
		},
		bidStatus(){
			return (bid) => getBidStatus(bid.status)
		}
  },
  methods: {
    fullDate, 
    timeAgo,
    selectAll($event){
			const checked = $event.target.checked;
			this.$refs.check.forEach((c) => {
				c.checked = checked;
				this.toggleOffer(c.checked, c.id);
			});
    },
    generateInvoice($bid){
      this.invoiceItem = $bid;
      this.$nextTick(function(){
        this.$refs.invoice.generate();
      });
    },
		cancelModal(){
			this.showAmendBidFeeModal = false;
		},
		editBidFee($bid){
			this.selectedBid = $bid;
			this.showAmendBidFeeModal = true;
		},
		onBidFeeUpdated($bid){
			this.selectedBid = $bid;
			this.cancelModal();
			NProgress.start();
			this.$store.dispatch('pastGigs/syncUpdatedBid', this.selectedBid)
				.then((response) => {
					this.rows = response;
					NProgress.done();
				})
				.catch(err => {
					console.log(err);
					NProgress.done();
				})
		},
    toggleOffer: function(isOn, $bidid){ 

      if (isOn)
      {
        this.selectedBids.push($bidid);
      }
      else 
      {
        let removeIndex = this.selectedBids.indexOf($bidid);
        if (removeIndex !== -1){
          this.selectedBids.splice(removeIndex, 1);
        }
      }
    }, 
    openModal(){
      this.$refs.signoffModal.open();
    },
  }
};

</script>