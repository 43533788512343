<template>

    <div>

        <Header Heading="Add your GigPig profile here" class="ml-8" />
        <InlineNotification Heading="Your artist profile" class=" ml-4">
            <template slot="body">
            <p>A band, a DJ, a solo act, a duo, or all of the above? Create separate profiles to show off each act, so venues can spot you when they’re booking gigs.
            </p>
            </template>
            <template slot="cta">
                <router-link to="/artists/add" class="gp_cta">Add new artist profile</router-link>
            </template>
        </InlineNotification>


        <div class="max-w-7xl m-6"> 
        <div class="flex flex-col ">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div
                class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
            >
                <div
                class="max-w-4xl shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
                >
                <table class="min-w-full divide-y divide-gray-200 text-left">
                    <thead class="bg-gray-50">
                    <tr>
                        <th
                        scope="col"
                        class="px-6 py-3 marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                        Artist
                        </th>
                        <th
                        scope="col"
                        class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                        
                        </th>
                        <th scope="col" class="relative px-6 py-3">
                        <span class="sr-only">Edit</span>
                        </th>
                        <th
                        scope="col"
                        class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                        ></th>
                    </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="(row, index) in convertedRows()" :key="index" :row="row">
                        <td>
                            <router-link :to="'/artists/' + row.id"> 
                            <img :src="row.icon" :alt="row.name + '  logo'" class="w-28 h-28 object-cover object-center" /></router-link>
                        </td>
                        <td class="lg:space-x-4 space-x-12 lg:space-y-0 space-y-6 px-4 w-100 lg:w-64 text-gp_pink-default-500 ">
                            <router-link title="View profile"  class="h-full  lg:inline-flex flex-row" :to="'/artists/profiles/' + row.id">
                                <EyeIcon/> <span class="hidden lg:inline-block ml-4">View</span>
                            </router-link> 
                            <router-link title="Edit profile" class="h-full lg:inline-flex lg:flex-row " :to="'/artists/' + row.id">
                                <PencilIcon /> <span class="hidden lg:inline-block ml-4">Edit</span>
                            </router-link>
                        </td>
                        <td class="px-3 text-right">
                            <router-link :to="'/artists/' + row.id"> {{row.name}}</router-link></td>
                    </tr> 
                    </tbody>
                </table>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div> 
</template>

<script type="text/javascript">

import { apiComputed, apiMethods } from '@/state/helpers.js'  
import InlineNotification from '../../components/notifications/inline.vue'
import Header from '../../components/listing/header.vue'

import { EyeIcon, PencilIcon } from '@vue-hero-icons/outline'

export default
{
  name: "ArtistsProfiles",
  components: {
    InlineNotification, Header, EyeIcon, PencilIcon
  },
  computed: {
    ...apiComputed,
  },
  mounted: () => {
  },
  methods: {
      ...apiMethods,
     convertedRows() {
      let rows = []

      if (this.artists && this.artists.length > 0){

        this.artists.forEach(artist => {
            //let lastBooking = venue.sortedBookings.slice().pop()
            //let lastBookingMonthYear = lastBooking ? this.formatDate(new Date(lastBooking.gig.start).toISOString(), "MMM yyyy") : ""
            let artistObj = {
                id: artist.id,
            name: artist.name, 
            icon: artist.image ? artist.image.url : require('@/assets/images/placeholder.svg')
            }
            rows.push(artistObj)
        });
      } 
      
      return rows
    }
  }
}

</script>