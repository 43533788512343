<template>
	<PageDetails>
		<h3 class="font-bold">{{ titleLabel }}</h3>
		<template v-if="isLoading">
			<div class="p-8 flex justify-center items-start">
				<PageSpinner 
					tailwind-diameter="8"
					tailwind-border="4"
					tailwind-color="pink-500"
				/>
			</div>
		</template>
		<template v-else>
			<template v-if="gigExpected">
				<GigTabs 
					:gig-id="String(gigId)" 
					:current-route="currentRoute"
				/>
			</template>
			
			<PageContentDetails>
				<slot name="default"></slot>
			</PageContentDetails>
		</template>
		
	</PageDetails>
</template>

<script>
import formatDate from '../../utils/format-date.js';
import PageSpinner from '../ui/spinner/PageSpinner.vue';
import PageDetails from '../ui/wrappers/PageDetails.vue';
import PageContentDetails from '../ui/wrappers/PageContentDetails.vue';
import GigTabs from './Tabs.vue';

export default {
    components: { 
			PageSpinner,
			PageDetails, 
			PageContentDetails,
			GigTabs,
		},
		props:{
			gigId:{
				type:String,
				required: true,
			},
			currentRoute:{
				type:String,
				required: true,
			},
			title:{
				type:String,
				required: false,
			},
			mainPageTitle:{
				type:String,
				required: false,
				default:"Edit gig"
			},
		},
		computed: { 
			gigExpected(){
				if(this.currentRoute === "details" && this.gigId === 'undefined'){
					return false;
				}
				return true
			},
			isLoading(){
				if(this.gigExpected){
					if(!this.gig){
						return true;
					}else if(String(this.gig.id) !== this.gigId){
						return true;
					}else{
						return false;
					}
				}
				return false;
			},
			
			titleLabel(){
				if(!this.gigExpected){
					return 'Add gig'
				}
				// 
				if(this.title){
					return this.isLoading 
					? `${ this.mainPageTitle } - ${ this.title }` 
					: `${ this.mainPageTitle } - ${ this.title }: ${ this.gigTitleDetails }`
				}else{
					return this.isLoading ? `${ this.mainPageTitle }` : `${ this.mainPageTitle }: ${ this.gigTitleDetails }`
				}
				
			},
			gig(){
				return this.$store.getters['gig/currentGig'];
			},
			gigTitleDetails(){
				if(!this.gig){
					return "";
				}
				if(!this.gig.start){
					return "";
				}
				return this.gig.name + ', '  + formatDate(this.gig.start, 'dd/MM/yy HH:mm').replaceAll(',', '');
			}
    },
		watch:{
			isLoading(flag){
				if(!flag){
					setTimeout(() => {
						this.$emit('loaded')
					}, 500);
				}
			}
		}
}
</script>