<template>
    <div class="min-h-1100px flex flex-col justify-between pt-6 px-8 " v-if="invoiceItem">
  
      <div class="grid grid-flow-row gap-8">
          <div class="flex justify-between">
              <div>
                  <ul v-if="invoice && invoice.artist_billing_address">
                      <li>{{ invoice.artist_name }}</li>
                      <li v-if="invoice.artist_billing_address.address_line_1">{{ invoice.artist_billing_address.address_line_1 }}</li>
                      <li v-if="invoice.artist_billing_address.address_line_2">{{ invoice.artist_billing_address.address_line_2 }}</li>                                    
                      <li>{{ invoice.artist_billing_address.town }}</li>
                      <li>{{ invoice.artist_billing_address.county }}</li>
                      <li>{{ invoice.artist_billing_address.post_code }}</li>
                      <li v-if="invoiceItem && invoiceItem.artist">{{ invoiceItem.artist.email }}</li>
                  </ul>
                  <ul v-else-if="invoice">
                      <li>{{ invoice.artist_name }}</li>
                      <li>Billing address not supplied</li>
                  </ul>
              </div>
              <div class="grid grid-flow-row gap-4"  v-if="invoice">
                  <span class="text-4xl">Invoice</span>
                  
                  <div class="grid grid-cols-2 gap-4">
                      <div>Invoice:</div><div>GP-{{ invoice.invoice_number }}</div>
                      <div>Gig Reference:</div><div>{{invoiceItem.gig.id}}</div>
                      <div v-if="invoiceItem && invoiceItem.signed_off_at">Date issued: </div><div>{{signoffFormat(invoiceItem.signed_off_at)}}</div> 
                      <div v-if="invoice.utr_number">UTR Number:</div><div>{{ invoice.utr_number }}</div> 
                      <div v-if="invoice.vat_number">VAT Number:</div><div>{{ invoice.vat_number }}</div> 
                  </div>
              </div>
          </div>
          <div class="grid grid-flow-row">
              <div class="font-bold uppercase text-sm">
                  Invoice to
              </div>
                  <ul v-if="invoice && invoice.venue_billing_address">
                      <li>{{ invoice.venue_name }}</li>
                      <li>{{ invoice.venue_billing_address.address_line_1 }}</li>
                      <li v-if="invoice.venue_billing_address.address_line_2">{{ invoice.venue_billing_address.address_line_2 }}</li>
                      <li>{{ invoice.venue_billing_address.town }}</li>
                      <li>{{ invoice.venue_billing_address.county }}</li>
                      <li>{{ invoice.venue_billing_address.post_code }}</li>
                      
                  </ul>
          </div>
          <div class="grid grid-flow-row w-full gap-4" v-if="invoiceItem">
  
              <div class="uppercase font-bold text-sm">Item description</div>
              <section class="grid grid-flow-row w-full gap-4" v-if="invoiceItem.artist && !invoiceItem.artist.vat_number">
                  <div class="flex flex-col  justify-between">
                      <div>{{invoiceItem.gig.name}}, {{format(invoiceItem.gig.start, 'cccc dd/MM/yyyy @ HH:mm')}}</div>
                      <div></div>
                  </div>
                  <div class="flex flex-row justify-between">
                      <div></div>
                      <div>£{{invoiceItem.fee}}</div>
                  </div>    
                  <div class="flex flex-row justify-between  border-solid border-t-2 border-gray-500">
                      <div class="uppercase font-bold text-sm">Total</div>
                      <div class="font-bold">£{{invoiceItem.fee}}</div>
                  </div>      
              </section>
              <section  class="grid grid-flow-row w-full gap-4" v-else>
                  <div class="flex flex-row  justify-between">
                      <div>{{invoiceItem.gig.name}}, {{format(invoiceItem.gig.start, 'cccc dd/MM/yyyy @ HH:mm')}}</div>
                      <div>£{{(invoiceItem.fee - calculateVAT()).toFixed(2)}}</div>
                  </div>
                  <div class="flex flex-row justify-between">
                      <div>VAT @ {{invoice.vat_rate}}%</div>
                      <div>£{{calculateVAT().toFixed(2)}}</div>
                  </div>    
                  <div class="flex flex-row justify-between  border-solid border-t-2 border-gray-500">
                      <div class="uppercase font-bold text-sm">Total</div>
                      <div class="font-bold">£{{invoiceItem.fee}}</div>
                  </div>      
              </section>
              <div>
                  <h2 class="font-bold">Amounts payable to:</h2>
                  {{invoiceItem.artist.account_name}}
                  <br/>
                  Sort code: {{invoiceItem.artist.sortcode}}
                  <br/>
                  Account: {{invoiceItem.artist.account_number}}
              </div>
          </div>
      </div>
      
      <div v-if="invoice && invoice.payment_terms" class="text-left  flex flex-col justify-between items-start">
          <p><strong>Venue payment terms</strong></p>
          <p v-html="invoice.payment_terms"></p>
      </div>
      <div class="text-center  flex flex-row justify-between items-center">
          
          <a href="https://gigpig.uk">
              <img src="@/assets/images/poweredby_pdf.jpg" alt="Powered by Gig Pig" class="h-12 w-24"/>
          </a>
          <div class="text-right" v-if="invoice">
              Ref {{invoiceItem.id}}/{{invoiceItem.gig.id}}/{{invoice.invoice_number}}
          </div>
      </div>
  </div>
  </template>
  
  <script>
  
  import format from '../../utils/format-date-default' 
  import formatDate from '../../utils/format-date'
  
  const { DateTime } = require("luxon");
  
  export default
  {
      name: "InvoiceContents",
      methods: {
          format,
          formatDate,
          signoffFormat(date){
        
            if (date == null)
            return '';
        
            return DateTime.fromFormat(date, "yyyy-MM-dd HH:mm:ss").toFormat("dd/MM/yyyy")
          },
          calculateVAT(){
              return this.invoiceItem.fee - (this.invoiceItem.fee / (1 + (this.invoice.vat_rate / 100)))
          }
      },
      computed: {
          invoice() {
              return this.invoiceItem.invoice
          }
      },    
      props:{
          invoiceItem: Object, 
      }
  }
  </script>