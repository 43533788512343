<template>
	<GigPageWrapper
		:gig-id="String($attrs.id)" 
		current-route="choose-artists"
		title="Choose artists"
		@loaded="onCalendarWrapperLoaded"
	>

        <InlineNotification Heading="Find your artists" class="mt-4">
            <template slot="body">
            <p>Choose from your favourites list, search for a specific artist, or let GigPig search for all the available artists in your area.</p>
            </template> 
        </InlineNotification> 
            <div class="lg:max-w-5xl">

                <form class="form" ref="form" novalidate> 

                <div class="col-span-full py-4">
                    <h2>How do you want to book artists - use artists you already have as favourites, or let GigPig find them for you?</h2>
                </div>


                <div class="sm:col-span-4 grid grid-flow-col items-center">
                    <label for="enabled">
                        Let me pick my artists
                    </label>
                    <t-toggle name="usefaves" id="usefaves"  ref="usefaves" v-model="use_faves" @click="switch_filter('faves')" class="justify-self-end" :disabled="!doesGigNeedBooking"/>
                    
                </div>


                <div class="sm:col-span-4 grid grid-flow-col items-center">
                    <label for="enabled">
                        Let GigPig find artists for me
                    </label>
                    <t-toggle name="usefaves" id="usefaves"  ref="usesearch" v-model="use_search" @click="switch_filter('search')" class="justify-self-end" :disabled="!doesGigNeedBooking" />
                    
                </div>         

								<div class="sm:col-span-4 grid grid-flow-col items-center">
                    <label for="enabled" class="pr-8">
                      Fast-book an artist (This will skip the availability process and assume the artist is already booked&nbsp;offline).
                    </label>
                    <t-toggle name="usehard" id="usehard"  ref="usehard" v-model="use_hard" @click="switch_filter('hard')" class="justify-self-end" :disabled="!doesGigNeedBooking"/>
                </div>

                <div v-show="use_faves || use_hard" class="col-span-full">
                <SearchByFave 
									ref="SearchByFave"  
									:artistdirectory="directory"
									:tiers="tiers" 
									:styles="styles" 
									:faveslists="faveslist"
									:defaultFees="item.gig_tiers"
									:genres="genres" 
									:select-multiple-artist="canSelectMultipleArtists"
								/>
                </div>      

                <div v-show="use_search" class="col-span-full">
                <SearchByTag ref="SearchByTag" class="pb-48"
                
                :search_styles="form.search_styles"
                :search_tiers="form.search_tiers"
                :search_genres="form.search_genres"
                
                :single_tier="true" :tiers="tiers" :styles="styles" :genres="genres"></SearchByTag>
                        </div>  
                </form>
                
                <div class="action-bar">               
                     
										<template v-if="this.item && !this.item.published">
											<GPButton 
												@click.native="save(true)" 
												:color="getOfferGigBtnColor"
												:disabled="getOfferGigBtnDisabled" 
												:loading="isLoading && saveType === 'published'"
												size="lg"
											>{{ getOfferGigBtnLabel }}</GPButton>
										</template>
										<template v-else-if="isGigCancelled">
											<span class="text-xs uppercase justify-center h-full text-gray-600">This gig has been cancelled</span>
										</template>
										<template v-else-if="this.item && this.item.published && this.item.bookings.length == 0">
											<span class="text-xs uppercase justify-center h-full text-gp_pink-default-500">{{ getGigBookedLabel }}</span>
										</template>
										
                    <GPButton 
											@click.native="save(false)" 
											:disabled="isLoading || isGigCancelled" 
											:loading="isLoading && saveType === 'unpublished'"
											size="lg"
										>Save</GPButton>              
                </div>
            </div>
            <NotificationSmall ref="notification" />
 
	</GigPageWrapper>

    

</template>

<script>
import GPButton from '../../components/ui/buttons/Button.vue'
import SearchByTag from '../../components/gigs/SearchByTag.vue'
import SearchByFave from '../../components/gigs/SearchByFave.vue'

import Pristine from '../../utils/pristine.js'
		import GigPageWrapper from '@/components/gigs/GigPageWrapper.vue'; 
import '../../utils/pristine.js'
    import { apiComputed, apiMethods } from '@/state/helpers'; 
import NotificationSmall from '../../components/notifications/small.vue';
import InlineNotification from '../../components/notifications/inline.vue' 
import formatDate from '../../utils/format-date.js';
import NProgress from 'nprogress';

import {
  TToggle 
} from 'vue-tailwind/dist/components'
    
    export default
    {
        name: "ChooseArtists",
        components:{
            GPButton,
						GigPageWrapper, 
						SearchByFave, 
						SearchByTag, 
						TToggle,  
						NotificationSmall, 
						InlineNotification
        },
        data(){
            return {
                item:{},
                form: {},
                faveslist: null,
                addedArtists: [], 
                selectedFave: null,
                selectedArtist: null,
                styles: null,
                genres: null,
                search_tiers: null,
                search_genres: null,
                search_styles: null,
                use_faves: true,
                use_search: false,
								use_hard: false,
                directory: [],
								isLoading:false,
								saveType: null,
            }
        },
        created() {    

            NProgress.start();
            this.getGigChosenArtists(this.$attrs.id).then((resp) => { 
                this.item = resp;
								this.$store.dispatch('gig/storeGig', this.item);
            }).finally(() => {
                this.loadGig();
                NProgress.done();
            });
        },
        computed:{
            ...apiComputed,
						doesGigNeedBooking(){
							return this.$store.getters['gig/doesCurrentGigNeedBooking']
						},
						getOfferGigBtnDisabled(){
							return this.use_hard ? !this.$refs.SearchByFave.addedArtists.length > 0 || this.isLoading : false;
						},
						getOfferGigBtnLabel(){
							return this.use_hard ? "Fast-book this artist" : "Offer gig to artists";
						},
						getOfferGigBtnColor(){
							return this.use_hard ? "pink" : "indigo";
						},
						getGigBookedLabel(){
							return this.use_hard ? "This gig has been booked" : "GigPig is looking for artists";
						},
						isGigCancelled(){
							return this.$store.getters['gig/isCurrentGigCancelled']
						},
						canSelectMultipleArtists(){
							return this.use_hard 
							?	this.item.max_bookings > 1 && this.$refs.SearchByFave.addedArtists.length < this.item.max_bookings ? true : false 
							: this.use_faves	
						}
        },
        mounted()  {

            this.getTiers();

            this.getArtistDirectorySimple().then((resp) => {
                this.directory = resp;
            })
        
                
        },
        methods:{
					onCalendarWrapperLoaded(){
						if(!this.pristine && this.$refs.form){
							this.pristine = new Pristine(this.$refs.form, this.pristineConfig);
						}
					},
            loadGig(){
								this.onCalendarWrapperLoaded()

                this.faveslist = this.$store.state.api.favelists;

                this.genres = this.artisttags.filter(tag => tag.tag_type != 100);
                this.styles = this.artisttags.filter(tag => tag.tag_type == 100);
 
                    this.use_faves = this.item.search_type == 100;
                    this.use_search = this.item.search_type == 200;
										this.use_hard = this.item.search_type == 300;

                    this.$refs.SearchByFave.setStaged(this.item.staged_artists);
                    

                    this.$refs.SearchByTag.search_tiers = this.item.gig_tiers.map((tag) => {
                            if (tag.tier_id)
                                return tag.tier_id
                            else return tag.tier.id;
                        });
                    this.$refs.SearchByTag.search_styles = this.item.artist_tags.filter(tag => tag.tag_type == 100).map((tag) => {return tag.id });
                    this.$refs.SearchByTag.search_genres = this.item.artist_tags.filter(tag => tag.tag_type != 100).map((tag) => {return tag.id });
    
 
            },
            formatDate,
            switch_filter(type){
							if(!this.item.published){
								this.use_faves = false;
								this.use_search = false;
								this.use_hard = false;
								this[`use_${type}`] = true;
							}
							
                // this.use_faves = !this.use_faves;
                // this.use_search = !this.use_search;
            },
                
            ...apiMethods,
        save(published = false){
	
            var valid = this.pristine.validate(); 
            if (valid)
            {
								this.isLoading = true;
								this.saveType = published ? 'published' : 'unpublished' 
                let fallbackPublished = this.item ? this.item.published : published;
    
                let tags = [];

                if (this.$refs.SearchByTag.search_genres != undefined)
                {
                    tags = this.$refs.SearchByTag.search_genres;
                } 

                if (this.$refs.SearchByTag.search_styles != undefined)
                {
                    tags = tags.concat(this.$refs.SearchByTag.search_styles);
                }
 

                let payload = { 
                    id: this.item.id,  
                    published: published ? published : fallbackPublished, // don't change publish value on save
                    publish_now: published,
                    search_type: this.use_faves ? 100 : this.use_search ? 200 : 300,
                    add_artists: this.$refs.SearchByFave.addedArtists,
                    tags: tags, 
                    tiers: this.$refs.SearchByTag.search_tiers,     
                }

                this.updateGig(payload)
                    .then(() => {
                        if (published)
                        {
													if( this.use_hard){
														this.$router.push('/gigs/' + payload.id + '/bookings'); 
													}else{
														this.$router.push('/gigs/' + payload.id + '/offers'); 
                            this.$notify("Looking for artists, check back soon.");
													}
                        }
                        else
                        {
                          this.$notify("Gig updated"); 
                        }
												this.isLoading = false;
												this.saveType = null;
                    }).catch($ex => {
                        this.$refs.notification.createNotification("An error has occurred - " + $ex.message);
												this.isLoading = false;
												this.saveType = null;
                    })
            }
            else
            {                
                this.$refs.notification.createNotification("Please check the form for errors", true);
            }
        }
        },
        
    }

</script>