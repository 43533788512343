<template>
<div>
         
    <div class=" text-left flex flex-col lg:flex-row lg:min-h-100vh">

        
        <div class="min-w-full lg:min-w-30% lg:max-w-30%">
                <div class="bg-white ">
        <div class="lg:mx-auto">
          <div class="pb-6 md:flex md:items-start  ">
            <div class="flex-1 min-w-0">
              <!-- Profile --> 
                  <div class="px-3 flex flex-col   space-y-4 w-full">
                    <h1 class="text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                      Your Messages
                    </h1>

                            <TRichSelect class="w-full" v-model="gigID" @change="refreshBidList" required id="gig-selection" ref="gigpicker" name="bid-selection"                      
                                :options="this.sorted_gigs.map((option) => ({ value : option.id, text: option.name + ': ' + format(option.start, 'dd/MM/yyyy') + ' ' +  (option.latest_bid_message == null ? '' : option.latest_bid_message)  }))" > 
                            </TRichSelect>

                  </div>  
            </div> 
          </div>
        </div>
      </div>
            <ul   role="list" class="divide-y divide-gray-200" v-if="!gig || !gig.bids">
                 <li  class=" relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                       <div class="flex justify-between space-x-3">
                       <div class="min-w-0 flex-1">

                <p class="line-clamp-2 text-center text-sm text-gray-600 min-w-300px">
                </p>
                       </div>
                       </div>
                 </li>
            </ul>
            <ul   role="list" class="divide-y divide-gray-200" v-if="gig && gig.bids">  
            <li @click="selectBid(bid)" v-for="(bid, index) in gig.bids" v-bind:key="index" class="  relative bg-white hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                <div  v-bind:class="selectedBid && selectedBid.id == bid.id ? '  border-indigo-500 bg-gray-100 ' : 'border-white'" class="border-l-4 py-5 px-4" >
                <div class="flex justify-between space-x-3">
                    <div class="min-w-0 flex-1">
                        <a href="#" class="block focus:outline-none">
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            <p class="text-sm   text-gray-900 truncate" v-if="bid && bid.artist">{{bid.artist.name}}</p>
                            <p class="text-sm text-gray-500 truncate" v-if="gig && gig.venue">{{gig.venue.name}}</p>
                        </a>
                    </div>
               
                    <div class="flex flex-row gap-2 align-top items-start" v-if="bid.messages.length > 0">
                        <span class="text-sm bg-gp_pink-default-100  rounded text-gray-500 p-1" v-if="isRecentMessageRead(bid.messages)">Unread</span>
                        <time :datetime="bid.created_at" class="flex-shrink-0 whitespace-nowrap text-sm text-gray-500" v-if="getRecentMessage(bid.messages).message">
                              {{ formatTimeago(getRecentMessage(bid.messages).message.created_at)}} ago
                        </time>
                    </div>
                </div>
                <div class="mt-1">
                <p class="line-clamp-2 text-sm text-gray-600" v-if="bid && bid.messages">
                   {{getRecentMessage(bid.messages).snippet}}
                </p>
                </div>
                </div>
            </li>
           


            <!-- More messages... -->
            </ul>
        </div>
        <div   v-if="selectedBid"  v-click-outside="closeMenu"  class="w-full 3xl:max-w-30% relative">

     <transition :duration="1000" appear enter-active-class="animate__animated animate__fadeInRight" leave-active-class="animate__animated animate__fadeOutRight">
            

<div v-if="isMessagePanelOpen" class="absolute top-0 right-0 w-8/12 p-4 h-full bg-gray-200 shadow-2xl flex flex-col gap-4">
    <p>Replying to {{selectedBid.artist.name}}</p>
    <div class="text-gray-500 text-sm">
    <p>{{format(selectedBid.gig.start)}}</p>
    <p>{{selectedBid.gig.name}}</p>
    </div>
<p>
    {{getRecentMessage(selectedBid.messages).snippet}}
</p>
    <textarea v-model="messageBody" rows="10" class="w-full shadow-inner" ref="reply-message" placeholder="Type your message here..." />

    <button @click="(reply(selectedBid))" class="bg-gp_pink-default-500 text-white p-4 rounded hover:bg-indigo-500 flex flex-row justify-center items-center gap-4">
        <img src="@/assets/images/icons/icon-mail.svg" alt="mail icon"/> Send Message
    </button>
</div>

     </transition>
<div  class="p-5 w-full border-b bg-gray-100 border-gray-200 sm:flex sm:items-center sm:justify-between" >
  <div class="text-gray-400 text-sm">
    <h3 class="text-lg leading-6 font-medium text-gray-900" v-if="selectedBid.gig.venue">
        {{selectedBid.gig.venue.name}}
    </h3>
    <p>{{format(selectedBid.gig.start)}}</p>
    <p>{{selectedBid.gig.name}}</p>
  </div>
  <div class="mt-3 flex sm:mt-0 sm:ml-4">
    <router-link :to="selectedBid.accepted ? '/gigs/' + selectedBid.gig.id + '/bookings' : '/gigs/' + selectedBid.gig.id + '/offers'" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      View Booking Details
    </router-link >
    <button type="button" 
                    v-on:click="toggleMessagePanel" class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gp_pink-default-500  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      Reply
    </button>
  </div>
</div>

<div class="p-5 w-full border-b  border-gray-200 sm:flex sm:items-center sm:justify-start gap-12 shadow">
  <div>
      <img class="rounded-full  w-10 h-10" v-if="selectedBid.artist.image" :src="selectedBid.artist.image" alt="profile picture" />
  </div>
  <div class="flex flex-row justify-between w-full ">
        <div class="text-gray-400 text-sm ">
            <h4 class="text-base leading-6 font-medium text-gray-900">
           {{selectedBid.artist.name}}
            </h4>
        </div>
        <div>
            <p>£{{selectedBid.fee}}</p> 
        </div>
  </div>
</div>
<div @click="closeMenu" class="bg-gray-200 p-12 max-h-80vh  min-h-80vh overflow-y-scroll flex flex-col ">

    <div v-if="selectedBid.messages.length == 0">
        There are no messages from this artist
    </div>

    <div v-for="(message, index) in selectedBid.messages.slice().reverse()" v-bind:key="index">
    <div class="bg-white p-4 mb-4 flex flex-col gap-4 ml-12" v-if="!messageFromMe(message)">
        <div class="w-full text-right">
            <h2 v-if="message.user"><strong>{{message.user.name}} said</strong></h2>
            <h3 class="text-gray-400">{{format(message.created_at)}}</h3>
        </div>
        <p>
            {{message.message}}
        </p>
    </div>
    <div v-else class="bg-white p-4 mb-4 flex flex-col gap-4">
        <h2><strong>You said</strong></h2>
        <h3 class="text-gray-400">{{format(message.created_at)}}</h3>
        <p> 
            {{message.message}}
        </p>
    </div>
    </div>
    

</div>
        </div>
        <div v-else class="p-5 w-full border-b bg-gray-100 border-gray-200 sm:flex sm:items-center sm:justify-between" >
            <div class="text-gray-400 text-sm  h-full"> 

            </div> 
</div>
    </div>
</div>
</template>

<script>
import { apiMethods, apiComputed } from '@/state/helpers';  
import { TRichSelect } from 'vue-tailwind/dist/components';
import format from  '@/utils/format-date-default.js';
import formatTimeago from  '@/utils/format-date-timeago.js';
import NProgress from 'nprogress';
export default {
    name: "MessageList",
       data: () =>
         ({
        isMessagePanelOpen: false ,
        messageBody: '',
        sorted_gigs: [],
        gigID: null,
        gig: null,
        gigs: null,
        selectedBid: null,
        uuid: '',
        messages: [],
        loadGig: null
    }),
    created(){
        this.fetchGigs();
    },
    components:  {
        TRichSelect
    }, 
    ...apiComputed, 
    methods: { 
        ...apiMethods,
        fetchGigs(){
            NProgress.start();
            this.getGigsMessages().then((resp) => {
                this.gigs = resp.filter((gig) => {
                    return gig.bids_count > 0
                });
            }).finally(() => {
                this.loadData();
                NProgress.done();
            })
        },
        loadData(){
            let $self = this; 
            this.sorted_gigs = this.gigs.sort((a, b) => a.most_recent_message_date > b.most_recent_message_date);
            this.uuid = this.$store.state.api.uuid;
            let channel = null;
            if (this.$echo)
            {            
                channel = this.$echo.channel(this.uuid);
            }

            this.$refs.gigpicker.selectedOption = {
                text: 'Pick a gig to begin',
                value: ''
            }; 

            if (this.$route.params.gigID)
            {
                this.gigID = this.$route.params.gigID;
                this.$refs.gigpicker.selectedOption = this.gigID;
                this.getGig(this.gigID).then((resp) => {
                    this.loadGig = resp; 
                    this.gig = resp;
                    $self.$refs.gigpicker.selectedOption = {
                        text: resp.name,
                        value: resp.id.toString()
                    };
                    $self.$refs.gigpicker.selectedOption.text = resp.name;

                    if(this.$route.params.bidID)
                    {
                        this.selectedBid = this.gig.bids.find((bid) => bid.id == this.$route.params.bidID);
                    }
                }).finally(() =>{
                    this.refreshBidList();
                });
            }
            else if (this.$route.params.bidID)
            {
                this.remountBid(this.$route.params.bidID);
            }
            else{ 
                this.gigID = this.sorted_gigs.length > 0 ? this.sorted_gigs[0].id : null;
            }


            if (channel)
            {
                //prevent stacking multiple subscriptions to same channel
                channel.unsubscribe();
                channel.subscribe();
            //TODO: Move this to global scope
            channel.listen('NotifyUser', function(data) {



                $self.getGigWithOffers($self.gig.id).then((resp) => {
                    console.log("refresh", resp);                
                    $self.gig = resp;
                    $self.selectBid(resp.bids.find((bid) => bid.id == $self.selectedBid.id));
                    //$self.selectBid($self.selectedBid);
                });


                $self.$notify({ 
                    title: data.notification.title,
                    text: data.notification.body
                });



                let notification = {
            title: data.notification.title,
            options: {
                body: data.notification.body
            },
            events: {
                onerror: function () {
                    console.log('Custom error event was called');
                },
                onclick: function () {
                    console.log('Custom click event was called');
                },
                onclose: function () {
                    console.log('Custom close event was called');
                },
                onshow: function () {
                    console.log('Custom show event was called');
                }
            }
            } 

                $self.$notification.requestPermission() .then(console.log);
                $self.$notification.show(notification.title, notification.options, notification.events);

            });
            }

        },
        format,
        formatTimeago,
        ...apiMethods,
        // eslint-disable-next-line no-unused-vars
        remountBid($bidid){
            
            this.refreshBidList();
            let $bid = this.gig.bids.find((bid) => bid.id == $bidid);
            this.selectBid($bid);
        },
        refreshBidList(){
            let $self = this;
            console.log("gigid", this.gigID);
            console.log("gigs", this.gigs);

            if (this.gigID){
             this.getGigMessages(this.gigID).then((resp) => {
                $self.gig = resp;
             });
            }
            
        },
        messageFromMe($message)
        {
            if ($message.user)
                return $message.user.uuid == this.uuid;
            else return false;
        },
        selectBid($bid){
            let $self = this;
            this.selectedBid = $bid;
            
            console.log("bid", $bid);
            
            this.messages = $bid.messages;
            let $recentMessage =this.getRecentMessage($bid.messages); 
            console.log($recentMessage);
            if ($recentMessage.message)
            { 
                this.markAsRead({id: $recentMessage.message.id}).then(() => { 
    
                    $self.getGigs().then((resp) => {
                        console.log("resp", resp);
                        $self.gigs = resp;
                        this.refreshBidList();
                    });               
                });
            }
        },
        isRecentMessageRead($messages){
            
            let $message = this.getRecentMessage($messages).message;
            
            if ($message)
            {
                return !$message.is_read;
            }
            else{
                return true;
            }
        },
        getRecentMessage($messages){

            if ($messages.length > 0)
            {
                let message = $messages.at(-1);
            
                if (message)
                {
                    console.log(message);
                    return  {
                        message: message,
                        snippet: message.message.substring(0, 30)
                    };
                } 
            }

             return {
                message: null,
                snippet: "No messages.",
             };
        },
        toggleMessagePanel() { 
            this.isMessagePanelOpen = !this.isMessagePanelOpen;
        },
        reply($bid){
            
            let $payload = {
                message: this.messageBody,
                bid: $bid /* bid */
            }

            let self = this; 
            this.$notify("Posting...")
            this.sendMessage($payload).finally(() => { 
                self.remountBid($bid.id);
                this.$notify("Message posted")
            });




        },
        closeMenu() {
            if (this.isMessagePanelOpen)
                this.isMessagePanelOpen = false;
        }
    }
}
</script>