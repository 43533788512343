<template>
    <div class="bg-gray-50 sm:rounded-lg max-w-4xl mx-0 mt-4 grid gap-4 grid-flow-row lg:grid-flow-col items-center px-4 py-5 sm:p-6">
        <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{Heading}}
            </h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500 grid grid-flow-row gap-4">
                <slot name="body" />
            </div> 
        </div> 
         <slot name="cta"/>
    </div>
</template>

<script>
export default {
    name: "inline-notification",
    props:{
        HtmlBody: String,
        Heading: String
    }
}
</script>