<template>


    <div class="col-span-full">
        <p class="col-span-full my-4">If you already know who you want for this gig, you can manually select them here.  To set up your favourites list, <router-link to="/artist-directory" class="text-gp_pink-default-500"> you can browse our artists here.</router-link></p>

        <div class="grid grid-flow-row lg:grid-flow-col lg:grid-cols-12 col-span-full gap-4 items-center">
            <TRichSelect 
							ref="faves" 
							class="col-span-12 lg:col-span-5"
							:close-on-select="true" valueAttribute="id" textAttribute="name" v-model="selectedFave"
							placeholder="Select favourite list to add to this gig" :options="faveslists" 
							@change="selectFave()"
							:disabled="selectArtistDisabled"
						></TRichSelect>
            <span class="col-span-12 lg:col-span-1 text-center">OR</span>
            <TRichSelect 
							ref="artistdirectoryselect"  
							class="col-span-12 lg:col-span-5"
							:close-on-select="true" valueAttribute="id" textAttribute="name" v-model="selectedArtist"
							placeholder="Add a specific artist" :options="artistdirectory" 
							@change="selectArtist()"
							:disabled="selectArtistDisabled"
						></TRichSelect> 

        </div>
        <!-- This example requires Tailwind CSS v2.0+ -->
        <ul role="list" class="grid grid-cols-1 mt-4 gap-2">
        <li class="grid grid-flow-row grid-cols-6 col-span-3 bg-white rounded-lg shadow divide-y divide-gray-200" v-for="(addedArtist, index) in addedArtists" v-bind:key="index">
            <div class="col-span-full lg:col-span-3 w-full flex items-center justify-between px-6 py-2 space-x-2">
            <div class="flex-1 truncate">
                <div class="flex items-center space-x-3">
                <h3 class="text-gray-900 text-sm font-medium truncate">{{addedArtist.artist.name}}</h3>
                <span class="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                    {{addedArtist.artist.tier.name}}
                </span>
                </div>
                <p class="mt-1 text-gray-500 text-sm truncate">
                    {{
                        addedArtist.artist.artist_tags && addedArtist.artist.artist_tags.length > 0 ?
                        addedArtist.artist.artist_tags.map(tag => tag.name).join(', ')
                        : 'No tags'
                    }}</p>
            </div>
            </div> 
            <div class="-mt-px col-span-full lg:col-span-2 flex divide-x divide-gray-200">
                <div class="w-0 flex-1 flex-col flex p-2">  
                <InputText :name="'fee_' + addedArtist.id"  v-on:input="updateFee($event, addedArtist.artist);" v-bind:value="addedArtist.fee"  label="Fee" prefix="£" :placeholder="'Use ' + addedArtist.artist.tier.name + ' fee'">
                </InputText>  
                </div> 
            </div> 
            <div class="h-full col-span-full lg:col-span-1 divide-gray-200">
                <div class="flex p-2 text-center justify-center items-center h-full"> 
                <a class="text-gp_pink-default-500 text-sm" @click="removeArtist(addedArtist.artist)">Remove Artist</a>
                
                </div>  
            </div>
        </li>

        <!-- More people... -->
        </ul>
    </div>
</template>

<script>


import InputText from '../form/InputText.vue';

import {
  TRichSelect
} from 'vue-tailwind/dist/components'

export default {
    name: "SearchByFave",
    components: {
        InputText,
        TRichSelect
    },
    
    mounted(){ 
    },
    props:{
        defaultFees:{
					type: Array,
					default: () => [],
				},
        tiers: Array,
        genres: Array,
        styles: Array,
        artistdirectory: Array,
        faveslists: Array, 
				selectMultipleArtist:{
					type:Boolean,
					required:false,
					default:true,
				}
    },
		data(){
        return {
            addedArtists: [],
            selectedArtist: null,
            selectedFave: null,
            tempFees: [],
						updateableDefaultFees: this.defaultFees,
        }
    },
		computed:{
			selectArtistDisabled(){
				return !this.selectMultipleArtist && this.addedArtists.length > 0;
			},
		},
		watch:{
			defaultFees(updatedDefaultFees){
				this.updateableDefaultFees = updatedDefaultFees;
			}
		},
    methods:{
        

        setStaged($staged_artists){
 
            $staged_artists.forEach((added_artist) => {

                this.updateFee(added_artist.supersede_fee.toString(), added_artist.artist)
            });
        },
        pushToArray ( arr, obj ) {

        var existingIds = arr.map((obj) => obj.id);

            if (! existingIds.includes(obj.id)) {
                arr.push(obj);
            } else {
                arr.forEach((element, index) => {
                    if (element.id === obj.id) {
                    arr[index] = obj;
                    }
                });
            }
        },
        updateFee($fee, $artist){    
             
            if ($artist)
            {
                this.pushToArray(this.addedArtists, {id: $artist.id, fee: $fee, artist: $artist});
            }
        },
        setDefaultFees($fees){
					this.updateableDefaultFees = $fees;  
        },
        addArtistToTemplate($artist){  
 
            let fee = 0;
 
            if (this.updateableDefaultFees)
            {

                let $defaultFee = this.updateableDefaultFees.find((defaultFee) => 
                {


                    let tierID = defaultFee.tier_id;

                    if (!tierID){
                        tierID = defaultFee.tier.id; 
                    }

                    let artist_tier_id = $artist.tier_id;

                    if (!artist_tier_id)
                    {
                        artist_tier_id = $artist.tier.id;
                    }
 

                    return  tierID == artist_tier_id
                });

                if ($defaultFee)
                { 
                    fee = $defaultFee.requested_fee;
                }
            }

            this.updateFee(fee.toString(), $artist);     
        },
        selectArtist(){
            this.addArtistToTemplate(this.$refs.artistdirectoryselect.selectedOption.raw);
        },
        removeArtist($artist){ 
 
            this.addedArtists.splice(this.addedArtists.findIndex(addedArtist => addedArtist.artist.id === $artist.id), 1);
        },
        selectFave(){

            let $artists = this.$refs.faves.selectedOption.raw.artists.map(artist => artist);

            $artists.forEach((artist) => {
                this.addArtistToTemplate(artist);
            });
                            
        },
    }
}

</script>