<template>


<div> 
        <div class="p-4 grid grid-cols-1 gap-4 sm:grid-cols-1 bidblock max-w-6xl">
          <div v-for="(date, index) in groupedDatesbyArray"  :key="'datepick' + index">
          <div  :class="index == selectedIndex ? 'selected' : ''"  class="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
            <div class="flex-1 min-w-0 ">
              <a @click="filter(index)" class="focus:outline-none grid gap-4 lg:grid-flow-row grid-flow-col">  
                <span class="absolute inset-0" aria-hidden="true"></span>
                <div class="grid grid-flow-col justify-between">
                <div class="grid grid-flow-col lg:grid-cols-2 gap-4">
                  <p class="text-sm font-medium text-gray-900">{{date[0].start}}</p>
                  <p class="text-sm text-gray-500 truncate  hidden lg:block" >{{pluralize(date.length, 'offer')}} awaiting</p>
                </div>
                <span v-if="index == selectedIndex" class="uppercase text-xs">Selected</span>
                <span v-else class="uppercase text-xs">Click to view offers</span>
                </div>
              </a>
            </div>

      </div>
            
      <div class=" flex flex-col " v-if="index == selectedIndex"> 
<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div    v-show="items && items.length"
              class="max-w-6xl shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >

            

              <table class="min-w-full w-max divide-y relative divide-gray-200 text-left" >
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                    <th
                      scope="col"
                      class="relative px-6"
                    >
                      Date
                    </th> 
                    <th scope="col" class="relative px-6 py-3"> 
                        Venue
                    </th>
                    <th scope="col" class="relative px-6 py-3"> 
                        Artist
                    </th>
                    <th scope="col" class="relative px-6 py-3"> 
                        Fee
                    </th> 
                    <th scope="col" class="relative px-6 py-3"> 
                        
                    </th> 
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr class="relative" v-for="(bid, index) in items" :key="'key' + index"> 
                  
                      <td class="p-6">
                        
                        <router-link class="text-gp_pink-default-500" v-if="!bid.cancelled && !bid.declined && !bid.gig.cancelled" :to="'/artist/offers/' + bid.id" >View offer</router-link>
                      </td>
                      <td class="p-6" v-if="bid.gig">             
                          {{  fullDate(bid.gig.start) }}   
                      </td>
                      <td class="p-6" v-if="bid.gig && bid.gig.venue && bid.gig.venue.address">
                         {{bid.gig.venue.name}}<br/>
                         {{bid.gig.venue.address.town}} 
                      </td>
                      <td class="p-6" v-if="bid.artist">                             
                          {{  bid.artist.name }}                               
                      </td> 
                      <td class="p-6">                             
                          £{{ bid.fee }}                               
                      </td>
                      <td class="p-6">                         
<span v-if="bid.gig.cancelled">
  This gig has been cancelled
</span>                          
<span v-else-if="bid.declined" class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
  You declined this offer
</span>                   
<span v-else-if="!bid.date_bid && !bid.declined && !bid.accepted" class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
  Needs your availability
</span>                   
<span v-else-if="bid.date_bid && !bid.declined && !bid.accepted" class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
  Awaiting venues confirmation
</span>       
<span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800" v-else-if="bid.accepted && !bid.artist_final_accepted">
  Needs your final acceptance
</span>      
                                                     
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
          </div>

            <div v-show="groupedDatesbyArray.length == 0">
              <p>{{noItemsText}}</p>
            </div>
          <!-- More people... -->
        </div>
 
</div>

</template>

<script type="text/javascript">

import fullDate from  '@/utils/format-date-full.js'; 
import sortBy from  '@/utils/sortby.js'; 
import lodash from 'lodash';
import pluralize from  '@/utils/pluralize'
import { apiComputed } from '@/state/helpers.js';

    export default {
        name: "OfferByDate", 
        data(){
          return {
            items: [], 
            filteredBids: [],
            selectedIndex: -1, 
            groupedDatesbyArray: [],
            openFirstByDefault: false
          }
        },
        props:{
          unfilteredBids: Array,          
          noItemsText: String
        },
        mounted(){
           
          this.loadData();

        },
        computed:{
          ...apiComputed
        },    
				watch:{
					unfilteredBids(){
						this.loadData()
					}
				},
        methods:
        {      
          loadData(){
            let groupedDates = lodash.groupBy(this.unfilteredBids, bid => {
              return new Date(bid.gig.start);
            });

            let keys = Object.keys(groupedDates);

            keys.forEach(date => {
              
            this.groupedDatesbyArray.push(this.unfilteredBids.filter(b =>
             {    console.log("inner unfliter grouped", new Date(b.gig.start)); 
                console.log("inner unfliter", date); 
                 return new Date(b.gig.start) == date
             })
            );

          this.groupedDatesbyArray.sort(function(a, b){

             return new Date(a[0].gig.start) - new Date(b[0].gig.start); 
          })
 
          
          console.log("mounted?", this.unfilteredBids);

        });

            if (this.openFirstByDefault) {
              this.filter(0);
            }
          },    
          filter(index){
 

            if (this.groupedDatesbyArray){
              
              this.selectedIndex = index;

              this.items = this.groupedDatesbyArray[index];
            }
          },
          pluralize,
          fullDate,
          sortBy,        
        }

    }

</script>