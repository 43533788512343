<template>
    <div v-if="modal_open" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            
            </div>
                    
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    
            <transition appear enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">        
                <div v-click-outside="toggleModal" class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                    <div>
                        <div class="mx-auto grid grid-flow-row gap-4 w-full ">

                            <h3 class="text-xl font-bold leading-6 text-gray-900 justify-between flex" id="modal-title">
                                Adding to your favourites lists
                                <span class="text-gp_pink-default-500 text-base">Adding {{Artist.name}}</span>
                            </h3> 
                            <InlineNotification class="mx-0 p-0 important py-0">
                                <template slot="body">
                                <p>You can create as many favourite lists as you like.  These lists help you find the artists you love quicker, for those gigs you host often.</p>
                                <p class="block mt-4">Each list can then be used in your gig templates and gigs. You can also manage your lists via 
                                    <router-link to="/favourites" class="text-gp_pink-default-500">
                                    "My favourites"</router-link>
                                     in the main menu.</p>
                                </template> 
                            </InlineNotification> 
                            <div>
                                <label>Add to an existing list</label>
                                <TRichSelect 
                                    :close-on-select="false" multiple ref="favelist" :options="faveslists" textAttribute="name" valueAttribute="id"
                                    placeholder=""  class="w-full" >
                                </TRichSelect> 
                            </div>
                            <div>
                                <label>Or create a new list and add this artist</label>
                                <TInput ref="favename" v-model="favename" placeholder="Name your new list" class="w-full" />
                            </div>
                        </div>
                        <div class="text-left sm:mt-5">
                            <div class="text-sm">
                                <form  class="form mb-36 lg:mb-24">
                                
                                    
                                    <div class="action-bar px-6 w-full justify-between">  
                                        <a @click="toggleModal()" class="gp_cta  w-full justify-center bg-indigo-500">Close</a> 
                                            <a @click="save()" class="gp_cta w-full justify-center bg-gp_pink-default-500" >Save & add artist</a>  
                                    </div> 

                                </form> 
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>

</template>

<script type="text/javascript"> 
    import { TRichSelect, TInput } from 'vue-tailwind/dist/components';
    import InlineNotification from '../notifications/inline.vue'; 
    import { apiMethods, apiComputed } from '@/state/helpers'; 

    export default {
        name: "FaveManagement",
        components:{
            TRichSelect,
            TInput,
            InlineNotification
        },
        props:{
            Artist: Object,
            ShowModal: Boolean
        },
        data(){
            return {
                modal_open: false,
                favename: '',
                favelist: null, 
                ddList: null,
                faveslists: null,
            }
        }, 
        ...apiComputed,
        mounted(){
                  this.getFavesLists().then((resp) =>{
                        this.faveslists = resp;
                });
        },
        methods:{
            emitUpdateList(){
                this.$emit("updateList", this.favelist);
            },
            ...apiMethods,
            toggleModal(){
                this.modal_open = !this.modal_open; 

                

            },
            save()
            {  
                let $fave = this.favename;
                let $artist_id = this.Artist.id; 
                        
                let $self = this;

                let payload = 
                        {
                            name: $fave, 
                            artists: [{ id:$artist_id }] 
                        };

								let newFavesId = null;
                if (this.favename != ''){
                    this.createFavesList(payload)
										.then((resp) => {            
												console.log(resp);
												newFavesId = resp.id
                        // this.faveslists = resp.data;
												// Add the artist!
						
                    }).then(() => {
											console.log(newFavesId);
											this.createFave({
												favelists : newFavesId,
												artist_id : $artist_id  
											});
										})
										.finally(() => {
                        this.emitUpdateList();
                        $self.toggleModal(); 
                    });
                    // set notification - list created and x artist added,

                    this.$notify({ 
                        title: 'List created',
                        text: this.Artist.name + ' added to list'
                    });

                }else{

                    let $favelists = this.$refs.favelist.selectedOptions.map(option => option.value);

                    let $payload = {
                        favelists : $favelists,
                        artist_id : $artist_id  
                    }

                    this.createFave($payload);


                    this.$notify({ 
                        title: 'Lists updated',
                        text: this.Artist.name + ' added to lists'
                    });
                this.toggleModal(); 

 
                }

            }
        }
    }

</script>