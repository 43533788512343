<template>
    <span class="badge " :class="item.status.class">
        {{item.status.name}}
    </span>
</template>

<script>
export default {
    name: "GigStatus",
    data(){
        return {
            item: this.gig
        }
    },
    computed: 
    {   
    },
    props:{
        gig: Object
    }
}
</script>