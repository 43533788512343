<template>
	<div>
		
		<!-- -->

		<CalendarWrapper
			calendar-type="Gigs"
			:view-options="calendarViewOptions"
			:available-status-keys="calendarAvailableStatusKeys"
			:schedules="schedules"
			@selected="scheduleSelected"
			@update="onCalendarUpdated"
			@refresh="onCalendarRefresh"
			@create="onCalendarCreate"
		>
			<template v-slot:filters>
				<!-- User Venue filter -->
				<TRichSelect 
					id="venue-selection" 
					ref="venuepicker" 
					name="venue-selection" 
					v-model="filteredVenues"
					placeholder="Filter by venue"                    
					:options="venues"
					value-attribute="id" 
					text-attribute="name" 
					class="w-full"
				/>
			</template>

			<template v-slot:additionalFilters>
				<div class="flex justify-end items-center col-span-full pt-1 pb-4">
					<span class="mr-4 text-sm md:text-md" v-if="toggleArtistsNames">Showing artist name</span>
					<span class="mr-4" v-else>Showing gig name</span>
					<SwitchToggle 
						:enabled="toggleArtistsNames"
						@updateEnable="onToggleArtistsNames"
					/>
				</div> 
			</template>

		</CalendarWrapper>
		<!-- Schedule modal -->
		<calendar-preview 
			ref="calendarPreview" 
			:isArtistView="false" 
			:bid-id="selectedBidId"
			:show-as-bid="false"
		/>
	</div>
</template>

<script>
	import NProgress from 'nprogress'
	import { TRichSelect } from 'vue-tailwind/dist/components';
	import CalendarWrapper from '@/components/calendar/CalendarWrapper.vue';
	import CalendarPreview from '@/components/modal/CalendarPreview.vue';
	import SwitchToggle from '@/components/ui/form/SwitchToggle.vue'
	import { getStatusesFromTypes, getStatusFromType, hasGigBeenBooked  } from '@/state/models/gigStatus';
	import normalizeDate from '@/utils/normalizeDate';
	const { DateTime } = require("luxon");

	const VENUE_LOCK_KEY = "LOCK_VENUE_1";
	const ARTIST_TOGGLE_KEY = "SHOW_ARTIST_NAME";

export default {
	components:{
		TRichSelect,
		CalendarWrapper,
		CalendarPreview,
		SwitchToggle
	},
	data(){
		return{
			gigs:null,
			venues:null,
			schedules:[],
			filteredGigs:[],
			filteredVenues:null,
			isLoading:false,
			selectedBidId:null,
			calendarViewOptions:[
				{ value: 'month', text: 'Month view'}, 
				{ value: 'week', text: 'Week view'},
				{ value: 'day', text: 'Day view'},
			],
			calendarAvailableStatusKeys: getStatusesFromTypes([
				'UNPUBLISHED',
				'CANCELLED',
				'NEEDS_RESPONSE',
				'PENDING',
				'CONFIRMED',
				'NEEDS_SIGNOFF',
				'SIGNED_OFF',
			]),
			calendarParams:{
				currentDate: null,
				endDateRange: null,
				startDateRange: null,
				view: null,
			},
			toggleArtistsNames:false,
			loadingGigs:false
		}
	},
	computed:{
		bankHolidays() {
			return this.$store.getters['bankHolidays/bankHolidays']
		},
	},
	watch:{
		filteredVenues(){
			localStorage.setItem(VENUE_LOCK_KEY, this.filteredVenues);
			this.setCalendarItems();
		}
	},
	methods:{
		parseGigToSchedule(gig){
			const status = getStatusFromType(gig.status)
			const time = DateTime.fromISO(gig.start).toUTC().toFormat('t').replaceAll(' ', '').toLowerCase(); 
			// Title
			const scheduleTitle = !this.toggleArtistsNames ? gig.name : hasGigBeenBooked(gig.status) ? gig.bookings.length ? gig.bookings[0].artist.name : '-' : status.name;
			// End date/time
			let end = gig.end;
			if (new Date(gig.end).getHours() < new Date(gig.start).getHours()) {
				end = DateTime.fromISO(gig.start).toUTC().set({hours: 23, minutes: 59});
			}
			return {
				id: gig.id,
				title: time + ' ' + scheduleTitle,
				category: 'time',
				start: normalizeDate(gig.start),
				end: normalizeDate(end),
				calendarId: 0,
				raw: gig,
				bgColor: status.bg,
				color: status.color,
				customStyle: 'background-color:' + status.bg
			}
		},
		parseGigs(){
			this.filteredGigs = this.gigs
				.filter((g) => {
					return g.status && this.calendarAvailableStatusKeys.filter(i => i.type === g.status).length > 0
				})
				.map((fg) => this.parseGigToSchedule(fg))
			this.setCalendarItems();
		},

		setCalendarItems(){
			this.schedules = [];
			let schedules = [ ...this.filteredGigs ]
			// check for filter
			if(this.filteredVenues && this.filteredVenues !== "all"){
				schedules = schedules.filter(s => String(s.raw.venue.id) === String(this.filteredVenues));
			}

			const bankHolidays = [...this.bankHolidays]
					.map((bankHoliday) => {
							return {
									title: bankHoliday.region === 'England and Wales' ? bankHoliday.title : `${bankHoliday.title} (${bankHoliday.region})`,
									category: 'time',
									start: bankHoliday.date,
									calendarId: 0,
									bgColor: '#E0BBE4',
									color: '#E0BBE4',
									customStyle: 'background-color:' + '#E0BBE4'
							}
					})

			this.schedules = [...schedules, ...bankHolidays];
		},

		scheduleSelected(schedule){
			if(!this.$store.getters['user/hasManageGigsPermission']){
				return null;
			}
			let preview = this.$refs.calendarPreview;
			preview.item = schedule.raw
			preview.viewModal = true;
		}, 

		getGigsByRange(){
			this.loadingGigs = true;
			NProgress.start();
			let range = {
				'from' : DateTime.fromJSDate(this.calendarParams.startDateRange).toFormat('yyyy-MM-dd'), 
				'to': DateTime.fromJSDate(this.calendarParams.endDateRange).plus({days: 1}).toFormat('yyyy-MM-dd')
			}
			// 
			this.$store.dispatch('gig/getGigsByRange', range)
			.then((response) => {
				this.gigs = response;
				this.parseGigs();
				NProgress.done();
				this.loadingGigs = false;
			})
			.catch((err) => {
				console.log(err)
				NProgress.done();
				this.loadingGigs = false;
			})
		}, 
		onCalendarUpdated($event){
			this.calendarParams = $event
			this.getGigsByRange();
		},
		onCalendarRefresh(){
			this.getGigsByRange();
		},
		onCalendarCreate(event){
			if(this.$store.getters['user/hasManageGigsPermission']){
				this.$router.push({
					path: "/gigs/add", 
					query: {
						start: event.start._date.toISOString(),
						end: event.end._date.toISOString() 
					}
				});
			}
		},
		onToggleArtistsNames(){
			this.toggleArtistsNames = !this.toggleArtistsNames
			this.parseGigs();
			localStorage.setItem(ARTIST_TOGGLE_KEY, this.toggleArtistsNames);
		},
		setKeysFromLocalStorage(){
			let venueKeyValue = localStorage.getItem(VENUE_LOCK_KEY);
			if (localStorage.getItem(VENUE_LOCK_KEY)){ 
				this.filteredVenues = Number(venueKeyValue)
			}
			//
			let artistKey = localStorage.getItem(ARTIST_TOGGLE_KEY);
			if (artistKey){
				this.toggleArtistsNames = Boolean(artistKey);
			}
		},
	},
	mounted(){
		this.isLoading = true;
		NProgress.start();
		this.$store.dispatch('venue/getAllVenues')
		.then((response) => {
			this.venues = [
				{ name:"All venues", id:"all" },
				...response
			];
			// 
			this.setKeysFromLocalStorage();
			// 
			this.isLoading = false;
			if(!this.loadingGigs){
				NProgress.done();
			}
		})
		.catch((err) => {
			console.log(err)
			this.isLoading = false;
			if(!this.loadingGigs){
				NProgress.done();
			}
		})

		this.$store.dispatch('bankHolidays/getBankHolidays')
	}
}
</script>