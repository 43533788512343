<template>
           <VueHtml2pdf 
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            filename="gig_pig_invoice"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            
image="{ type: 'jpg', quality: 0.95 }"
    
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <!-- PDF Content Here -->
                <InvoiceItem v-for="(page, index) in invoiceItems" :key="index" :invoiceItem="page" class="html2pdf__page-break" />
            </section>
        </VueHtml2pdf>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import InvoiceItem from './contents.vue'
export default{
    name: "InvoiceGeneration",
    props:{
        invoiceItems: Array
    },
    components:{
        InvoiceItem, VueHtml2pdf
    },
    methods:{       
        generate(){ 
            console.log("items", this.invoiceItems);
            this.$refs.html2Pdf.generatePdf()
        },     
        onProgress(loading){
            console.log(loading);
        },
        hasGenerated(loading){
            console.log(loading);
        },
        hasStartedGeneration(loading){
            console.log(loading);
        },
    }
}

</script>