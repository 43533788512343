<template>

<div class="lg:ml-4">
    
    <InlineNotification Heading="Favourite artists">
        <template slot="body">
          <p>Creating your very own favourites list is like shopping for your favourite artists, and adding them to your “cart”.</p>
          <p>This allows you to quickly add a list of artists to your gig and send them gig offers. It’s really useful if you have a different style artist on different days of the week, for example, “Friday solo acoustic singers” and “Saturday house DJs”.</p>
                
         <p>Start by <router-link to="/artist-directory" class="text-gp_pink-default-500">browsing the GigPig artist directory</router-link> create a new list and add your favourites to it.
          </p>
        </template>
    </InlineNotification>
     

      <div class=" flex flex-col mt-4 pb-24" v-if="faves && faves.length > 0">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="max-w-4xl shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200 text-left">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Manage</span>
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Artists
                    </th> 
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr @click="$router.push('favourites/' + list.id)" class="relative cursor-pointer" v-for="(list, index) in faves" :key="index" :row="list">
                      <td class="p-6"> 

                        <!-- <router-link :to="list.id" class="">  -->
                        
                        <span class="text-gp_pink-default-500">Manage list and artists</span>
											<!-- </router-link > -->
                      </td>
                      <td class=" text-left mx-2 p-6 justify-center h-full items-center">
                          <strong>{{list.name}}</strong>
                      </td> 
                      <td class="p-6">
                          <span>{{pluralize(list.artists.length, 'artist')}}</span> 
                      </td> 
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
          <p class="mt-4">No favourites have been created, <router-link class="text-gp_pink-default-500" to="/artist-directory">use the artist directory to add your first artist</router-link>.</p>
      </div>
</div>
</template>

<script>
 
import { apiComputed, authComputed, apiMethods } from '@/state/helpers'
import pluralize from '../../utils/pluralize.js'
import InlineNotification from '../../components/notifications/inline.vue';

export default
{
    name: "FavouritesList",
    components:
    {  
        InlineNotification
    },
    data(){
      return {
        faves : null
      }
    },
    mounted() {
      this.getFavesLists().then((resp) =>{
        this.faves = resp;
      });
    },
    computed:{
        ...apiComputed,
        ...authComputed
    },
    methods:{
        ...apiMethods,
        pluralize
    }
    
}

</script>