<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
<div  transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->



    <div :class="artist_only || venue_only ? 'sm:max-w-md': 'sm:max-w-2xl'" class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6">
      <div>
        <div class="mx-auto flex gap-4 flex-row justify-between items-center justify-center ">
          
          <h3 class="mt-8 font-bold text-2xl  text-gray-900" id="modal-title">
            Welcome to GigPig
          </h3>
          <img src="@/assets/images/Logo_ind.svg" alt="Gig Pig." class="w-32" />
        </div>
        <div class="text-left sm:mt-5" v-if="!venue_only && !artist_only">
          <div class="mt-8 text-sm">
            <p class="mb-4" >
                Are you an artist? 
            </p>
            <p class="mb-4">
              Pick an option below to login or register.
            </p>
           </div>
        </div>
        
        <div class="text-left sm:mt-5" v-if="venue_only && !artist_only">
          <div class="mt-8 text-sm">
            <p class="mb-4" >
                In charge of booking gigs at a venue? 
            </p>  
           </div>
        </div>
      </div>
      
<ul role="list" class="grid gap-6 grid-flow-row lg:grid-flow-col mt-2">
     <li v-if="!artist_only" :class="allow_venues ? '' : 'deter_nav'"  class=" col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
    <div class="flex-1 flex flex-col p-8  ">
      <img class="w-32 h-32 flex-shrink-0 mx-auto rounded-full object-cover shadow-xl" src="@/assets/images/book.png" alt="Event manager sat at coffee table">
      <h3 class="mt-6 text-gray-900 text-sm font-bold uppercase">Venue Management</h3>
      

<ul role="list" class="mt-4 space-y-2 ">
                
                  <li class="flex space-x-3 justify-center ">
                    <svg class="flex-shrink-0 h-5 w-5 text-green-500" x-description="Heroicon name: solid/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
</svg>
                    <span class="text-sm text-gray-500">Browse artists</span>
                  </li>
                   <li class="flex space-x-3  justify-center  ">
                    <svg class="flex-shrink-0 h-5 w-5 text-green-500" x-description="Heroicon name: solid/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
</svg>
                    <span class="text-sm text-gray-500">Book gigs</span>
                  </li>
                   <li class="flex space-x-3  justify-center ">
                    <svg class="flex-shrink-0 h-5 w-5 text-green-500" x-description="Heroicon name: solid/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
</svg>
                    <span class="text-sm text-gray-500  justify-center  ">Download invoices</span>
                  </li>
</ul>

 

      <dl class="mt-4 flex-grow flex flex-col justify-between"> 
        <dt class="sr-only">Login</dt>
        <dd class=" "> 
            <a rel="nofollow" @click="loginEvents($auth);" class="mt-2 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900">Log in</a>
        </dd>
      </dl> 
    </div>
  </li>
  <li v-if="!venue_only" class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
    <div class="flex-1 flex flex-col p-8">
      <img class="w-32 h-32 flex-shrink-0 mx-auto rounded-full shadow-xl" src="@/assets/images/getbooked.png" alt="Get booked">
      <h3 class="mt-6 text-gray-900 text-sm uppercase font-bold">Artist area</h3>

<ul role="list" class="mt-4 space-y-2 text-left">
                
                  <li class="flex space-x-3" :class="alignment">
                    <svg class="flex-shrink-0 h-5 w-5 text-green-500" x-description="Heroicon name: solid/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
</svg>
                    <span class="text-sm text-gray-500">Get gig offers</span>
                  </li>
                
                  <li class="flex space-x-3" :class="alignment">
                    <svg class="flex-shrink-0 h-5 w-5 text-green-500" x-description="Heroicon name: solid/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
</svg>
                    <span class="text-sm text-gray-500">Automated invoices</span>
                  </li> 
                  
                  <li class="flex space-x-3" :class="alignment">
                    <svg class="flex-shrink-0 h-5 w-5 text-green-500" x-description="Heroicon name: solid/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
</svg>
                    <span class="text-sm text-gray-500">Calendar management</span>
                  </li> 
              </ul>




      <dl class="mt-1 flex-grow flex flex-col justify-between"> 
        <dt class="sr-only">Sign up</dt>
        <dd > 
            <a href="#"  rel="nofollow" @click="signupArtists($auth);" class="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900">New artist? Sign up</a>
        </dd>
      </dl>
      <dl>
        <p class="mt-2 uppercase font-semibold text-sm">Or</p>
      </dl>

      <dl class=" flex-grow flex flex-col justify-between"> 
        <dt class="sr-only">Login</dt>
        <dd class=" "> 
            <a  rel="nofollow"  @click="loginArtists($auth);" class="mt-2 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900">Log in</a>
        </dd>
      </dl>
       
    </div>
  </li>
 
</ul>




    </div>
  </div>
</div>

</template>

<style scoped>
.fadeIn-transition {
  transition: all .3s ease;
  opacity:0;
}
.expand-enter, .expand-leave {
  opacity: 1;
}
</style>

<script>
export default {
    name: "ModalDefault",
    props:{
      venue_only: Boolean,
      artist_only:Boolean
    },
  computed:
  {
    alignment: function(){
      return this.venue_only || this.artist_only ? 'justify-center' : 'justify-center"';
    },
    hide_venues: function(){
      return this.$route.params.hide_venues;
    },
    allow_venues: () => true
  },
    methods: {
      signupArtists($auth){
        
        this.$global.switchRoute('artist'); 
        this.$global.signup($auth);
      },
      loginArtists($auth){
        
        this.$global.switchRoute('artist'); 
        this.$global.login($auth);
      },
      signupEvents($auth){

        this.$global.switchRoute('event');
        this.$global.signup($auth);
      },
      loginEvents($auth){

        this.$global.switchRoute('event');
        this.$global.login($auth);
      }
    }
}
</script>
