<template>   
    <div v-if="viewModal" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
               
            </div>
    
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    
            <transition appear enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">        
                <div class="inline-block w-full  align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                    <div class="grid lg:grid-flow-col gap-4 lg:justify-between items-center">
                        <h2>{{
                            item.name}} at {{item.venue.name}}</h2>
                            <div class="text-indigo-500">
                                {{ DateTime.fromISO(item.start).toUTC().toFormat('cccc DDD HH:mm')}}
                            </div>
                            <gig-status :gig="getStatus" class="self-center" />
                    </div>
                     <div class="mt-5 sm:mt-4 sm:flex justify-between">

                        <button v-if="!isArtistView && !item.published" @click="deleteItem()" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                        Delete draft gig
                        </button>
                        <div class="sm:flex justify-between" :class="item.published? 'w-full' : ''">
                            <button @click="viewModal = false;" type="button" class="my-3 lg:my-0 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                            Close
                            </button>
														<template v-if="hasManageGigsPermission">
															<router-link type="button" :to="isArtistView ? ('/artist/bookings/' + bidId) : ('gigs/' + item.id)" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
															Go to gig
															</router-link>
														</template>
                            
                        </div>
                     </div>
                </div>
            </transition>
        </div>
    </div>

</template>

<script> 
import GigStatus from '../iconsets/GigStatus.vue'
// turns out these helpers arn't getting used? TODO - double check and remove if not
import { apiComputed, apiMethods } from '@/state/helpers';
import { getStatusFromType as getGigStatus } from '@/state/models/gigStatus'
import { getStatusFromType as getBidStatus } from '@/state/models/bidStatus'
import {DateTime} from 'luxon';
export default {
  components: { GigStatus },
    name: "CalendarPreview",
    data(){
        return {
            item: this.calendarItem,
            viewModal: this.showModal,
            DateTime
        }
    },
    props:{
        calendarItem: Object,
        showModal: Boolean,
        isArtistView: Boolean,
        bidId: Number,
				showAsBid:{
					type:Boolean,
					default:false
				}
    },
		computed:{
			...apiComputed,
			hasManageGigsPermission(){
				return this.$store.getters['user/hasManageGigsPermission']
			},
			getStatus(){
				console.log(this.showAsBid)
				console.log(getGigStatus(this.item.status))
				return { status: this.showAsBid  ? getBidStatus(this.item.status) : getGigStatus(this.item.status) }
			}
		},
    methods:{
        ...apiMethods,
        deleteItem(){ 
            let $self = this;
            this.deleteGig(this.item).finally(() => {
                $self.$emit("deletion", true);
                $self.$notify("Draft deleted")
            });
        }
    }
}
</script>