<template>

<div class="col-span-full grid grid-flow-row gap-4 mt-4 input-group">
      <t-rich-select class="col-span-4  t-rich"
        :multiple="single_tier" ref="tiers"
        :close-on-select="!single_tier" valueAttribute="id" textAttribute="name"
            placeholder="Select type" v-model="search_tiers" :options="tiers" 
    ></t-rich-select> 
  
    <t-select  class="col-span-4"
      ref="styles"  valueAttribute="id" textAttribute="name"  :options="styles" placeholder="Upbeat & Relaxed" v-model="search_styles" 
    ></t-select>  
		<!--  -->

    <t-rich-select  class="col-span-4 t-rich"  ref="genres"  valueAttribute="id" textAttribute="name"  :options="genres"
    multiple  v-model="search_genres" 
    :close-on-select="false"
    placeholder="Select genres" 
    ></t-rich-select>   
</div>
</template>


<script>

import {
  TRichSelect, TSelect
} from 'vue-tailwind/dist/components'

export default {
    name: "SearchByTag",
    components: {
        TRichSelect, TSelect
    },
    data(){
        return { 
            search_tiers: null,
            search_styles:null,
            search_genres :null
        }
    },
    props:{
        tiers: Array,
        styles: Array,
        genres: Array,
        single_tier: Boolean,
    },
		watch:{},
    methods:{}
}

</script>

