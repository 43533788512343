<template>
	<GigPageWrapper
			:gig-id="String($attrs.id)" 
			current-route="notes"
			title="Notes"
		>
        <!-- <div> -->
             


    

    <CreateNote v-if="isShowNote" :gig="item" :note="note" v-on:deleteNote="deleteItem($event)" v-on:saveNote="save($event)" v-on:closeModal="isShowNote = $event" />

<!-- 
        <div class="details-view p-4">
        <h3>Edit gig - Notes: {{ titleOutput }}</h3>
 
						<GigTabs 
							:gig-id="String($attrs.id)" 
							current-route="notes"
						/> -->


    <InlineNotification Heading="Keep track behind the scenes" class="m-0">
        <template slot="body">
          <p>This section is useful to make notes about how good the artist was, how the vibe worked etc. These notes are only visible to the venue.</p>
        </template>
        <template slot="cta">
            <a @click="showNote(null)" class="bg-gp_pink-default-500 gp_cta">Add note</a>
        </template> 
    </InlineNotification> 


            <div v-if="item" class=" lg:max-w-5xl"> 
                 
                <div class=" flex flex-col mt-4 " v-if="item.notes && item.notes.length > 0">
                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div
                        class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
                    >
                        <div
                        class="max-w-4xl shadow  border-b border-gray-200 sm:rounded-lg"
                        >
                        <table class="min-w-full divide-y divide-gray-200 text-left">
                            <thead class="bg-gray-50">
                            <tr>
                                <th
                                scope="col" v-show="false"
                                class="px-6 py-3 marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                Assigned to
                                </th> 
                                <th
                                scope="col"
                                class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                Raised
                                </th>
                                <th
                                scope="col"
                                class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                Body
                                </th>
                                <th
                                scope="col"
                                class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                                ></th>
                                <th scope="col" class="relative  text-xs px-6 py-3">
                                <span class="sr-only">Action</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200  text-sm">
                            <tr v-for="(note, index) in item.notes" :key="index" :row="note">
                                <td class="flex flex-col text-left mx-2 p-6" v-show="false">
                                    <strong>{{note.assignee ? note.assignee.name : 'No assignee'}}</strong> 
                                </td> 
                                <td class="p-6">
                                    <span>
                                        {{format(note.created_at)}}
                                    </span>
                                </td>
                               
                                <td class="p-6 large:space-x-8 max-w-150px " >
                                    {{note.body.substring(0, 50)}}... 
                                </td>
                                <td class="p-6 large:space-x-8" >
                                    <a @click="showNote(note)" class="text-gp_pink-default-500">View/amend</a> 
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                </div>

                <div v-else >
                    There are no notes for this gig.
                </div>
                
							</div>
			<!-- 		</div> -->

            <NotificationSmall ref="notification" />

        </GigPageWrapper>



</template>

<script>  
import NotificationSmall from '../../components/notifications/small.vue'; 
// import GigTabs from '../../components/gigs/Tabs.vue';  
import GigPageWrapper from '@/components/gigs/GigPageWrapper.vue'; 
import { apiComputed, apiMethods } from '@/state/helpers'; 
import InlineNotification from '../../components/notifications/inline.vue'
import CreateNote from '../../components/modal/CreateNote.vue';
import format from  '@/utils/format-date-default.js' ; 
import formatLinebreak from  '@/utils/format-string-linebreaks.js' ; 
import formatDate from '../../utils/format-date.js';
//import CancelBooking from '../../components/modal/CancelBooking.vue'; 

export default {
    name: "GigNotes",
    components:{
		GigPageWrapper, 
    NotificationSmall,
    InlineNotification,
    CreateNote
}, 
    data ()
    {
       return { 
            item: null,
            selectedNote: null,
            showSelectedNote: false,
            isShowNote: false
       }
    }, 
    
    created() {    
        this.loadNotes();
    },
  computed: { 
    ...apiComputed, 
		// titleOutput(){
		// 	if(!this.item){
		// 		return "";
		// 	}
		// 	if(!this.item.start){
		// 		return "";
		// 	}
		// 	return this.item.name + ', '  + formatDate(this.item.start, 'dd/MM/yy HH:mm').replaceAll(',', '');
		// },
    }, 
    methods:{
        loadNotes(){
            this.getGigWithNotes(this.$attrs.id).then((resp) => {
                this.item = resp; 
								this.$store.dispatch('gig/storeGig', this.item);
            });
        },
        formatDate,
        ...apiMethods,
        format,
        formatLinebreak,
        showNote($note){
            this.isShowNote = true;
            this.note = $note;
        },  
        deleteItem($note){
            let payload = $note;
            $note.gig_id = this.item.id;
                this.deleteNote(payload).then(() =>{
                    
                    this.isShowNote = false;
                    this.$refs.notification.createNotification("Note deleted");
                }).finally(() => {
                    this.loadNotes();
                }).catch(ex => {
                    alert(ex);
                });
        },
        save($note){
                
            let payload = $note;

            $note.gig_id = this.item.id;

            if (!$note.id)
            {
                console.log(this);
                this.createNote(payload).then(() =>{ 
                    this.isShowNote = false;
                    this.$refs.notification.createNotification("Note created");
                }).finally(() => {
                    this.loadNotes();
                }).catch(ex => {
                    alert(ex);
                });
            }
            else
            {            
                this.updateNote(payload)
                .then(() => { 
                    this.isShowNote = false;
                    this.$refs.notification.createNotification("Note updated"); 
                }).finally(() => {
                    this.loadNotes();
                }).catch(ex => {
                    alert(ex);
                });
            }
        }
    }
}
</script>