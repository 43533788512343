<template>
<div class="ml-4">
    
    <InlineNotification Heading="Pending gigs">
        <template slot="body">
          <div class="grid grid-flow-row gap-4">
          <p>Below is the list of gigs that you have offered to artists but have not confirmed yet, and upcoming booked gigs.</p>

<p>Click “view offers” to see who is available, and confirm the artist for that gig.</p>

<p>If there are no available artists, let GigPig find available artists in your area.
</p></div>
        </template>
    </InlineNotification>
     
      <div class=" flex flex-col mt-4 ">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="max-w-5xl shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full w-max divide-y divide-gray-200 text-left">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                    <!-- <th
                      scope="col"
                      class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                    ></th> -->
                    <th
                      scope="col"
                      class="px-6 py-3 marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Venue
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Gig
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                    ></th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr class="relative" v-for="(gig, index) in rows" :key="'chaserow' + index" >

										<td class="p-6">
											<router-link :to="getGigRoute(gig) " class=""> 
												<span class="text-gp_pink-default-500">
													{{ getGigRouteLabel(gig) }}
												</span>	
											</router-link>
											
										</td>
										<!--
                      <td class="p-6" v-if="gig.bookings.length == 0"> 

                        <router-link :to="'gigs/' + gig.id + '/offers'" class=""> 
                        
                        <span class="text-gp_pink-default-500">View offers</span></router-link >
                      </td>
                      <td class="p-6" v-else-if="!gig.published">
                        

                        <router-link :to="'gigs/' + gig.id + '/choose-artists'" class=""> 
                        
                        <span class="text-gp_pink-default-500">Choose artists</span></router-link >
                      </td>
                      <td class="p-6" v-else>
                        

                        <router-link :to="'gigs/' + gig.id + '/bookings'" class=""> 
													
                        <span class="text-gp_pink-default-500">View bookings</span></router-link >
                      </td>

-->

                      <td class=" text-left mx-2 p-6 justify-center h-full items-center">
                          <strong>{{gig.venue.name}}</strong>
                      </td> 

                      <td class="p-6">
                          <span>{{gig.name}}</span>
                          <span class="block">{{  fullDate(gig.start) }}</span>
                      </td>
                      
                      <td class="p-6" v-if="!gig.published "> 
                        <span class="text-indigo-500">Draft gig</span>
                      </td>
                      <td class="p-6" v-else-if="gig.cancelled"> 
                        <span>Gig cancelled</span>
                      </td>
                      <td class="p-6" v-else-if="gig.bookings.length == 0"> 
                        <span>{{gig.bids_count}} offers found</span>
                        <span class="block">{{timeAgo(gig.start)}} until gig</span>
                      </td>
                      <td class="p-6" v-else-if="gig.bookings && gig.bookings[0] && gig.bookings[0].artist"> 
                        <span class="text-gp_pink-default-500" v-if="gig.bookings && gig.bookings[0].artist">{{gig.bookings[0].artist.name}} booked</span>
                        <span class="block text-indigo-500" v-if="gig.bookings[0].artist.telephone"><a :href="'tel:' + gig.bookings[0].artist.telephone">tel: {{gig.bookings[0].artist.telephone }}</a></span>
                        <span class="block">{{timeAgo(gig.start)}} until gig</span>
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    <div v-if="debug && false">
      {{ rows }}
    </div>
</div>
</template>

<script type="text/javascript">


import { apiComputed, apiMethods } from '@/state/helpers.js'
import fullDate from  '@/utils/format-date-full.js'  
import timeAgo from  '@/utils/format-date-timeago.js'  
import InlineNotification from '../../components/notifications/inline.vue'; 
import NProgress from 'nprogress'; 
     


export default {
    name: "ChaseArtists",
  components:
  {  
    InlineNotification 
  },
  data() { 
      return { 
        rows: []
      }
  },
  computed: {
    ...apiComputed,
		getGigRoute(){
			return (gig) => {
				if(!gig.published){
					return `gigs/${gig.id}/choose-artists`
				}else{
					if(gig.status === "PENDING" && gig.bids_count){
						return `gigs/${gig.id}/offers`
					}
					if(gig.status === "CONFIRMED" && gig.bookings.length){
						return `gigs/${gig.id}/bookings`
					}
					return `gigs/${gig.id}`
				}
				
			}
		},
		getGigRouteLabel(){
			return (gig) => {
				
				if(!gig.published){
					return 'Choose artists'
				}else{
					if(gig.status === "PENDING" && gig.bids_count){
						return 'View offers'
					}
					if(gig.status === "CONFIRMED" && gig.bookings.length){
						return 'View bookings'
					}
					return `View gig`
				}
				
			}
		}
  },
  mounted(){
    this.loadData();
  },
  methods: {
    ...apiMethods,
    fullDate, 
    timeAgo, 
    remainingDays(){

    },
    loadData() {
			NProgress.start();
			this.getPendingGigs().then((resp) => { 
				this.rows = resp
					.filter((r) => !r.cancelled)
					.sort(function(a, b){
						return new Date(a.start) - new Date(b.start); 
					})
					.filter((gig) => {
						return new Date(gig.start) > new Date();
					})
					}).finally(() => {
						NProgress.done();
					})
    }
  }
};

</script>
