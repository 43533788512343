import client from "../../utils/client"

export const state = {
  venueInvites: [],
}

export const mutations = {
  SET_VENUE_INVITES(state, { invites, venueId }) {
		const index = state.venueInvites.findIndex(r => r.venueId === venueId)
    index > -1 
			? state.venueInvites[index].invites = invites
			: state.venueInvites.push({
				venueId: venueId,
				invites
			})
  },
	ADD_VENUE_INVITES(state, { invites, venueId }) {
		const index = state.venueInvites.findIndex(r => r.venueId === venueId)
    index > -1 
			? state.venueInvites[index].invites = [
				...state.venueInvites[index].invites,
				...invites
			]
			: state.venueInvites.push({
				venueId: venueId,
				invites:[ invites ]
			})
  },
	ADD_VENUE_INVITE(state, { invite, venueId }) {
		const index = state.venueInvites.findIndex(r => r.venueId === venueId)
    index > -1 
			? state.venueInvites[index].invites.push(invite) 
			: state.venueInvites.push({
				venueId: venueId,
				invites:[ invite ]
			})
  },
	UPDATE_VENUE_INVITE(state, {invite, venueId }) {
		const index = state.venueInvites.findIndex(r => r.venueId === venueId)
    if(index > -1){
			const inviteIndex = state.venueInvites[index].invites.findIndex(i => i.id === invite.id)
			state.venueInvites[index].invites[inviteIndex] = invite;
		}
  },
	DELETE_VENUE_INVITE(state, { invite, venueId }) {
		const index = state.venueInvites.findIndex(r => r.venueId === venueId)
    if(index > -1){
			state.venueInvites[index].invites = state.venueInvites[index].invites.filter(i => i.id !== invite.id)
		}
  }
}

export const getters = {
	all: state => {
    return state.venueInvites.invites;
  },
	venueInvites: state => venueId => {
		const i = state.venueInvites.findIndex(r => r.venueId === venueId)
    return i > -1 ? state.venueInvites[i].invites : null;
  },

}

export const actions = {
  getVenueInvites({ getters, commit }, venueId) {
		return new Promise((resolve) => {
			// if(getters['venueInvites'](venueId)){
			// 	resolve(getters['venueInvites'](venueId))
			// }else{
				// return dispatch('requestVenueInvites', venueId);
				client.get(`/venues/${venueId}/invites`)
					.then((response) => {
						commit('SET_VENUE_INVITES', {
							invites: response.data.data, 
							venueId
						});
						resolve(getters['venueInvites'](venueId))
					})
					.catch(err => {
						console.log(err)
					})
			// }
		})
  },
	addVenueInvites({ commit }, { venueId, invites }) {
		return new Promise((resolve) => {
			commit('ADD_VENUE_INVITES', {
				invites: invites, 
				venueId
			});
			resolve(invites)
	
		})
  },
	deleteVenueInvite({ commit }, { venueId, invite }) {
		return new Promise((resolve) => {
			commit('DELETE_VENUE_INVITE', {
				invite: invite,
				venueId
			});
			resolve(invite)
		})
  },
	requestVenueInvites({ commit }, venueId) {
    return client.get(`/venues/${venueId}/invites`)
      .then((response) => {
        commit('SET_VENUE_INVITES', {
					invites: response.data.data, 
					venueId
				});
      })
			.catch(err => {
				console.log(err)
			})
  }
} 