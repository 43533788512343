<template>
	<PageDetails>
		<div class="flex-1 flex flex-col min-h-full">

			<!-- Navigation, DisplayDate/Refresh and Filter slot-->
			<div class="flex flex-col lg:flex-row justify-between lg:items-center mb-4 gap-4 lg:gap-8">

				<!-- Responsive div to display the Navigation and DisplayDate/Refresh -->
				<div class="flex flex-col-reverse lg:flex-row gap-2 lg:gap-4">
					<!-- Calendar Navigation buttons -->
					<div class="flex items-center gap-4">
						<select 
							@change="updateCalendar" 
							v-model="view" 
							class="block py-2 pl-3 pr-10 text-gray-600 text-base font-medium placeholder-gray-400 transition duration-100 ease-in-out hover:text-gray-700 border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:border-gray-300 rounded">
							<option 
								v-for="viewOption in viewOptions" 
								:key="viewOption.value" 
								:selected="viewOption.value === view"
								:value="viewOption.value"
							>
								{{ viewOption.text }}
							</option>
						</select>

						<GPButton @click.native="showToday" color="outline-gray">
							Today
						</GPButton>

						<GPButton @click.native="prevBlock" color="outline-gray">
							<ChevronLeftIcon />
						</GPButton>

						<GPButton @click.native="nextBlock" color="outline-gray">
							<ChevronRightIcon />
						</GPButton>
					</div>

					<!-- DisplayDate/Refresh, sits on top in mobile view -->
					<div class="flex items-center gap-4">
						<div class="flex gap-4" >
							<div class="text-center font-bold col-span-4 lg:col-span-1">
								{{ getCalendarDate }}
							</div>
							<button @click="onRefresh" title="Refresh calendar" >
								<RefreshIcon/>
							</button>
						</div>
					</div>
			
				</div>

				<!-- Filters slot - takes 100% of width when mobile -->
				<div class="flex-1 flex w-full justify-end items-center gap-4">
					<slot name="filters"></slot>
				</div>
				
			</div>

			<!-- Status keys, displays the availableStatusKeys prop and wraps -->
			<div class="flex-1 flex w-full justify-between">
				<div class="flex items-center flex-wrap mb-4 gap-2">
					<gig-status 
						v-for="(statusKey, index) in availableStatusKeys" 
						:key="getStatusKey(statusKey, index)"
						:gig="{ status: statusKey }" 
					/>
				</div>
				<div class="flex items-center justify-end">
					<slot name="additionalFilters"></slot>
				</div>
			</div>

		<!-- Calendar -->
			<div ref="calendarContainer" class="flex-1 flex flex-col w-full">
				<Calendar 
					ref="tuiCalendar" 
					:style="getCalendarStyle"
					:useCreationPopup="false"
					:useDetailPopup="false"
					:usageStatistics="false"
					:schedules="schedules"        
					:view="view"
					:taskView="false"
					:scheduleView="scheduleView"
					:calendars="getCalendarList"
					@beforeCreateSchedule="beforeCreateSchedule"
					@clickSchedule="clickSchedule"
				/>
			</div>
		</div>
	</PageDetails>
</template>

<script>
import PageDetails from '../ui/wrappers/PageDetails.vue'
import GPButton from '../ui/buttons/Button.vue'
import GigStatus from '../iconsets/GigStatus.vue';
import { 
	ChevronLeftIcon, 
	ChevronRightIcon, 
	RefreshIcon 
} from "@vue-hero-icons/outline"
// import { TSelect } from 'vue-tailwind/dist/components';
import { Calendar } from '@toast-ui/vue-calendar';
import 'tui-calendar/dist/tui-calendar.css'; 
import formatDate from '../../utils/format-date';

const CURRENT_DATE = "CALENDAR_CURRENT_DATE";

export default {
	components:{
		PageDetails,
		GPButton,
		GigStatus,
		ChevronLeftIcon,
		ChevronRightIcon,
		RefreshIcon,
		// TSelect,
		Calendar
	},
	props:{
		calendarType:{
			type:String,
			required: true
		},
		viewOptions:{
			type:Array,
			required: true
		},
		availableStatusKeys:{
			type:Array,
			required: true
		},
		schedules:{
			type:Array,
			required: true
		},
		scheduleView:{
			type:Array,
			required: false,
			default:() => ['time']
		},
	},
	emits:["update", "selected", "refresh", "create"],
	data(){
		return {
			view: 'month',
			calendar: null,
			currentDate: null,
			startDateRange: null,
			endDateRange: null,
			containerHeight: 700,
			currentMonth: null
		}
	},
	computed:{
		getStatusKey(){
			return (status, index) => `${index}-${status.type}`
		},
		getCalendarStyle(){
			return `height: ${this.containerHeight}px`
		},
		getCalendarList(){
			return [{
				id: 0,
				name: this.calendarType,
				color: '#ffffff',
				bgColor: '#9e5fff',
				dragBgColor: '#ff4040',
				borderColor: '#fff'
			}]
		},
		getCalendarDate(){
			if(!this.currentDate || !this.startDateRange){
				return ""
			}
			//
			if(this.view !== 'day'){
				return this.formatDate(this.currentDate, 'MMMM yyyy')
			}else{
				return this.formatDate(this.startDateRange, 'cccc dd MMM yyyy')
			}
		}
	},
	methods:{
		formatDate,
		showToday(){
			this.$refs.tuiCalendar.invoke('today');
			this.updateCalendar();
		},
		nextBlock(){
			this.$refs.tuiCalendar.invoke('next');
			this.updateCalendar();
		},
		prevBlock(){
			this.$refs.tuiCalendar.invoke('prev');
			this.updateCalendar();
		},
		updateCalendar(){
			this.updateDates();
			this.$emit('update', {
				view:this.view,
				currentDate:this.currentDate,
				startDateRange:this.startDateRange,
				endDateRange:this.endDateRange,
			});
		},
		updateDates(){
			this.startDateRange = this.$refs.tuiCalendar.invoke('getDateRangeStart')._date;
			this.endDateRange = this.$refs.tuiCalendar.invoke('getDateRangeEnd')._date;

			let $middleDate = new Date(this.endDateRange - (this.endDateRange - this.startDateRange) / 2);
 
			let $month = this.formatDate($middleDate, 'MMMM');

			localStorage.setItem(CURRENT_DATE, this.$refs.tuiCalendar.invoke('getDate')._date);
			this.currentDate = $middleDate;
			this.currentMonth = $month;
		},
		clickSchedule(event){
			const schedule = event.schedule;
			this.$emit('selected', schedule);
		}, 
		beforeCreateSchedule(event){
			this.$emit('create', event);
		},
		getCalendarContainerHeight() {
			const currHeight = this.$refs.calendarContainer.clientHeight;
			if(!this.containerHeight || this.containerHeight < currHeight){
				this.containerHeight = currHeight;
				this.$refs.tuiCalendar.invoke('render');
			}
		},
		onRefresh(){
			this.$emit('refresh');
		}
	},
	mounted(){
		// is mobile
		let isMobile = window.matchMedia("(max-width: 700px)");
		if (isMobile.matches){ 
			this.view = "day";
		}
		// init calendar container height
		this.getCalendarContainerHeight();
		window.addEventListener("resize", this.getCalendarContainerHeight);
		// init dates
		this.updateDates();
		if(this.calendarType.toLowerCase() === 'gigs'){
			// trigger an initial 'update event'
			this.updateCalendar()
		}
		let storedDate = localStorage.getItem(CURRENT_DATE);
		if (storedDate && this.calendarType.toLowerCase() === 'gigs'){
			this.$refs.tuiCalendar.invoke('setDate', new Date(storedDate));
			this.currentDate = new Date(storedDate); 
		}
	},
	destroyed() {
		window.removeEventListener("resize", this.getCalendarContainerHeight);
	},
}
</script>


<style>
	.tui-full-calendar-weekday-schedule[style*="16, 185, 129"] .tui-full-calendar-weekday-schedule-title,
	.tui-full-calendar-weekday-schedule[style*="180, 83, 9"] .tui-full-calendar-weekday-schedule-title{
		color: white !important;
	}
</style>
