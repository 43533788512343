<template>
<div> 
    
    <div class="flex flex-col gap-3 relative" v-if="artist && !loading">
      <div class="header-banner">
        <div class="lg:max-w-3xl px-4 md:pl-8 md:pr-8">
          <div class="py-6 md:flex md:justify-between ">
            <div class="flex-1 min-w-0">
              <!-- Profile -->
              <div>
                <div>
                  <div class="flex flex-col  gap-2">
                    <h1 v-if="artist"
                      class="text-2xl col-span-full  font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate"
                    >
                      {{ artist.name }}
                    </h1>
                    <h2  class="text-lg border-none col-span-2" v-if="artist && artist.tier">{{artist.tier.name}}</h2>
                    
                    <div class="grid grid-cols-2 sm:grid-cols-4 justify-center items-center justify-items-stretch gap-4">
                    
                <span
                  class="bg-gp_pink-default-500 text-white px-4 text-center py-0.5 rounded-2xl justify-items-start items-center"
                  v-for="tag in artist.artist_tags" :key="tag.id"
                >
                  {{ tag.name }}
                </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex-1 gap-8 lg:gap-0 relative z-0 flex flex-col lg:flex-row overflow-hidden max-w-7xl mb-36"
      >
        <main
          class="restrict px-4 md:px-12 flex flex-col flex-1 relative z-0 overflow-y-auto focus:outline-none gap-8"
        >
          <div class="relative"> 
            <agile  :key="galleryKey" v-if="artist.gallery && artist.gallery.length > 0">
              <div v-for="(slide, index) in artist.gallery" :key="index">
                <img :src="slide.url" :alt="artist.name + ' image'" class="w-full" />
              </div>
            </agile>
          </div>
          <div class="flex flex-row text-left gap-8 w-full"> 
            <img 
              class="shadow self-start w-40 h-40 object-center object-contain"
              :src="artist.image ? artist.image.url : require('@/assets/images/placeholder.svg')" 
              :alt="artist.name + ' logo'" 
            />
            <div class="flex flex-col gap-1">
              <h1
                class="text-gp_pink-default-500 text-lg"
                style="font-weight:600"
              >
                {{ artist.name }}
              </h1>
              <h2 v-if="artist.address" class="border-0 divide-x-0" style="border-width:0px;">
                Based in {{artist.address.town}}
              </h2>

              <div class="text-gp_pink-default-500" v-if="!artist || artist.description == null || artist.description.length == 0">
                <p>This artist hasn't completed their profile</p>
              </div>         
            </div> 
          </div>
          <!-- <div v-html="venue.about" class="venue-description" /> -->
          <div class="venue-description" v-if="artist.description"> 
            <p v-for="(line, index) in artist.description.split('\n')" :key="index" v-html="line"></p>
          </div>
          <div v-if="artist.videos" class="space-y-4">
            <div v-for="(video, index) in artist.videos.filter(f => f != null)" :key="index"> 
                  <video-embed :ref="'video_' + index" :src="video"></video-embed>         
            </div>
          </div>
          <div v-if="artist.audio && artist.audio.filter(f => f != null).length > 0" >
        
            <div v-for="(audio, index) in artist.audio.filter(f => f != null)" :key="index"> 
              <iframe title="audio" :src="changeAudio(audio)" class="w-full" />     
            </div>
          </div>
          <div v-if="artist.bookings" class="hidden  flex-col text-left gap-4">
            <h2 class="font-bold text-lg">
              <span v-if="artist.bookings.length">{{ artist.name }} currently playing at</span>
              <span v-else></span>
            </h2>
            <div
              class="flex flex-row gap-12"
              v-for="booking in artist.bookings.slice(0, 2)"
              :key="booking.id"
            >
              <img v-if="booking.venue && booking.venue.icon" :src="booking.venue.icon" :alt="booking.venue.name + ' logo'" style="width:auto; height:72px;" />
              <div class="flex justify-center flex-col items-start">
                <h3 class="font-bold" v-if="booking.venue">
                  {{ booking.venue.name }}
                </h3>
                <p> 
                </p>
              </div>
            </div>
          </div>
        </main>
        <aside
          class="relative px-12 flex flex-col gap-8 flex-shrink-0 w-full md:w-96 border-l border-gray-200"
        >
          <div class="venue-options" v-if="is_venue" >
              <a @click="openFaves()" class="text-gp_pink-default-500">
                Add artist to favourites
              </a>
              <div v-if="is_in_faves.length > 0">
                <h2>This artist appears in the following lists</h2>
                <router-link :to="'/favourites/' + list.id" class="mt-4 text-gp_pink-default-500" v-for="list in this.is_in_faves" :key="list.id">
                {{list.name}}
                </router-link>
              </div>
            <router-link v-if="current_uuid == artist.uuid && current_uuid != null" :to="'/artists/' + artist.id" class="text-gp_pink-default-500 ">
              <svg
                width="29"
                height="27"
                viewBox="0 0 29 27"
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.0955 5.62454L22.263 1L2.933 19.4982L1 25.9725L7.76551 24.1227L27.0955 5.62454Z"
                  fill="currentColor"
                />
                <path
                  d="M2.933 19.4982L22.263 1L27.0955 5.62454L7.76551 24.1227M2.933 19.4982L1 25.9725L7.76551 24.1227M2.933 19.4982L7.76551 24.1227M18.397 4.69963L23.2295 9.32418"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Edit Artist Profile
            </router-link>
          </div>

          <div v-if="artist && artist.song_list && artist.song_list.length > 1 && artist.song_list.filter(f => f != null && f.length > 1)" >
              <p><strong>Top songs {{artist.name}} performs</strong></p>
              <ol class="list-decimal list-inside">
                <li v-for="(song, index) in artist.song_list.filter(f => f != null && f.length > 1)" :key="index">{{ song }}</li>
              </ol>
          </div>
        </aside>
      </div> 
    </div>
    <div class="flex flex-col gap-3 relative lg:max-w-3xl px-4 md:pl-8 md:pr-8 py-4" v-else>
      <h1 class="text-2xl col-span-full  font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">Loading artist profile...</h1> 
    </div>
    
                <FaveManagement  ref="faves" :modal_open="open_faves"></FaveManagement>
</div>
</template>

<style type="text/css">

.restrict .embed-responsive iframe
{
    width:100%;
    min-height:400px;
}

</style>

<script type="text/javascript">
import FaveManagement from '../../components/modal/FaveManagement.vue'
import { apiComputed, authComputed, apiMethods } from '@/state/helpers'
import { VueAgile } from "vue-agile";
import appConfig from "@/app.config";
import changeAudio from  '@/utils/change-audio.js';  
import NProgress from 'nprogress';


export default
{
    name: "ArtistProfile",
    data()
    {
        return{          
            loading: true,
            appConfig,   
            artist: {},
            galleryKey: 0,
            GalleryOptions: {
                dots: true,
                navButtons: false,
                infinite: true,
                autoplay: true,
            },
            open_faves: false,
            is_in_faves: false,
            current_uuid: '',
            is_venue: false
        }
    },
    components:{
        agile: VueAgile, FaveManagement
    },
    computed:{
        ...apiComputed,
        ...authComputed,

    },
    mounted() {  
			console.log("ARTISTS PROFILE")
      NProgress.start();
 
        if (this.artist == null && this.$route.params.artist)
          {
            this.artist = this.$route.params.artist;
          }
          

      this.getArtist(this.$attrs.id).then((resp) => {
        this.artist = resp;
      }).finally(() =>{

      this.current_uuid = this.$store.state.api.uuid;     

          if (this.faveslists){
						this.faveslists.map((fave) => {
							if(fave.artists.find(f => f.id === this.artist.id)){
								this.is_in_faves = true
							}
						});
          }
					console.log(this.is_in_faves);
					// TODO
          this.is_venue = this.$route.params.is_venue;
          console.log(this.is_venue, this.$route.params.is_venue)
          NProgress.done();
          this.loading = false;
      })
       

    },
    methods:{
        changeAudio,
        ...apiMethods,
        openFaves(){
          console.log(this.artist);
          this.$refs.faves.Artist = this.artist;
          this.$refs.faves.toggleModal();
        }
    }
}
</script>