<template>
    
    
    <div class="header-banner ml-3">
      <div class="lg:max-w-xl">
        <div class="py-6 md:flex md:items-center md:justify-between ">
          <div class="flex-1 min-w-0">
            <!-- Profile -->
            <div class="flex items-center">
              <div>
                <div class="flex items-center">
                  <h1
                    class="text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate"
                  >
                    {{Heading}}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div class="flex space-x-3 md:mt-0 md:ml-4">
            <router-link v-if="AddLink"
              :to="AddLink"
              class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white bg-gp_pink-default-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
            >
              {{AddText}}
            </router-link>
          </div>
        </div>
      </div>
    </div>


</template>

<script>
export default {
    name: "listing-header",
    props:
    {
        Heading: String,
        AddLink: String,
        AddText: String
    }
}
</script>