<template>
        <!-- <div> -->
	<GigPageWrapper
		:gig-id="String($attrs.id)" 
		current-route="offers"
		title="Offers"
	>
    <BidAccept :bid="bid" v-if="bid && isShowAccept" v-on:acceptBid="accept(bid)" v-on:closeModal="isShowAccept = $event;" />
    <BidDecline :bid="bid" v-if="bid && isShowDecline"  v-on:declineBid="decline(bid)" v-on:closeModal="isShowDecline = $event;" />


        <!-- <div class="details-view p-4" v-if="item">
        <h3 v-if="item">Edit gig - Offers:  {{ item.name + ', '  + formatDate(item.start, 'dd/MM/yy HH:mm').replaceAll(',', '') }}</h3>

						<GigTabs 
							:gig-id="String($attrs.id)" 
							current-route="offers"
						/> -->

            <div class="mt-4 lg:max-w-5xl pb-0"> 
                <InlineNotification Heading="Book your artists" class="p-0 mt-0">
                    <template slot="body">
										<p>Below is a list of artists that are available for this gig. Simply click “thumbs up” to book the artist you want. This will send the confirmation to your chosen artist and the gig is booked.</p>
                    <!-- <p>Below is a list of artists that are available for this gig. Simply click “thumbs up” to book the artist you want. This will send the confirmation to your chosen artist. They will then re-confirm to make sure they are still available. Once this happens, the gig is booked.</p> -->
                    </template> 
                </InlineNotification> 
 
                <a class="gp_cta disabled hidden">Add artists using my shortlist</a>

								<!-- v-if="item && item.bids && item.bids.length > 0 && (!item.bookings || item.bookings.length == 0)" -->
                <div class=" flex flex-col mt-4 " v-if="!isGigBooked && bids && bids.length > 0">
                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div
                        class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
                    >
                        <div
                        class="max-w-4xl shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
                        >
                        <table class="min-w-full divide-y divide-gray-200 text-left">
                            <thead class="bg-gray-50">
                            <tr>
                                <th
                                scope="col"
                                class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Make choice
                                </th> 
                                <th
                                scope="col"
                                class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                
                                </th> 
                                <th
                                scope="col"
                                class="px-2 py-3 text-left marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                Artist
                                </th> 
                                <th
                                scope="col"
                                class="px-0 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >Fee </th> 
                            </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200  text-sm">
                            <tr v-for="(bid, index) in bids" :key="index" :row="bid">
                                <td class="p-4" >
                                    
                                    <div v-if="bid.declined">
                                        <p>Availability declined</p>
                                    </div> 
                                    <div v-else-if="bid.cancelled">
                                        <p>Offer cancelled</p>
                                    </div> 
                                    <div v-else-if="bid.gig.cancelled">
                                        <p>Gig cancelled</p>
                                    </div> 
                                    <div v-else-if="bid.awaiting_artist_bid">
                                        <p>Waiting for initial<br/>confirmation from artist</p>
                                    </div> 
                                    <div v-else-if="bid.awaiting_venue_final_acceptance" class="flex flex-col ">
                                       <div>
                                           <span v-if="bid.artist">{{bid.artist.name}}</span><span v-else>[Artist deleted]</span> has said they're available,<br/>click the thumbs up to advise you want to book this artist.
                                       </div>
                                       <div class="flex flex-row mt-4 space-x-8">                                            
                                           <a @click="showAccept(bid)" title="Accept artist" class="text-green-500 hover:text-green-800 mr-2"><ThumbUpIcon/></a> <a  class="hover:text-black text-red-500" v-show="false"  title="Remove artist" @click="showDecline(bid)"><ThumbDownIcon/></a>  
                                        </div>
                                    </div>
                                    <div v-else-if="bid.awaiting_artist_final_acceptance">
                                        You have accepted this artist,<br/> awaiting artist's final confirmation.
                                    </div>
                                    <div v-else-if="bid.accepted">
                                        <span class="text-green-500 flex items-center"><CheckIcon class="mr-2"/>Artist booked </span>
                                    </div> 
                                    <div v-else> 
                                   </div>
                                </td>
                                <td class="py-2 px-4" >
                                    <router-link class="text-gp_pink-default-500" :to="'/messages/offer/' + bid.gig.id + '/' + bid.id">Message artist</router-link>  
                                </td> 
                                <td class="py-4 flex-col text-left mx-2  ">
                                    <router-link v-if="bid.artist" class="text-gp_pink-default-500 block" :to="'/artist-directory/' + bid.artist.id + '/' + slugify(bid.artist.name).toLowerCase()">{{bid.artist.name}}</router-link> 
                                    
                                    <span v-if="bid.distance">Distance: {{bid.distance}} miles</span> 
                                    <span v-if="bid.matched_tags">{{bid.matched_tags}}</span>
                                </td> 
                                <td class="py-4 text-left">
                                    <span>£{{bid.fee}}</span>
																		<a class="text-gp_pink-default-500 pl-2"
																			@click="editBidFee(bid)"
																		>Amend</a>
                                </td>  
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                </div>

								<div v-else>
									<p class="mt-4 max-w-2xl">
										{{ offerMessage }}
									</p>
								</div>
								<!--
                <div v-else-if="item && item.published == false">
                    <p class="mt-4 max-w-2xl">
                        Use the "Offer gig to artists" button to publish your gig.  GigPig will then notify you shortly of any artists that have said they are available.
                    </p>
                </div>
                <div v-else-if="item && item.published == true && item.bids && item.bids.length == 0">
                    <p class="mt-4 max-w-2xl">
                        GigPig is out looking for artists.  Once we've made some matches, you'll see them here, and can offer them the gig if they're available.
                    </p>
                </div>
                <div v-else-if="item && item.bookings && item.bookings.length > 0">
                    <p class="mt-4 max-w-2xl">This gig is now booked.  Once the gig has passed, you will be able to sign-off this gig and receive an invoice from the artist.</p>
                </div>
								-->
                
            </div>
        <!-- </div> -->

            <NotificationSmall ref="notification" />

						<template v-if="selectedBid">
							<AmendBidFee 
								:is-open="showAmendBidFeeModal"
								:bid="selectedBid"
								@closed="cancelModal"
								@updated="onBidFeeUpdated"
							/>
						</template>
	</GigPageWrapper>

				

</template>

<script>  
import NotificationSmall from '../../components/notifications/small.vue'; 
// import GigTabs from '../../components/gigs/Tabs.vue';  
import GigPageWrapper from '@/components/gigs/GigPageWrapper.vue'; 
import { apiComputed, apiMethods } from '@/state/helpers'; 
import InlineNotification from '../../components/notifications/inline.vue'
import BidAccept from '../../components/modal/BidAccept.vue';
import BidDecline from '../../components/modal/BidDecline.vue';
import { ThumbUpIcon, ThumbDownIcon, CheckIcon } from "@vue-hero-icons/outline"   
import formatDate from '../../utils/format-date.js';
import slugify from 'slugify';

import AmendBidFee from '../../components/modal/gigs/AmendBidFee.vue'

export default {
    name: "GigBids",
    components:{
		GigPageWrapper, 
    NotificationSmall,
    InlineNotification,
    BidAccept,
    BidDecline,
    ThumbUpIcon, 
    ThumbDownIcon,
		CheckIcon,
		AmendBidFee
}, 
    data ()
    {
       return { 
            item: null,
            bids:[],
            bid: null,
            isShowAccept: false,
            isShowDecline: false,
            gigCopy: '',            
            pristine: null,
            pristineConfig: {
                classTo: 'input-group',
                errorClass: 'has-error',
                successClass: 'has-success',
                errorTextParent: 'input-error',
                errorTextTag: 'span',
                errorTextClass: 'text-help'
            },
						showAmendBidFeeModal: false,
						selectedBid:null,
       }
    },
    mounted()
    {             
      
    },
    created() {   
        this.initData();
    },
  computed: { 
    form: function() {
  
        let deepCopy = JSON.parse(JSON.stringify(this.item));            
        return deepCopy;  
    },
    ...apiComputed, 
		offerMessage(){
			if(!this.item){
				return ''
			}
			if(!this.item.published){
				return `Use the "Offer gig to artists" button to publish your gig.  GigPig will then notify you shortly of any artists that have said they are available.`
			}else if(this.item.bids && !this.item.bids.length){
				return `GigPig is out looking for artists. Once we've made some matches, you'll see them here, and can offer them the gig if they're available.`
			}else if(this.item.bookings && this.item.bookings.length < this.item.max_bookings && this.item.max_bookings > 1){
				return `This gig requires multiple bookings (${this.item.max_bookings} in total). GigPig is out looking for other artists. Once we've made some matches, you'll see them here, and can offer them the gig if they're available.`
			}else{
				return `This gig is now booked. Once the gig has passed, you will be able to sign-off this gig and receive an invoice from the artist.`
			}
		},
		isGigCancelled(){
			return this.$store.getters['gig/isCurrentGigCancelled']
		},
		isGigBooked(){
			return this.$store.getters['gig/isCurrentGigBooked']
		}
    }, 
    methods:{
        slugify,
				initData(){
						this.getGigWithOffers(this.$attrs.id).then((resp) => {
							this.item = resp;      
					}).finally(() => {
							this.loadBids();
							this.$store.dispatch('gig/storeGig', this.item); 
					})
				},
        loadBids(){

            this.bids = this.item.bids.sort(function(a, b){
                return b.awaiting_venue_final_acceptance - a.awaiting_venue_final_acceptance 
            }).sort(function(a, b){
                return  b.awaiting_artist_final_acceptance - a.awaiting_artist_final_acceptance 
            }).sort(function(a,b){
              return a.declined - b.declined
          })
        },
        formatDate,
        ...apiMethods,
        showAccept($bid){
            this.isShowAccept = true;
            this.bid = $bid;
        },
        showDecline($bid){ 
            this.isShowDecline = true;
            this.bid = $bid;
        },
        accept($bid){ 
            this.save($bid, true);
        },
        decline($bid){ 
            this.save($bid, false);
        },
        save($bid, $isAccept = false){
                
            let payload = { 
                id: $bid.id, 
                accepted: $isAccept,
                declined: !$isAccept   

            };
            
            if ($isAccept){

                this.acceptAvailability(payload)    
                    .finally(this.proceed($isAccept));
            }else{

                this.declineAvailability(payload)
                    .finally(this.proceed($isAccept));
            }

        },
        proceed($isAccept){
             
                                
                this.getGigWithOffers(this.$attrs.id).then((resp) => {
                    this.item = resp;
                    this.bids = resp.bids;
                }).finally(() => {                    
                    this.$notify("Availability " + ($isAccept ? 'accepted' : 'declined'));
                    this.isShowAccept = false;
                    this.isShowDecline = false;
                    setTimeout(() => {                       
                        this.$router.go(); 
                    }, 1000);
                });
        },
				editBidFee($bid){
					this.selectedBid = $bid;
					this.showAmendBidFeeModal = true;
				},
				cancelModal(){
					this.showAmendBidFeeModal = false;
				},
				onBidFeeUpdated($bid){
					this.selectedBid = $bid;
					this.cancelModal();
					this.initData();
				},
    }
}
</script>