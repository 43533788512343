import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import VenueLogin from '../views/Venues/Login.vue';
import Home from '../views/Home.vue';
import Dashboard from '../views/Dashboard.vue'; 
import { getInstance } from "../auth/index";
import { authGuard } from "../auth/authGuard";
import AuthCallback from '../views/callbacks/AuthCallback.vue'; 

import ArtistDirectory from '../views/ArtistDirectory/Index.vue';

import Favourites from '../views/Favourites/Index.vue';
import FavouritesDetails from '../views/Favourites/ID.vue';

import VenueRoutes from './venues';
import GigRoutes from './gig'
import InvoiceRoutes from './invoice'
import CalendarRoutes from './calendar'

// import Calendar from '../views/Calendar/Index.vue';
// import ArtistCalendar from '../views/ArtistProfile/Calendar/Index.vue';

import MessagesList from '../views/Messages/Index.vue';
import ArtistMessagesList from '../views/ArtistProfile/Messages/Index.vue';

import ArtistsProfileIndex from '../views/ArtistProfile/Index.vue';
import ArtistProfile from '../views/ArtistProfile/ID.vue';
import ArtistProfileView from '../views/ArtistProfile/Profile.vue';

import ArtistIndex from '../views/ArtistProfile/Dashboard.vue';
import ArtistBidIndex from '../views/ArtistProfile/Bids/Index.vue';
import ArtistAwaitingVenue from '../views/ArtistProfile/Bids/InitialAccept.vue';
import ArtistBidDetails from '../views/ArtistProfile/Bids/ID.vue';
import ArtistBookingIndex from '../views/ArtistProfile/Bookings/Index.vue';
import ArtistFinalAccept from '../views/ArtistProfile/Bids/FinalAcceptance.vue';

import DevIndex from '../views/Dev/Index.vue';
import store from '../state/store';
import axios from 'axios'; 
import client from '../utils/client';


Vue.use(VueRouter)

const routes = [
  {
    path: "/dev",
    name: "Dev",
    meta: { authRequired: true },
    props: true,
    /*beforeEnter: authGuard,*/
    component: DevIndex
  },
  {
    path: '/',
    name: 'Login',
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      
      let $claim = to.query.claim;
      if ($claim){

        localStorage.setItem(process.env.VUE_APP_CLAIM_KEY, $claim); 
        localStorage.setItem('initialRoute', 'artist');
        next('/dashboard');
        
        // presetup claim
      }
      else{
        next();
      }
    },
    meta: { authRequired: false },
    component: Home
  },
  {
    path: '/venues/login',
    name: 'VenuesLogin',
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
       
        next(); 
    },
    meta: { authRequired: false },
    component: VenueLogin
  },
  {
    path: '/claim',
    name: 'Login',
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
        
        to.params.hide_venues = true;
         
        next(); 
    },
    meta: { authRequired: false },
    component: Home
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { authRequired: true },    
    beforeEnter: (to, from, next) => {  
        next();  
      },
    component: Dashboard
  }, 
  {
    path: "/artist/profiles",
    name: "ArtistList",
    meta: { authRequired: true },
    beforeEnter: (to, from, next) => {
      store
        .dispatch('api/getArtists')
        .then(() => {
          next()
        })
    },
    component: ArtistsProfileIndex
  }, 
  {
    path: "/artists/add",
    name: "ArtistProfile",
    meta: { authRequired: true }, 
    component: ArtistProfile,
    beforeEnter:(to, from, next) => {
 
      localStorage.setItem('initialRoute', 'artist');

      store.dispatch('api/getArtistTags', 'api/getTiers').then(function(){
        next();
      });
    }
  },
{
    path: "/artists/:id",
    name: "ArtistEditProfile",
    meta: { authRequired: true },
    props: true,
    /*beforeEnter: authGuard,*/
    beforeEnter: (to, from, next) => {
      let artist = store.getters['api/artists'].find(a => a.id === Number(to.params.id))
       
      localStorage.setItem('initialRoute', 'artist'); 
      if (artist) {
        to.params.artist = artist;
        to.params.artistId = artist.id;
        next()
      } else {
        store.dispatch('api/getArtist', to.params.id).then((resp) => {
          console.log("resp", resp);
          to.params.artist = resp
          to.params.artistId = artist.id;
          next()
        })
      }
    },
    component: ArtistProfile
  },{
    path: "/favourites",
    name: "Favourites",
    meta: { authRequired: true },
    props: true,
    beforeEnter: (to, from, next) => {       
        store
        .dispatch('api/getFavesLists')
        .then(() => { 
          next();
        }); 
    },
    component: Favourites
  },{
    path: "/favourites/:favouriteListId",
    name: "FavouritesDetails",
    meta: { authRequired: true },
    props: true,
    // beforeEnter: (to, from, next) => {       
    //     store
    //     .dispatch('api/getFavouriteList', to.params.id)
    //     .then((resp) => { 
    //       console.log("loaded")
		// 			console.log(resp)
    //       to.params.favelist = resp
    //       next()
    

    //     }); 
    // },
    component: FavouritesDetails
  },{
    path: "/artists/profiles/:id",
    name: "ArtistProfileView",
    meta: { authRequired: true },
    props: true,
    /*beforeEnter: authGuard,*/
    beforeEnter: (to, from, next) => {
      
      to.params.is_venue = false;
      
      store.dispatch('api/getArtist', to.params.id).then(function(artist){

        store
        .dispatch('api/getFavesLists')
        .then(() => {
          to.params.artist = artist;
          to.params.artistId = Number(to.params.id);
					console.log("go")
          next();
        }); 

      });

    },
    component: ArtistProfileView
  },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   meta: { authRequired: true },
  //   beforeEnter: (to, from, next) => {
  //     store
  //       .dispatch('api/getVenues')
  //       .then(() => {
  //         next()
  //       })
  //   },
  //   component: Calendar
  // },
  // {
  //   path: "/calendar/:venueid",
  //   name: "CalendarByVenue",
  //   meta: { authRequired: true },
  //   beforeEnter: (to, from, next) => {
  //     store
  //       .dispatch('api/getGigs', 'api/getVenues')
  //       .then(() => {
  //         next()
  //       })
  //   },
  //   component: Calendar
  // },
	// Venue Routes
	...VenueRoutes(store),
	// Gig Routes
	...GigRoutes(store),
	// Invoice Routes
	...InvoiceRoutes(store),
	// Calendar Routes
	...CalendarRoutes(store),
  // {
  //   path: "/artist/calendar",
  //   name: "ArtistCalendar",
  //   meta: { authRequired: true },
  //   beforeEnter: (to, from, next) => {
  //     store
  //       .dispatch('api/getArtistBids')
  //       .then(() => {
  //         next()
  //       })
  //   },
  //   component: ArtistCalendar
  // },
  {
    path: "/artist-directory",
    name: "ArtistDirectory",
    meta: { authRequired: true },
    beforeEnter: (to, from, next) => {

 
      Promise.all([ 
        store.dispatch('api/getFavesLists') 
      ])
      .then(() => {
        next();
      });

    },
    component: ArtistDirectory
  },
  {
    path: "/artist-directory/:id/:slug",
    name: "ArtistDirectoryPage",
    meta: { authRequired: true },
    props: true,
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
 
        next();
    },
    component: ArtistProfileView
  },


  // {
  //   path: "/gigs",
  //   name: "GigsList",
  //   meta: { authRequired: true },
  //   beforeEnter: (to, from, next) => {
  //     store
  //       .dispatch('api/getGigs')
  //       .then(() => {
  //         next()
  //       })
  //   },
  //   // beforeEnter: authGuard,
  //   component: GigsList
  // },
  // {
  //   path: "/gigs/add",
  //   name: "NewGig",
  //   meta: { authRequired: true },
  //   props: true,
  //   beforeEnter: (to, from, next) => { 
  //     Promise.all([ 
  //       store.dispatch('api/getTiers'),
  //       store.dispatch('api/getArtistTags'),
  //       store.dispatch('api/getGigTemplates'),
  //       store.dispatch('api/getVenues'),
  //     ]).finally(() => {
  //       console.log("dispatched gigs tiers tags templates");

  //       next(); 
  //   })},
  //   component: GigDetails
  // },
  // {
  //   path: "/gigs/:id",
  //   name: "GigDetails",
  //   meta: { authRequired: true },
  //   props: true,
  //   /*beforeEnter: authGuard,*/
  //   beforeEnter: (to, from, next) => {
 
  //     Promise.all([
  //       store.dispatch('api/getTiers'),
  //       store.dispatch('api/getArtistTags'),
  //       store.dispatch('api/getGigTemplates'),
  //       store.dispatch('api/getVenues'),
  //     ]).finally(() => {

  //       to.params.gigId = Number(to.params.id);
  //       next();
  //     })
 
  //   },
  //   component: GigDetails
  // },
  // {
  //   path: "/gigs/:id/choose-artists",
  //   name: "GigChooseArtists",
  //   meta: { authRequired: true },  
  //   props: true,
  //   beforeEnter: (to, from, next) => {
      
  //     Promise.all([ 
  //       store.dispatch('api/getArtistTags'),
  //       store.dispatch('api/getFavesLists'),
  //     ]).finally(() => {
        
  //       next();

  //     });
       
    
  //   },
  //   component: GigChooseArtists
  // }, 
  // {
  //   path: "/gigs/:id/repeat",
  //   name: "GigRepeat",
  //   meta: { authRequired: true },  
  //   props: true,
  //   beforeEnter: (to, from, next) => { 
  //         next();
      
  //   },
  //   component: GigRepeat
  // }, 
  // {
  //   path: "/gigs/:id/instructions",
  //   name: "GigInstructions",
  //   meta: { authRequired: true },
  //   props: true, 
  //   beforeEnter: (to, from, next) => { 
  //       next();
  //   },
  //   component: GigInstructions
  // }, 
  // {
  //   path: "/gigs/:id/notes",
  //   name: "GigNotes",
  //   meta: { authRequired: true },
  //   props: true,
  //   component: GigNotes
  // },
  // {
  //   path: "/gigs/:id/offers",
  //   name: "GigBids",
  //   meta: { authRequired: true },  
  //   props: true,
  //   beforeEnter: (to, from, next) => {
 
  //         next();  
  //   },
  //   component: GigBids
  // }, 
  // {
  //   path: "/gigs/:id/bookings",
  //   name: "GigBookings",
  //   meta: { authRequired: true },  
  //   props: true,
  //   beforeEnter: (to, from, next) => { 
  //     next();
  //   },
  //   component: GigBookings
  // }, 
  // {
  //   path: "/gigs/:id/invoices",
  //   name: "GigInvoices",
  //   meta: { authRequired: true },  
  //   props: true,
  //   beforeEnter: (to, from, next) => { 
  //       next();
  //   },
  //   component: GigInvoiceIndex
  // }, 
  // {
  //   path: "/pending-gigs",
  //   name: "PendingGigs",
  //   meta: { authRequired: true },  
  //   props: true, 
  //   component: ChaseArtists
  // }, 
  // {
  //   path: "/past-gigs",
  //   name: "PastGigs",
  //   meta: { authRequired: true },  
  //   props: true, 
  //   component: PastGigs
  // }, 
  // {
  //   path: "/invoices",
  //   name: "AllInvoices",
  //   meta: { authRequired: true },  
  //   props: true,
  //   component: AllInvoices
  // },  
  // {
  //   path: "/artist/invoices",
  //   name: "AllArtistInvoices",
  //   meta: { authRequired: true },  
  //   props: true,
  //   beforeEnter: (to, from, next) => {
  //     store
  //       .dispatch('api/getArtistBids').then(function()
  //       {            
  //         next();  
  //       });
    
  //   },
  //   component: AllArtistInvoices
  // }, 
  // {
  //   path: "/gigtemplates",
  //   name: "GigTemplatesList",
  //   meta: { authRequired: true },
  //   beforeEnter: (to, from, next) => {
  //     store
  //       .dispatch('api/getGigTemplates')
  //       .then(() => {
  //         next()
  //       })
  //   },
  //   // beforeEnter: authGuard,
  //   component: GigTemplateList
  // },
  // {
  //   path: "/gigtemplates/add",
  //   name: "NewGigTemplate",
  //   meta: { authRequired: true },
  //   props: true,
  //   beforeEnter: (to, from, next) => {
      
  //     Promise.all([       
  //       store.dispatch('api/getTiers'),     
  //       store.dispatch('api/getFavesLists'), 
  //       store.dispatch('api/getArtistTags'), 
        
  //     ]).finally(() => { 
        
  //       next();
      
  //     })
  //   },
  //   component: GigTemplateDetails
  // },
  // {
  //   path: "/gigtemplates/:id",
  //   name: "GigTemplateEdit",
  //   meta: { authRequired: true },
  //   props: true,
  //   /*beforeEnter: authGuard,*/
  //   beforeEnter: (to, from, next) => {


  //     Promise.all([      
  //       store.dispatch('api/getTiers'),            
  //       store.dispatch('api/getArtistTags'),     
  //       store.dispatch('api/getFavesLists'), 
        
  //     ]).finally(() => { 
         

  //       next();
        
 
  //     })
 

  //   },
  //   component: GigTemplateDetails
  // },
  {
    path: "/auth/callback",
    name: "Callback", 
    beforeEnter: authGuard,
    component: AuthCallback
  },
	
  {
    path: "/messages/offer/:gigID/:bidID",
    name: "BidMessages", 
    meta: { authRequired: true },
    component: MessagesList, 
    props: true
    
  }, 
  {
    path: "/messages/gig/:gigID",
    name: "GigMessages", 
    meta: { authRequired: true },
    component: MessagesList, 
    props:true    
  }, 
  {
    path: "/artist/messages",
    name: "ArtistMessages", 
    meta: { authRequired: true },
    component: ArtistMessagesList, 
    beforeEnter: (to, from, next) => {
      {
        store
          .dispatch('api/getArtistBids')
          .then(() => {
            next()
          })
      }
    }
  }, 
  {
    path: "/messages",
    name: "Messages", 
    meta: { authRequired: true },
    component: MessagesList, 
    props: true
  }, 
  {
    path: "/artist",
    name: "ArtistIndex",
    meta: { authRequired: true },
        
    beforeEnter: (to, from, next) => { 
      Promise.all([
        store.dispatch('api/getArtistBids'),
        store.dispatch('api/getArtists'),
        store.dispatch('api/getArtistUnreadMessages') 
      ]).finally(() => { 

        next(); 
    })}, 
    component: ArtistIndex
  },
  {
    path: "/artist/awaiting-venue-response",
    name: "AwaitingVenue",
    meta: { authRequired: true },
    component: ArtistAwaitingVenue,
    beforeEnter: (to, from, next) => {
      store
        .dispatch('api/getArtistBids')
        .then(() => {
          next()
        })
    },
  },
  {
    path: "/artist/offers",
    name: "ArtistBidIndex",
    meta: { authRequired: true },
    component: ArtistBidIndex,
    beforeEnter: (to, from, next) => {
      store
        .dispatch('api/getArtistBids')
        .then(() => {
          next()
        })
    },
  },
  {
    path: "/artist/final-acceptance",
    name: "FinalAcceptance",
    meta: { authRequired: true },
    component: ArtistFinalAccept,
    beforeEnter: (to, from, next) => {
      store
        .dispatch('api/getArtistBids')
        .then(() => {
          next()
        })
    },
  },
  {
    path: "/artist/gigs",
    name: "ArtistBookingIndex",
    meta: { authRequired: true },
    component: ArtistBookingIndex,
    beforeEnter: (to, from, next) => {
      store
        .dispatch('api/getArtistBids')
        .then(() => {
          next()
        })
    },
  },
  {
    path: "/artist/offers/:id",
    name: "ArtistBidDetails",
    meta: { authRequired: true },
    props: true,
    component: ArtistBidDetails
  },
  {
    path: "/artist/bookings/:id",
    name: "ArtistGigDetails",
    meta: { authRequired: true },
    props: true,
    component: ArtistBidDetails
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition){
    
    return { top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})


// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  const authService = getInstance();

  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)

  // If auth isn't required for the route, just continue.
  if (!authRequired) return next()

  const fn = async () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {

      // Get the token
      const token  = await Vue.prototype.$auth.getTokenSilently()

      // Set the token in the axios default headers
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
			client.defaults.headers.common["Authorization"] = `Bearer ${token}`
      
      let claimKey = localStorage.getItem(process.env.VUE_APP_CLAIM_KEY); 

      if (claimKey)
      {
        let $key = claimKey;
        localStorage.removeItem(process.env.VUE_APP_CLAIM_KEY);
        router.push({name: 'ArtistProfile', query: { 'claim' : $key }});
      }
			
			Promise.all([
				// User
				store.dispatch('user/getUsersData')
				.then(() => {
					//
				}, err => {
					console.log(err)
				}),
				// User Roles
				store.dispatch('user/getUsersRoles')
				.then(() => {
					//
				}, err => {
					console.log(err)
				}),
			]).finally(() => {
				//
			});
			//
      return next();
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: routeTo.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
})

export default router
