import GigsList from '../views/Gigs/Index.vue';
import GigDetails from '../views/Gigs/ID.vue';
import GigChooseArtists from '../views/Gigs/ChooseArtists.vue';
import GigRepeat from '../views/Gigs/Repeat.vue';
import GigInstructions from '../views/Gigs/Instructions.vue';
import GigBids from '../views/Gigs/Bids.vue';
import GigBookings from '../views/Gigs/Bookings.vue';
import GigNotes from '../views/Gigs/Notes.vue';
import GigInvoiceIndex from '../views/Gigs/Invoices/Index.vue';
import ChaseArtists from '../views/Chase/Index.vue';
import PastGigs from '../views/Past/Index.vue';
import GigTemplateList from '../views/GigTemplates/Index.vue';
import GigTemplateDetails from '../views/GigTemplates/ID.vue';
export default (store) => [
	{
    path: "/gigs",
    name: "GigsList",
    meta: { authRequired: true },
    beforeEnter: (_, _2, next) => {
			// make sure userData is present
			store.dispatch('user/getUsersRoles')
				.then(() => {
					// check permission
					if(store.getters['user/hasManageGigsPermission']){
						// do route stuff
						store
						.dispatch('api/getGigs')
						.then(() => {
							next()
						})
					}else{
						next(false);
					}
				})
    },
    component: GigsList
  },
  {
    path: "/gigs/add",
    name: "NewGig",
    meta: { authRequired: true },
    props: true,
    beforeEnter: (to, from, next) => { 
			// make sure userData is present
			store.dispatch('user/getUsersRoles')
				.then(() => {
					// check permission
					if(store.getters['user/hasManageGigsPermission']){
						// do route stuff
						Promise.all([
							// store.dispatch('api/getGigs'),
							store.dispatch('api/getTiers'),
							store.dispatch('api/getArtistTags'),
							store.dispatch('api/getGigTemplates'),
							store.dispatch('api/getVenues'),
						]).finally(() => {
							console.log("dispatched gigs tiers tags templates");
			
							next(); 
			
						})
					}else{
						next(false);
					}
				})
      },
    component: GigDetails
  },
  {
    path: "/gigs/:id",
    name: "GigDetails",
    meta: { authRequired: true },
    props: true,
    beforeEnter: (to, from, next) => {
			// make sure userData is present
			store.dispatch('user/getUsersRoles')
				.then(() => {
					// check permission
					if(store.getters['user/hasManageGigsPermission']){
						// do route stuff
						Promise.all([
							store.dispatch('api/getTiers'),
							store.dispatch('api/getArtistTags'),
							store.dispatch('api/getGigTemplates'),
							store.dispatch('api/getVenues'),
						]).finally(() => {	 
							to.params.gigId = to.params.id;
							next()
						})
					}else{
						next(false);
					}
				})
     
 
    },
    component: GigDetails
  },
  {
    path: "/gigs/:id/choose-artists",
    name: "GigChooseArtists",
    meta: { authRequired: true },  
    props: true,
    beforeEnter: (to, from, next) => {
      // make sure userData is present
			store.dispatch('user/getUsersRoles')
			.then(() => {
				// check permission
				if(store.getters['user/hasManageGigsPermission']){
					// do route stuff
					Promise.all([ 
						store.dispatch('api/getArtistTags'),
						store.dispatch('api/getFavesLists'),
					]).finally(() => {
						next();
					});

				}else{
					next(false);
				}
			})

    },
    component: GigChooseArtists
  }, 
  {
    path: "/gigs/:gigId/repeat",
    name: "GigRepeat",
    meta: { authRequired: true },  
    props: true,
    beforeEnter: (to, from, next) => {
			// make sure userData is present
			store.dispatch('user/getUsersRoles')
				.then(() => {
					// check permission
					if(store.getters['user/hasManageGigsPermission']){
						// do route stuff
						next();
					}else{
						next(false);
					}
				})
    },
    component: GigRepeat
  }, 
  {
    path: "/gigs/:id/instructions",
    name: "GigInstructions",
    meta: { authRequired: true },
    props: true, 
    beforeEnter: (to, from, next) => { 
			// make sure userData is present
			store.dispatch('user/getUsersRoles')
				.then(() => {
					// check permission
					if(store.getters['user/hasManageGigsPermission']){
						next();
					}else{
						next(false);
					}
				})
        
    },
    component: GigInstructions
  }, 
  {
    path: "/gigs/:id/notes",
    name: "GigNotes",
    meta: { authRequired: true },
    props: true,
    /*beforeEnter: authGuard,*/
    beforeEnter: (to, from, next) => {
			// make sure userData is present
			store.dispatch('user/getUsersRoles')
				.then(() => {
					// check permission
					if(store.getters['user/hasManageGigsPermission']){
						// do route stuff
						next()
					}else{
						next(false);
					}
				})
      
    },
    component: GigNotes
  },
  {
    path: "/gigs/:id/offers",
    name: "GigBids",
    meta: { authRequired: true },  
    props: true,
    beforeEnter: (to, from, next) => {
			// make sure userData is present
			store.dispatch('user/getUsersRoles')
				.then(() => {
					// check permission
					if(store.getters['user/hasManageGigsPermission']){
						// do route stuff
						next();
					}else{
						next(false);
					}
				})
      
    },
    component: GigBids
  }, 
  {
    path: "/gigs/:id/bookings",
    name: "GigBookings",
    meta: { authRequired: true },  
    props: true,
    beforeEnter: (to, from, next) => {
			// make sure userData is present
			store.dispatch('user/getUsersRoles')
				.then(() => {
					// check permission
					if(store.getters['user/hasManageGigsPermission']){
						// do route stuff
						next();
					}else{
						next(false);
					}
				})
    },
    component: GigBookings
  }, 
  {
    path: "/gigs/:id/invoices",
    name: "GigInvoices",
    meta: { authRequired: true },  
    props: true,
    beforeEnter: (to, from, next) => {
			// make sure userData is present
			store.dispatch('user/getUsersRoles')
			.then(() => {
				// check permission
				if(store.getters['user/hasManageGigsPermission']){
					// do route stuff
					next();
				}else{
					next(false);
				}
			})
    },
    component: GigInvoiceIndex
  }, 
  {
    path: "/pending-gigs",
    name: "PendingGigs",
    meta: { authRequired: true },  
    props: true,
    beforeEnter: (to, from, next) => {
			// make sure userData is present
			store.dispatch('user/getUsersRoles')
				.then(() => {
					// check permission
					if(store.getters['user/hasManageGigsPermission']){
						// do route stuff
						next(); 
					}else{
						next(false);
					}
				})
    },
    component: ChaseArtists
  }, 
  {
    path: "/past-gigs",
    name: "PastGigs",
    meta: { authRequired: true },  
    props: true,
    beforeEnter: (to, from, next) => {
			// make sure userData is present
			store.dispatch('user/getUsersRoles')
				.then(() => {
					// check permission
					if(store.getters['user/hasManageGigsPermission']){
						// do route stuff
						next();  
					}else{
						next(false);
					}
				})
    },
    component: PastGigs
  }, 
	{
    path: "/gigtemplates",
    name: "GigTemplatesList",
    meta: { authRequired: true },
    beforeEnter: (to, from, next) => {
			// make sure userData is present
			store.dispatch('user/getUsersRoles')
				.then(() => {
					// check permission
					if(store.getters['user/hasManageGigsPermission']){
						// do route stuff
						store
						.dispatch('api/getGigTemplates')
						.then(() => {
							next()
						})
					}else{
						next(false);
					}
				})
      
    },
    // beforeEnter: authGuard,
    component: GigTemplateList
  },
  {
    path: "/gigtemplates/add",
    name: "NewGigTemplate",
    meta: { authRequired: true },
    props: true,
    beforeEnter: (to, from, next) => {
			// make sure userData is present
			store.dispatch('user/getUsersRoles')
			.then(() => {
				// check permission
				if(store.getters['user/hasManageGigsPermission']){
					// do route stuff
					Promise.all([       
						store.dispatch('api/getTiers'),     
						store.dispatch('api/getFavesLists'), 
						store.dispatch('api/getArtistTags'), 
					]).finally(() => { 
						next();
					})
				}else{
					next(false);
				}
			})
      
    },
    component: GigTemplateDetails
  },
  {
    path: "/gigtemplates/:id",
    name: "GigTemplateEdit",
    meta: { authRequired: true },
    props: true,
    /*beforeEnter: authGuard,*/
    beforeEnter: (to, from, next) => {
			// make sure userData is present
			store.dispatch('user/getUsersRoles')
				.then(() => {
					// check permission
					if(store.getters['user/hasManageGigsPermission']){
						// do route stuff
						Promise.all([      
							store.dispatch('api/getTiers'),            
							store.dispatch('api/getArtistTags'),     
							store.dispatch('api/getFavesLists'), 
						]).finally(() => { 
							next();
						})
					}else{
						next(false);
					}
				})
    },
    component: GigTemplateDetails
  },
]
