<template>
  <div>

    <Header Heading="Artist Directory" hidden />


    <div class="max-w-7xl p-1 lg:m-6 ">

      
    <InlineNotification Heading="Artist Directory" class="mb-4">
        <template slot="body">
          <p>Every artist that is signed up to GigPig is stored right here.</p>

<p>Use the filter below to help you find the specific style of artist that you want for your gig. Or if you know the name of the artist, search by name and find them that way.</p>

<p>If you find someone you really like, click on the “star” and add them to your favourites.
</p>
        </template> 
    </InlineNotification>
      <div class="max-w-4xl  mb-6 justify-start items-baseline lg:space-x-2 space-y-2  flex-col lg:flex-row flex">
           
             <t-rich-select
            multiple
            :close-on-select="false"
            :options="tiers" text-attribute="name" value-attribute="id" 
            placeholder="Type" class="w-full lg:w-3/6"  ref="tiers"
        ></t-rich-select> 


             <t-select
            :close-on-select="false" ref="styles"
            :options="styles" text-attribute="name" value-attribute="id" 
            placeholder="Style"  class="w-full lg:w-3/6"
        ></t-select> 


             <t-rich-select
            multiple
            :close-on-select="false" ref="genres"
            :options="genres" text-attribute="name" value-attribute="id" 
            placeholder="Genre"  class="w-full lg:w-3/6"
        ></t-rich-select> 
        <t-input name="search" ref="search_name" placeholder="Search by name" v-model="search_phrase" />
 
        <button class="gp_cta lg:w-min w-full" @click="filter()">
            Filter
        </button>
      </div>

      <div class="flex flex-col ">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle lg:align-baseline inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="max-w-4xl align-baseline shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 w-40 marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Artists
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                    ></th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    
                  <tr class="cursor-pointer" v-for="(artist, index) in tabledata" :key="index">
                      <td  @click="$router.push('/artist-directory/' + artist.id + '/' + slugify(artist.name).toLowerCase())" class="text-left mx-2 p-2 relative">
                          <img :src="artist.image ? artist.image.url : require('@/assets/images/placeholder.svg')" :alt="artist.name" class="w-40 h-40 object-cover object-center" />
                      </td> 
                      <td  @click="$router.push('/artist-directory/' + artist.id + '/' + slugify(artist.name).toLowerCase())" class="text-left mx-2 p-6 relative">
                          <strong>{{artist.name}}</strong><br/>
                          {{artist.artist_tags.map(tag => tag.name).join(', ')}}
                      </td> 
                      <td class="p-6">
                          {{artist.tier.name}}
                      </td>
                      <td class="p-6 ">
                          <div class="flex space-x-4 justify-center items-center text-center">
                            <a class="text-gp_pink-default-500" @click="$refs.faveModal.toggleModal();;addArtist = artist;">
                            <StarIcon title="Add to favourites list" /></a>
                            <router-link :to="'/artist-directory/' + artist.id + '/' + slugify(artist.name).toLowerCase()">View</router-link >
                          </div>
                      </td>
                  </tr>

                  <tr v-if="tabledata.length == 0">
                    <td class="p-6" colspan="4">
                      Use the search above to find your artists.
                    </td>
                    </tr>
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FaveManagement  ref="faveModal" :Artist="addArtist"  />
  </div>
</template>

<script>
import appConfig from '@/app.config'
import slugify from 'slugify';
import { apiComputed, apiMethods } from '@/state/helpers.js'
import formatDate from  '@/utils/format-date.js' 
import InlineNotification from '../../components/notifications/inline.vue'
import Header from '../../components/listing/header.vue'
import FaveManagement from '../../components/modal/FaveManagement.vue'
import {
   TRichSelect, TInput, TSelect
} from 'vue-tailwind/dist/components'; 

import { StarIcon } from '@vue-hero-icons/outline'
import NProgress from 'nprogress'

export default {
  components: {
    InlineNotification,
    Header,
    TRichSelect, 
    TInput,
    TSelect,
    FaveManagement,
    StarIcon
},
  data() {
    return {
      appConfig,
      showFaveManagement: false,
      addArtist: {},
      styles: [],
      genres: [],
      tabledata: [],
      search_phrase: ''
    };
  },
    created() {  
      this.getFavesLists(),
      this.getTiers()
    },
    computed:{
        ...apiComputed, 
             
    },
    mounted(){
      
      NProgress.start();
      this.getArtistTags().then((resp) => {

        let $tags = resp;
        console.log("tags", $tags)
        this.styles = $tags.filter(tag => tag.tag_type == 100);
        this.genres = $tags.filter(tag => tag.tag_type == 200);
        NProgress.done();
      });
    },
  methods: {
    ...apiMethods,
    formatDate,
    slugify, 
    filter(){
         this.loadData();
    },
    loadData(){
       

      NProgress.start()
      if (this.$store.state.api.artistdirectory){       
        
          this.tabledata = this.convertedRows(this.$store.state.api.artistdirectory);

          NProgress.done();
      }else{
          this.getArtistDirectory().then(($resp) => { 
         
            this.tabledata = this.convertedRows($resp);
          }).finally(() => {
            NProgress.done();
          });
      }

 
    },
    convertedRows($directory) {
         

      let selectedTiers = this.$refs.tiers.selectedOptions;
      let selectedStyle = this.$refs.styles.localValue;
      let selectedGenres = this.$refs.genres.selectedOptions ;
  
      let tiers = selectedTiers.map(tier => tier.value);
      let genres = selectedGenres.map(genre => genre.value);

      let searchPhrase = this.search_phrase;



      if (tiers.length > 0){
          console.log("filter by tiers");
          $directory = $directory.filter(function(artist){               
               return tiers.indexOf(artist.tier.id) !== -1; 
          });
      }

      if (selectedStyle != '' && selectedStyle != null){
          console.log("filter by style");
          $directory = $directory.filter(function(artist){

              let hasNeither = artist.artist_tags.filter(function (tag){
                return tag.id == 29 || tag.id == 30;
              }).length == 0;


              return artist.artist_tags.map(tag => tag.id).indexOf(parseInt(selectedStyle)) != -1 || hasNeither
          });
      }

      if (genres.length > 0){
          console.log("filter by genre");
          $directory = $directory.filter(function(artist){      
                return artist.artist_tags.map(tag => tag.id).some(tag => genres.indexOf(tag) != -1); 
          });
      }
 
      if (searchPhrase.length > 0){
        
          console.log("filter by text");
          $directory = $directory.filter(function(artist){   
            return artist.name.toLowerCase().search(searchPhrase.toLowerCase())  != -1;
          });
      }


      



      return $directory;
    }
  }
};
</script>
