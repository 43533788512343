<template>
    <div v-if="is_open" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
               
            </div>
    
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    
            <transition appear enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">        
                <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                    <div>
                        <div class="text-left sm:mt-5 flex justify-center flex-col items-center">
                            <div class="text-lg text-center"> 
                                Are you sure you want to sign-off {{toSignOff.length == 1 ? 'this artist' : 'these artists'}}?
                            </div>
                            <div class="flex flex-col lg:flex-row w-full justify-between">
                                <a @click="cancel()" class="cta text-center mt-6 my-2 lg:mb-0 items-center bg-indigo-500">
                                    Cancel
                                </a>
                                <a @click="accept()" class="cta text-center mt-6  items-center bg-gp_pink-default-500">
                                    Yes, sign off  {{toSignOff.length == 1 ? "this artist" : "these"}} 
                                    {{toSignOff.length > 1 ? pluralize(toSignOff.length, "artist") : ''}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <NotificationSmall ref="notification" />
    </div>
</template>

<script>

import pluralize from "../../utils/pluralize";
import { apiComputed, apiMethods } from '@/state/helpers.js'
import NotificationSmall from '../../components/notifications/small.vue';

export default
{
    name: "SignoffArtists",
    data(){
        return {
            is_open: false
        }
    },
    components:{
        NotificationSmall
    },
    props:{
        toSignOff: Array
    },
    computed: {
        ...apiComputed
    },
    methods:
    {
        ...apiMethods,
        pluralize,
        open(){
            if (this.toSignOff.length > 0){
                this.is_open = true;
            }
        },
        cancel(){
            this.is_open = false;
        },
        throwError(){
            self.$refs.notification.createNotification("There was an issue communicating with GigPig", true);
        },
        accept(){
            let self = this;
            this.signoffArtists(this.toSignOff).then((response) => { 

                if (response)
                {

                    self.$notify("Artists signed off, invoices released");
                    self.$router.push('/invoices');
                }
                else{
                    this.throwError();                     
                }
            }).catch(() => {
                this.throwError();
            });
        }
    }
}

</script>