<template>
      <div class="hidden lg:block bg-gp_gray-default-200 md:flex md:flex-shrink-0 venuenav" :class="is_gigpig ? '' : 'schloss'">
    <div class="flex flex-col flex-wrap w-72">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
        <div class="flex items-center flex-shrink-0 px-4">
          <img class="h-14 w-auto" :src="logo" :alt="alt">
        </div>
        <NavContainer/>      
      </div>
    </div>

  </div>
</template>

    <style type="text/css">

        .venuenav.schloss 
        {
          background-color: #000 !important;
        }

        .venuenav.schloss a
        {
          color:white !important;
        }

        .venuenav.schloss svg
        {
          color:#e8e1d9 !important;
        }
        .venuenav.schloss .router-link-active,
        .venuenav.schloss .hover\:bg-indigo-600:hover
        {
          background-color:#464646 !important;
        }
 

    </style>
<script>
import NavContainer from './NavContainer.vue'
export default {
    name: "NavDesktopSidebar",
    data(){
      return {
        logo: require("@/assets/images/platform_logo.svg"),
        alt: "GigPig. Gigs made easy.",
        is_gigpig: true
      }
    },
    components:{
      NavContainer
    },
    methods: {
        async applyBranding() {
 
          if (window.location.hostname == "schloss.gigpig.uk" ){
            this.logo = "https://wp3-fatsomasites.imgix.net/uploads/sites/516/2018/07/alberts_schloss_logo_small.png?auto=format%2Ccompress";
            this.alt = "Albert Schloss";
            this.is_gigpig = false;
          } 
        }
    },
    beforeMount() {
        this.applyBranding();
    }
   
}
</script>