<template>
  <div>
    <ModalDefault :artist_only="true"  />
  </div>
</template>

<script> 
 
import ModalDefault from "../components/modal/ModalDefault.vue"; 

export default {
  name: "Home",
  components: {
    ModalDefault, 
  },
  beforeRouteUpdate(){
 

  }
};
</script>
