import client from "../../utils/client"



export const state = {
  artistBids: null,
}

export const mutations = {
  SET_ARTIST_BIDS(state, artistBids) {
    state.artistBids = artistBids
  },
}

export const getters = {
	artistBids: state => {
    return state.artistBids;
  },
}

export const actions = {
	storeArtistBids({ commit }, artistBids){
		commit('SET_ARTIST_BIDS', artistBids);
	},
  getArtistBids({ getters, dispatch }, refresh = false) {
		return new Promise((resolve) => {
			const artistBids = getters.artistBids
			if(artistBids && !refresh){
				resolve(getters.artistBids)
			}else{
				dispatch('loadArtistBids')
				.then(() => {
					resolve(getters.artistBids);
				}).catch(err => {
					console.log(err)
				});
			}
		})
  },
	loadArtistBids({ dispatch }){
		return new Promise((resolve) => {
			client
					.get(`/artist/bids`)
					.then((response) => {
						dispatch('storeArtistBids', response.data.data)
						resolve(response.data.data)
					})
					.catch(err => {
						console.log(err)
					});
		})
	}
} 