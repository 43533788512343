<template>
    
    <div class="dashboard text-left lg:max-w-3xl xl:max-w-none mt-6 rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 divide-y-0 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-px">
    
  <DashboardStep URL="/artists/add"
  :IsComplete="has_artists" BackgroundClass="bg-yellow-50" TextColorClass="text-yellow-700" 
  Title="Create your artist page">

    <template v-slot:icon>
        <UserIcon />
    </template>
    
    <template v-slot:description>
       <p>Sell it. Tell venues who you are and what you’re about. Upload your photos, add videos, and link your SoundCloud too to show them what you can do. </p>
        <p> 
            More than one act? Link all your artist profiles to one account. </p>
    </template>

  </DashboardStep>    
     
  <DashboardStep URL="/artist/offers"
  :IsComplete="has_offers" BackgroundClass="bg-blue-50" TextColorClass="text-blue-700" 
  Title="View your gig offers">

    <template v-slot:icon>
        <TicketIcon />
    </template>
    
    <template v-slot:description>
        Profile done? Watch the gig offers come rolling in. View potential venue locations and the all-important fees here, then pick whether you can do the gig or not.
    </template>

  </DashboardStep>    
     
 
  <DashboardStep URL="/artist/invoices"
  :IsComplete="has_raised_invoice" BackgroundClass="bg-green-50" TextColorClass="text-green-700" 
  Title="Money">

    <template v-slot:icon>
        <CurrencyPoundIcon />
    </template>
    
    <template v-slot:description>
        The business bit. Once you’ve played a gig, GigPig will automatically generate an invoice for you and send it to the venue. The venue will let you know when it’s been paid, and you can download any invoices for your records. 
    </template>

  </DashboardStep>    
  
  <DashboardStep URL="/artist/calendar"
  :IsComplete="false" BackgroundClass="bg-purple-50" TextColorClass="text-purple-700" 
  Title="GigPig Calendar">

    <template v-slot:icon>
        <CalendarIcon />
    </template>
    
    <template v-slot:description>
        Busy gig schedule? This calendar’s your go-to for booked gigs, pending gigs, and keeping track of which gigs have been paid. If you’ve got more than one act, you’ll see all your bookings in one place here.
    </template>

  </DashboardStep>    


  
  <DashboardStep URL="/artist/messages"
  :IsComplete="false" BackgroundClass="bg-gray-50" TextColorClass="text-gray-700" 
  Title="Messages">

    <template v-slot:icon>
        <AnnotationIcon />
    </template>
    
    <template v-slot:description>
        Keep it simple. Have all your messages with the venue in one place. Chat with them directly about each gig and view your message history with them too – just in case. 
    </template>

  </DashboardStep>    
 
    </div>

</template>

<script>
import DashboardStep from './DashboardStep.vue'
import { UserIcon, TicketIcon, CurrencyPoundIcon, AnnotationIcon, CalendarIcon  } from '@vue-hero-icons/outline'
export default {
    components: { DashboardStep, UserIcon, TicketIcon, CurrencyPoundIcon, AnnotationIcon, CalendarIcon },
    name: "ArtistCompleteSteps",
    props:{
        has_artists: Boolean,
        has_offers: Boolean,
        has_bookings: Boolean,
        has_raised_invoice: Boolean,

    },
    data() {
        return  {
            support_url: process.env.VUE_SUPPORT_DOMAIN == null ? 'https://support.gigpig.uk' : process.env.VUE_SUPPORT_DOMAIN  
        }
    }
}
</script>