<template>
    <div>
        <div class="details-view mb-36">

            <h3 v-if="!ClaimProfile">{{this.$attrs.artistId ? 'Edit' : 'Add'}} your artist profile here</h3>
            <div>
                <div class=" lg:max-w-5xl">

                    <InlineNotification Heading="New artist profile" class="p-0 m-0" v-if="ClaimProfile">
                        <template slot="body">
                            <div class="grid grid-flow-row gap-4">
                                <p>Hi {{this.$auth.user.name}}.  Welcome to GigPig.  We've started off your profile for {{ClaimProfile.artist_name}}.  Once your profile is complete, you will officially be a GigPig artist. You will be visible to all venues in your region and they can start offering you gigs.</p>
                            </div>
                        </template>
                    </InlineNotification>

                    <InlineNotification Heading="Create your artist profile" class="p-0 m-0" v-else-if="!this.$attrs.artistId">
                        <template slot="body">
                        <p>It’s showtime. Add everything that makes your act the one to book. You’ll show up in all venue searches in the region, and you’ll officially become a GigPig artist.  </p>
                        </template>
                    </InlineNotification>
                </div>
            </div>

        <form class="mb-6 form input-group lg:max-w-5xl" ref="form" novalidate>
            <div class="mt-6 sm:mt-5 col-span-full">
              <h4 class="uppercase text-lg leading-6 font-bold text-gray-900">
                Artist info
              </h4>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Venues will be able to see all this info on your artist profile page.
              </p>
            </div>

             <div class="mt-6 sm:mt-5  col-span-full space-y-6 sm:space-y-5">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="artist-name"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Artist / Band name
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">


                       <InputText required id="artist-name" v-on:input="form.name = $event;" v-bind:value="form.name" hint="The artist/band name must be populated"
                       error="The artist/band name must be completed" name="artist-name"
                        />

                </div>
              </div>
            <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="artist-telephone"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Mobile
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">


                       <InputText id="artist-telephone" required v-on:input="form.telephone = $event;" v-bind:value="form.telephone" type="tel"
                       error="The artist mobile must be completed" name="artist-telephone" class="max-w-xs"
                        />

                </div>
              </div>
            <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="artist-telephone"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Email address
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">


                       <InputText id="artist-email" required v-on:input="form.email = $event;" v-bind:value="form.email" type="email"
                       error="The artist email must be completed" name="artist-email" class="max-w-xs"
                        />

                </div>
              </div>              
             </div>

            <div class="mt-6 sm:mt-5 col-span-full sm:border-t sm:border-gray-200 sm:pt-5">
              <h4 class="uppercase text-lg leading-6 font-bold text-gray-900">
                Matching you with the right gig
              </h4>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                We’ll use the info here to get the best gigs for you.  
              </p>
            </div>

            <div class="mt-6 sm:mt-5 space-y-6  col-span-full sm:space-y-5">

              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                <div class="mt-1 sm:mt-0 sm:col-span-2">

                    <SearchByTag 
                      ref="searchTags" 
                      :tiers="tiers"
                      :styles="styles"
                      :genres="genres"
                      :search_styles="form.search_styles"
                      :search_tiers="form.search_tiers"
                      :search_genres="form.search_genres"
                    >

                    </SearchByTag>

                </div>
              </div>
            </div>

            <div class="mt-6 sm:mt-5 space-y-6  col-span-full sm:space-y-5">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <span>Your home address</span>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                <label
                  for="artist-address-line1"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Line 1
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">



                       <InputText required id="artist-address-line1" v-on:input="form.address.address_line_1 = $event;" v-bind:value="form.address.address_line_1"
                       error="Artist address line one must be provided" name="artist-address-line1" class="max-w-lg sm:max-w-xs"
                        />
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-line2"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Line 2
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="artist-address-line2"
                    id="artist-address-line2"
                    v-model="form.address.address_line_2"
                    class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="artist-address-town"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Town/City
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">


                       <InputText required id="venue-address-town" v-on:input="form.address.town = $event;" v-bind:value="form.address.town"
                       error="The artist town must be provided" name="artist-address-town" class="max-w-lg sm:max-w-xs"
                        />
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-county"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  County
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">


                       <InputText required id="venue-address-county" v-on:input="form.address.county = $event;" v-bind:value="form.address.county"
                       error="The artist county must be provided" name="artist-address-county" class="max-w-lg sm:max-w-xs"
                        />
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-postcode"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Postcode
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">

                       <InputText required id="venue-address-postcode" v-on:input="form.address.post_code = $event;" v-bind:value="form.address.post_code"
                       error="Your artist address must be provided" name="artist-address-postcode" class="max-w-lg sm:max-w-xs"
                        />
                </div>
              </div>

            <div class="mt-6 sm:mt-5 space-y-6  col-span-full sm:space-y-5">

              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                <label
                  for="artist-address-line1"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Distance willing to travel to gigs
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">



                       <InputText suffix="miles" type="number" required id="artist-miles" v-on:input="form.distance_limit = $event;" v-bind:value="form.distance_limit.toString()"
                       error="Willingness to travel must be provided" name="artist-miles" class="max-w-lg sm:max-w-xs"
                        />
                </div>
              </div>
            </div>


                          <!-- Profile Contact Details -->

        <Accordian heading="Profile information" :toggle="true" bg_class="bg-gp_pink-default-300" class="mt-6 sm:mt-5  col-span-full space-y-6 sm:space-y-5">
            <template #contents>

            <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="artist-telephone"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Your bio
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">


                       <InputTextArea id="artist-description"  v-on:input="form.description = $event;" v-bind:value="form.description"
                       error="Your bio must be completed" name="artist-description" rows="6"
                        />

                </div>
              </div>


            <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="artist-telephone"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Your top 5 songs you perform
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <div v-for="(song_list, index) in form.song_list" :key="index">
                    <div class="flex items-center mt-1">
                      <InputText
                        name="song_list[]"
                        class="flex-1 mt-0"
                        v-model="form.song_list[index]"
                      />
                      <button class="ml-3" type="button" @click="deleteSong(index)">
                        <XIcon /> <span class="sr-only">Delete</span>
                      </button>
                    </div>
                  </div> 
                  <button 
                    type="button" 
                    @click="addSong" 
                    :disabled="form.song_list && form.song_list.length >= 5"
                    class="flex items-center mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                  >
                    <PlusIcon fill="#fff" /> Add song
                  </button>
                </div>
              </div>

              <div
                class="sm:grid input-group sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="photo"
                  class="block text-sm font-medium text-gray-700"
                >
                  Artist profile picture
                </label>
                <div class="input-group mt-1 sm:mt-0 sm:col-span-2">                 
                  <profile-picture-upload :image="image" v-on:onImageChange="profilePictureUploaded" />
                </div>
              </div>

            <div class="mt-6 sm:mt-5 col-span-full">
              <h4 class="text-lg leading-6 font-bold text-gray-900">
                Gallery
              </h4>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Upload photos for your artist profile page (4mb limit, jpg or png).
              </p>
            </div>
              <div
                class="input-group sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="cover-photo"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Gallery
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <upload-gallery 
                    :images="gallery"
                    v-on:onGalleryChange="galleryChanged" 
                  />
                </div>
              </div>

            <div class="input-group mt-6 sm:mt-5 col-span-full">
              <h4 class="text-lg leading-6 font-bold text-gray-900">
                Videos
              </h4>

              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Add videos from YouTube, Vimeo, or Dailymotion. Paste the URL into the box below. 
              </p>

            </div>
			<div
				class="grid">
                <div
					class="mt-1 sm:mt-0 col-span-full">
                    <div
						class="grid grid-flow-row lg:grid-flow-col lg:grid-cols-3 gap-8">
                        <div
							v-for="(video, index) in 3" :key="index">
                            <video-embed
								:ref="'video_' + index"
								:src="form.videos ? form.videos[index] : ''">
							</video-embed>
                            <InputText
								name="video_0"
								v-model="form.videos[index]"
								v-on:input="form.videos[index] = $event;changeVideo($event, index); "
								v-bind:value="form.videos[index]"
								placeholder="Video e.g. https://www.youtube.com/watch?abcde" />
                        </div>
                   </div>
                </div>
            </div>


            <div class="input-group mt-6 sm:mt-5 col-span-full">
              <h4 class="text-lg leading-6 font-bold text-gray-900">
                Audio
              </h4>

              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Add audio from SoundCloud or Mixcloud. Paste the URL into the box below. 
              </p>
            </div>
			<div
				class="grid">
				<div
					class="mt-1 sm:mt-0 col-span-full">
					<div
						class="grid grid-flow-row lg:grid-flow-col lg:grid-cols-3 gap-8">
						<div
							v-for="(audio, index) in 3"
							:key="index">
							<iframe
								width="100%" title="audio"	:ref="'audio_' + index">
							</iframe>
							<InputText
								name="audio_0" data-pristine-audiourl
								v-model="form.audio[index]" v-on:input="form.audio[index] = $event;updateAudio($event, index)"
								placeholder="Audio e.g. https://www.soundcloud.com/" />
						</div>
					</div>
				</div>
			</div>



                                      </template>
</Accordian>

        <Accordian heading="Invoice / Billing address" :toggle="true" bg_class="bg-gp_pink-default-300" class="mt-6 sm:mt-5  col-span-full space-y-6 sm:space-y-5">
            <template #contents>

 <div class="mt-6 sm:mt-5  col-span-full">
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Add your invoice info, so venues can pay you directly.
              </p>
            </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-postcode"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Account Name
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">

                       <InputText required id="account_name" v-on:input="form.account_name = $event;" v-bind:value="form.account_name"
                       error="Your bank account name must be provided" name="account_name" class="max-w-lg sm:max-w-xs"
                        />

                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-postcode"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Account number
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">

                       <InputText required id="account_name" v-on:input="form.account_number = $event;" v-bind:value="form.account_number"
                       error="Your bank account number must be provided" name="account_name" class="max-w-lg sm:max-w-xs"
                        />

                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-postcode"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Sort code
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">

                       <InputText required id="account_sortcode" v-on:input="form.sortcode = $event;" v-bind:value="form.sortcode"
                       error="Your bank sort code must be provided" name="account_sortcode" class="max-w-lg sm:max-w-xs"
                        />

                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-postcode"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  My billing address is the same as my home address
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <t-toggle v-model="billing_same_as"></t-toggle>
                </div>
              </div>
            <div class="mt-6 sm:mt-5 space-y-6  col-span-full sm:space-y-5" v-if="!billing_same_as">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <span>Billing address</span>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                <label
                  for="venue-billing-address-line1"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                 Billing Line 1
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">



                       <InputText id="artist-billing-address-line1" v-on:input="form.billing_address.address_line_1 = $event;" v-bind:value="form.billing_address.address_line_1"
                       error="The artist billing address line one must be provided" name="artist-billing-address-line1" class="max-w-lg sm:max-w-xs"
                        />
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-line2"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                 Billing Line 2
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="venue-billing-address-line2"
                    id="venue-billing-address-line2"
                    v-model="form.billing_address.address_line_2"
                    class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-billing-address-town"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                 Billing Town/City
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">


                       <InputText id="venue-billing-address-town" v-on:input="form.billing_address.town = $event;" v-bind:value="form.billing_address.town"
                       error="The venue's billing town must be provided" name="venue-billing-address-town" class="max-w-lg sm:max-w-xs"
                        />
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-billing-address-county"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                 Billing County
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">


                       <InputText id="venue-billing-address-county" v-on:input="form.billing_address.county = $event;" v-bind:value="form.billing_address.county"
                       error="The venue's billing county must be provided" name="venue-billing-address-county" class="max-w-lg sm:max-w-xs"
                        />
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-postcode"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Billing Postcode
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">

                       <InputText id="venue-billing-address-postcode" v-on:input="form.billing_address.post_code = $event;" v-bind:value="form.billing_address.post_code"
                       error="The venue's billing postcode must be provided" name="venue-billing-address-postcode" class="max-w-lg sm:max-w-xs"
                        />
                </div>
              </div>
            </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-postcode"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Unique Taxpayer Reference (UTR)*
                  <span class="block text-xs italic">*Optional unless venue requested</span>
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <InputText
                    id="account_utr" 
                    v-on:input="form.utr_number = $event;" 
                    v-bind:value="form.utr_number"
                    name="account_utr" 
                    class="max-w-lg sm:max-w-xs"
                    placeholder="UTR 12345 67890"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-postcode"
                  class="block flex  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  <t-toggle v-model="show_vat_field"></t-toggle>
                  <span class="ml-3">VAT registered</span>
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <InputText
                    v-if="show_vat_field"
                    id="account_vat" 
                    v-on:input="form.vat_number = $event;" 
                    v-bind:value="form.vat_number"
                    name="account_vat" 
                    class="max-w-lg sm:max-w-xs"
                    placeholder="GB123456789"
                  />
                </div>
              </div>
            </template>
        </Accordian>


        </div>

        </form>
      <div class="pt-5 max-w-5xl col-span-full sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0">
        <div class="flex justify-end col-span-2">
          <button

            @click="save()"
            type="submit"
            class="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
          {{submitting == true ? 'Submitting... please wait' : 'Save' }}
          </button>
        </div>
      </div>
    </div>
        <NotificationSmall ref="notification" />
        <Claim ref="claim" v-if="claiming" message="Claiming your profile, please wait" />
    </div>
</template>

<script type="text/javascript">
import Pristine from '../../utils/pristine.js'
import Claim from '../../components/modal/Claim.vue'
import Accordian from '../../components/accordian/Accordian.vue'
import SearchByTag from '../../components/gigs/SearchByTag.vue'
import appConfig from "@/app.config";
import { apiComputed, authComputed, apiMethods } from "@/state/helpers";
import InputTextArea from '../../components/form/InputTextArea.vue'
import InputText from '../../components/form/InputText.vue'
import NotificationSmall from '../../components/notifications/small.vue';
import changeAudio from  '@/utils/change-audio.js'

import InlineNotification from '../../components/notifications/inline.vue'
import {
  TToggle
} from 'vue-tailwind/dist/components'
import UploadGallery from '../../components/uploads/upload-gallery.vue'
import ProfilePictureUpload from '../../components/uploads/profile-picture-upload.vue'
import { PlusIcon, XIcon } from '@vue-hero-icons/outline'

export default
{
    name: "AddEditArtist",
    components:{
    InlineNotification,
    InputText,
    InputTextArea,
    TToggle,
    SearchByTag,
    NotificationSmall,
    Accordian, 
    Claim,
    UploadGallery,
    ProfilePictureUpload,
    PlusIcon, 
    XIcon
},
    mounted(){

        if (this.debug){
            this.form = {
                name: "Boogaluga",
                email: "ed@builtbybeluga.com",
                telephone: "07901 585791",
                description: "This is a description",
                song_list: ["This is a song list"],
				videos: ["https://www.youtube.com/watch?v=d43pMOF_MQI"],
                miles: "40",
                address: {
                    address_line_1: "Line one",
                    address_line_2: "Line two",
                    town: "town",
                    county: "county",
                    post_code: "postcode",
                },
                billing_address: {
                    address_line_1: "Line one",
                    address_line_2: "Line two",
                    town: "town",
                    county: "county",
                    post_code: "postcode",
                },
                sortcode: "12-24-32",
                account_number: "12345678",
                account_name: "Account name",


            }
        }

        this.billing_same_as = true;

       // this.$refs.video_0.src = "https://www.youtube.com/watch?v=d43pMOF_MQI";

		Pristine.addValidator('audiourl', function(value) {
      
      try
      {
         new URL(value);  
      }
      catch(_){
        return true;
      }
      
			let url = new URL(value);

      if (!url.valid)
        return true;
			
      return url.host === 'soundcloud.com' || url.host === 'www.soundcloud.com' || url.host === 'mixcloud.com' || url.host === 'www.mixcloud.com'
		}, "A valid Soundcloud or Mixcloud URL is required (including https://)", 1, false)


        this.pristine = new Pristine(this.$refs.form, this.pristineConfig);

      // console.log("mounting", this.artisttags);

        if (!this.artisttags || this.artisttags.length == 0){
            this.getArtistTags().then(resp =>{
              this.artisttags = resp;
              this.filterTags();
            });
        }
        else
        {
          this.filterTags();
        }

      // console.log("mounting done", this.artisttags);

      //   console.log("artist id", this.$attrs.artistId);

        this.claim_id = this.$route.params.claim;

        let artist = null;

        if(this.$route.query.claim){




          this.claiming = true;

          this.claim_id = this.$route.query.claim;
          this.claimProfile(this.claim_id).then(($resp) => {

                if (typeof($resp.data.data) != "string")
                {

                  let $claimartist = $resp.data.data;

                  this.ClaimProfile = {
                    name: this.$auth.name,
                    artist_name: $claimartist.name,
                    telephone: $claimartist.telephone
                  };

                  artist = $claimartist;

                  this.claiming = false;
                  this.populateForm(artist);
                }
                else
                {
                    this.$refs.claim.message = $resp.data.data + ".";
                    this.$refs.claim.url = '/artists/add';
                    this.$refs.claim.urlText = "That's fine, create a new artist profile anyway.";
                }
          });

        }

        if (this.$attrs.artistId)
        {
            artist = this.artists.find((item) =>item.id === this.$attrs.artistId);

            this.populateForm(artist);
        }
    },
    computed: {
        ...apiComputed,
        ...authComputed
    },
    async created() {
      this.getArtistTags();
      this.getTiers();
      this.getArtists();
      this.getArtistDirectory();

    },
    methods: {
      changeAudio,
      filterTags(){
        this.genres = this.artisttags.filter(tag => tag.tag_type != 100);
        this.styles = this.artisttags.filter(tag => tag.tag_type == 100);
      },
        ...apiMethods,
        populateForm(artist){
            this.form = artist;
            // this.gallery = artist.gallery
            this.image = artist.image
            // this.form.image = null;
            // this.form.gallery = [];

            this.form.song_list = artist.song_list;
            this.$refs.searchTags.search_tiers = [artist.tier.id];
						// Find the selected genres from artist.artist_tags matching the tags with the already parsed this.genres
            this.$refs.searchTags.search_genres = artist.artist_tags.map(t => {
							const genre = this.genres.filter(g => g.id === t.id)[0]
							if(genre){
								return t.id;
							}
						}).filter(g => g)
						// Find the selected style from artist.artist_tags matching the tags with the already parsed this.styles
            this.$refs.searchTags.search_styles = artist.artist_tags.map(t => {
							const style = this.styles.filter(s => s.id === t.id)[0]
							if(style){
								return t.id;
							}
						}).filter(s => s)
            let hasSetAddress = (artist.billing_address != null && artist.address != null)
            let addressesMatch = false;

            addressesMatch = hasSetAddress && (artist.billing_address.address_line_1 + artist.billing_address.post_code == artist.billing_address.address_line_1 + artist.address.post_code);

            this.billing_same_as = !hasSetAddress || addressesMatch;


			if (!artist.videos){
               this.form.videos = ["", "", ""]
            }
            else{
              artist.videos.forEach(function(v, i) {

                    // console.log("video", {"v": v, "v is null string": v == "null", "v is null type": v == null});
                  if (v == "null")
                  {
                    artist.videos[i] = null;
                  }
              })
            }

			if (!artist.audio){
               this.form.audio = ["", "", ""]
            }
            else{
              artist.audio.forEach(function(a, i) {

                    // console.log("audio", {"a": a, "a is null string": a == "null", "a is null type": a == null});
                  if (a == "null")
                  {
                    artist.audio[i] = null;
                  }
              })
            }

            //this.form.videos = JSON.parse(artist.videos);


            // console.log("raw videos",  '{' + artist.videos + '}');
            // console.log("first videos", artist.videos[0]);

            if (artist.vat_number !== null) {
              this.show_vat_field = true
            }

            if (!artist.distance_limit)
            {
              this.form.distance_limit = 100;
            }
            if (!artist.address)
            {

                this.form.address = {
                    address_line_1: "",
                    address_line_2: "",
                    town: "",
                    county: "",
                    post_code: "",
                };

            }

            if (!artist.billing_address)
            {

                this.form.billing_address = {
                    address_line_1: "",
                    address_line_2: "",
                    town: "",
                    county: "",
                    post_code: "",
                };
            }

            this.billing_same_as = true
        },
        galleryChanged(images) {
          this.gallery = images
        },
        profilePictureUploaded(image) {
          this.image = image
        },
        addSong() {
          this.form.song_list = [...this.form.song_list, '']
        },
        deleteSong(songIndex) {
          this.form.song_list = this.form.song_list.filter((item, index) => index !== songIndex)
        },
        parseError(response){
 
          let data = response.data.errors;
          let error = "";
          Object.keys(data).forEach(function(key) {
            error += ' ' + data[key];
          });

          return error;
        },
        save(){
            this.submitting = true;
            const self = this;
            const artistId = this.form.id

            if (!this.$refs.searchTags.search_genres || !this.$refs.searchTags.search_tiers)
            {
                this.$refs.notification.createNotification('You must select at least one genre and one type', true);
                this.submitting = false;
                return;
            }

            var valid = this.pristine.validate();

            if (valid)
            {
                let tags = this.$refs.searchTags.search_genres;
								
                if (this.$refs.searchTags.search_styles)
                {
                  tags = tags.concat(this.$refs.searchTags.search_styles)
                }
					
                const payload = {
                  name: this.form.name,
                  email: this.form.email,
                  telephone: this.form.telephone,
                  description: this.form.description,
                  song_list: this.form.song_list,
                  videos: this.form.videos ? this.form.videos : [],
                  audio: this.form.audio ? this.form.audio : [],
                  distance_limit: this.form.distance_limit,
                  tags: tags,
                  sortcode: this.form.sortcode,
                  account_number: this.form.account_number,
                  utr_number: this.form.utr_number,
                  vat_number: this.form.vat_number,
                  account_name: this.form.account_name,
                  image: this.image !== null ? this.image.id : null,
                  tier_id: this.$refs.searchTags.search_tiers,
                  gallery: this.gallery.map(gallery => gallery.id),
                  address: this.form.address,
                  billing_same_as: this.billing_same_as,
                  billing_address: this.billing_same_as ? null : this.form.billing_address,
                }

                if (!artistId) {
                  this.createArtist(payload).then((response) => {
                      if (response && response.status === 201) {
                          self.$notify({
                              title: 'Now previewing profile page',
                              text: 'Profile created'
                          });

                          self.$router.push({ name: "ArtistProfileView", params: { id: response.data.data.id } });
                          this.submitting = false;
                      } else {

                        console.log(response);

                          self.$refs.notification.createNotification("An error occurred on our end whilst creating this artist:" + self.parseError(response), true);
                        console.error("error sending payload to server", JSON.stringify(payload));
                      }
                  });
                }
                else {
									
                  this.updateArtist({ artistId, artist: payload }).then(function(response){ 
                      if (response && response.status === 200) {
                          self.$notify({
                              title: 'Viewing profile page',
                              text: 'Profile updated'
                          });

                          self.$router.push({ name: "ArtistProfileView", params: { id: response.data.data.id } });
                          self.submitting = false;
                      } else {
                          self.$refs.notification.createNotification("An error occurred on our end whilst updating this artist:" + self.parseError(response), true);
                      }
                  }).catch(function(error){
                    console.log("error: ", error);
                  });
									
                }

            }
            else{
              self.submitting = false;

              this.$refs.notification.createNotification("Please check the form for ommissions", true);

            }

        },
        updateAudio(url, index){
            
            let morphedUrl = changeAudio(url);

            this.$refs["audio_" + index][0].src = morphedUrl;
        },
        // eslint-disable-next-line no-unused-vars
        changeVideo(url, index){
              switch(index){
                 case 0: {
                     this.$refs.video_0.src = url;
                    break;
                 }
                 case 1: {
                     this.$refs.video_1.src = url;
                     break;
                 }
                 case 2: {
                     this.$refs.video_2.src = url;
                     break;
                 }
              }

        }
    },
    data() {
		return {
			styles: [],
			genres: [],
			submitting: false,
			debug: false,
			claiming: false,
			appConfig,
      gallery: [],
      image: null,
			pristine: null,
			pristineConfig: {
				classTo: 'input-group',
				errorClass: 'has-error',
				successClass: 'has-success',
				errorTextParent: 'input-error',
				errorTextTag: 'span',
				errorTextClass: 'text-help'
			},
			claim_id: null,
			ClaimProfile: null,
			item: null,
      show_vat_field: false,
      billing_same_as: true,
			form: {
				search_terms: null,
				search_genres: null,
				search_tiers: null,
				distance_limit: "100",
				address: {
					address_line_1: "",
					address_line_2: "",
					town: "",
					city: "",
					post_code: "",
				},
				billing_address: {
					address_line_1: "",
					address_line_2: "",
					town: "",
					city: "",
					post_code: "",
				},
				videos: ["", "", ""],
				audio: ["", "", ""],
        vat_number: '',
        utr_number: '',
        song_list: [],
			}
		}
    }
}

</script>
