<template>

	<ModalWrapper ref="editVenueBillingsModal" :is-open="isOpen">
		<template #header>
			<div class="text-xl"> 
				Profile Billing Details
			</div>
			<p class="mt-2 text-sm">
				This information will be shown on your invoices from artists.
			</p>
		</template>

		<template #body>

			<form class="mb-24 form mt-0 gap-y-4 input-group" ref="form" novalidate> 
				<div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5 col-span-full">

					<div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <span>Billing address</span>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label
                for="venue-address-line1"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Line 1
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="venue-address-line1"
                  id="venue-address-line1"
                  v-model="form.billing_address.address_line_1"
                  class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  required
                />
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0">
              <label
                for="venue-address-line2"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Line 2
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="venue-address-line2"
                  id="venue-address-line2"
                  v-model="form.billing_address.address_line_2"
                  class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0">
              <label
                for="venue-address-town"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Town/City
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="venue-address-town"
                  id="venue-address-town"
                  v-model="form.billing_address.town"
                  class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  required
                />
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0">
              <label
                for="venue-address-county"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                County
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="venue-address-county"
                  id="venue-address-county"
                  v-model="form.billing_address.county"
                  class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0">
              <label
                for="venue-address-postcode"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Postcode
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="venue-address-postcode"
                  id="venue-address-postcode"
                  v-model="form.billing_address.post_code"
                  class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  required
                />
              </div>
            </div>
          </div>
			
				</div>
			</form>

		</template>

		<template #footer>

			<ModalFooterWrapper>

				<template #left>
					<Button @click.native="onClose" :disabled="isFormLoading" color="gray">Cancel</Button>
				</template>

				<template #right>
					<Button @click.native="onSave" :disabled="formDisabled" :loading="isFormLoading">{{ submitButtonLabel }}</Button>
				</template>

			</ModalFooterWrapper>
					
		</template>
	</ModalWrapper>

</template>
<script>

import ModalWrapper from '@/components/ui/modal/ModalWrapper.vue'
import ModalFooterWrapper from '@/components/ui/modal/ModalFooterWrapper.vue'
import Button from '@/components/ui/buttons/Button.vue'

export default {
  components: {
		ModalWrapper,
		ModalFooterWrapper,
		Button,
  },
  props: {
		identity:{
			type:String,
		},
		isOpen:{
			type:Boolean,
			required: true,
			default:false
		},
    venue: {
      type: Object,
      required: true,
    },
  },
	emits:["closed", "updated"],
  data() {
    return {
      form: {
        billing_address: {},
      },
    };
  },
  computed: {
		hasData(){
			if(this.venue.id){
				return true;
			}
			return false;
		},
		formDisabled() {
      return false
    },
		isFormLoading(){
			return false
		},
		submitButtonLabel(){
			return this.isFormLoading ? 'Applying' : 'Apply'
		}
  },
  mounted() {
    // this.form.billing_address = { ...this.venue.billing_address }
    const address = { ...this.venue.billing_address }
    this.form.billing_address = {
			address_line_1: address.address_line_1,
			address_line_2: address.address_line_2,
			town: address.town,
			county: address.county,
			post_code: address.post_code,
		};
  },
  methods: {
    onClose(){
			this.$emit('closed')
		},
		onSave(){
			this.$emit('updated', this.form)
		}
  },
};
</script>
