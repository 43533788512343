<template>
  <PageDetails>

    <InlineNotification Heading="Adding a new venue" class="ml-4">
        <template slot="body">
          <p>Click the “Add new venue” button and fill in the details to register your venue. Repeat this process if you have more than one site. All your venues will be managed under this account.</p>
        </template>
        <template slot="cta">
            <router-link to="/venues/add" class="gp_cta">Add new Venue</router-link>
        </template>
    </InlineNotification>

		<!-- <h1 class="text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">Your venues</h1>  -->

		<template v-if="isLoading">
			<div class="p-8 flex justify-center items-start">
				<PageSpinner 
					tailwind-diameter="8"
					tailwind-border="4"
					tailwind-color="pink-500"
				/>
			</div>
		</template>

		<template v-else-if="!isLoading && !hasData">
			<NoDataMsgWrapper>There are no venues found for this account</NoDataMsgWrapper>
		</template>

		<template v-else>
			<TableWrapper>
				<template #header-rows>
					<tr>
						<th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-6">Venues</th>
						<th scope="col" class="hidden px-3 py-3.5 lg:table-cell">
							<span class="sr-only">Upcoming gigs</span>
						</th>
						<th scope="col" class="relative w-8 px-3 py-3.5 ">
							<span class="sr-only">Edit</span>
						</th>
					</tr>
				</template>
				<template #body-rows v-if="venues">
					<tr v-for="venue in venues" :key="venue.id">
						<td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
							<div class="flex items-center gap-3">
								<img
									class="shadow self-start w-8 h-8 object-contain"
									:src="venueIcon(venue)"
									:alt="venue.name + ' logo'" 
								/>
								<span>{{ venue.name }}</span>
							</div>
							
							<dl class="font-normal lg:hidden">
								<dt class="sr-only sm:hidden">Upcoming gigs</dt>
								<dd class="mt-2 truncate text-gray-500 sm:hidden">
									<span>{{ getGigs(venue) }}</span>
								</dd>
							</dl>
						</td>
						<td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
							<span>{{ getGigs(venue) }}</span>
						</td>
						<td class="w-8 px-3 py-4 text-right text-sm font-medium sm:pr-6">
							<a 
								@click.prevent="onEditVenue(venue.id)" 
								class="text-indigo-600 hover:text-indigo-900"
							>
								<span class="sm:hidden"><PencilAltIcon /></span>
								<span class="hidden sm:block">
									Edit<span class="sr-only">, {{ venue.name }}</span>
								</span>
							</a>
						</td>
					</tr>
				</template>
			</TableWrapper>
		</template>

	</PageDetails>
</template>

<script>
import PageDetails from '@/components/ui/wrappers/PageDetails.vue'
import TableWrapper from '../../components/ui/table/TableWrapper.vue';
import PageSpinner from '../../components/ui/spinner/PageSpinner.vue';
import NoDataMsgWrapper from '../../components/ui/messages/NoData.vue';
import { PencilAltIcon } from "@vue-hero-icons/outline"
import pluralize from  '@/utils/pluralize.js'
import InlineNotification from '../../components/notifications/inline.vue'


export default {
  components: {
		PageDetails,
		TableWrapper,
		PageSpinner,
		NoDataMsgWrapper,
		PencilAltIcon,
		InlineNotification
  },
  data() {
    return {
			venues:null,
			isLoading:false,
    };
  },
  computed: {
		hasData(){
			if(this.venues){
				if(this.venues.length){
					return true;
				}
			}
			return false;
		},
		getGigs(){
			return (venue) => pluralize(venue.gig_count, 'upcoming gig');
		},
		venueIcon(){
			return (venue) => {
				if(venue.icon && venue.icon.url){
					return venue.icon.url
				}else if(venue.icon && venue.icon.preview){
					return venue.icon.preview
				}else{
					return require('@/assets/images/placeholder.svg')
				}
			};
			
		},
  },
  methods: {
		onEditVenue(venueId){
			this.$router.push({ name: "VenuesDetails", params: { venueId: venueId } });
		}
  },
	mounted(){
		this.isLoading = true;
		Promise.all([
			// Venues
			this.$store.dispatch('venue/getAllVenues')
			.then((response) => {
				this.venues = response
			}),
		]).finally(() => {
			this.isLoading = false;
		});
		
	}
};
</script>
