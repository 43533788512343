<template>
    <div>         


        <InlineNotification Heading="New available gigs" class="ml-4" >
            <template slot="body">
            <p>New gigs? Take your pick.</p>
            <p>Click ‘view’ on each box for all the details.<br/>Hit the ‘thumbs up’ button if you're available for the gig.
            </p>
            <p>You’ll then find those gigs in the ‘Availability given’ tab.</p> 
            <p>If the gig’s not for you, hit the ‘thumbs down’ button instead. </p> 
            <p class="text-red-500 uppercase">Remember - The gig is only booked when you get a booking confirmation.  All confirmed bookings will be in the 'booked gigs' tab (and your calendar too).</p>            

            </template> 
        </InlineNotification> 
 



        <OfferByDate noItemsText="No new gig offers just yet." ref="offers"  :unfilteredBids="this.artistbids.filter(ab => ab.awaiting_artist_bid && !ab.declined && !ab.cancelled && !ab.gig.cancelled && ab.gig.status != 'CONFIRMED')">

        </OfferByDate>
 
    </div>
</template>


<script type="text/javascript">

import { 
} from 'vue-tailwind/dist/components'
import OfferByDate from '../../../components/gigs/OfferByDate.vue';
import InlineNotification from '../../../components/notifications/inline.vue';
import { apiComputed } from '@/state/helpers.js';
export default {
  name: 'ArtistBidIndex',
  components:
  {
    InlineNotification,
    OfferByDate
},
  data() { 
      return {
      }
  },
  computed: {
    ...apiComputed
  },  
  methods: { 
    filter($items, index){
      console.log("?", {$items, "index" : index});
      this.filteredBids = $items;
      this.selectedIndex = index;
      this.$refs.offers.items = $items; 
    },
  }
};

</script>