<template>
		<GigPageWrapper
			:gig-id="String(gigId)" 
			current-route="repeat"
			title="Repeat"
		>
			<InlineNotification Heading="Offer the gig multiple times" class="p-0 m-0">
					<template slot="body">
						<p>Do you book the same gig on a regular basis? From here you can repeat the gig. Offer the same artists the same gig over multiple dates. Review your dates before you publish the additional gigs.</p>
					</template> 
			</InlineNotification> 

			<template v-if="!isLoading && gig">
				<!-- Stage 1 -->
				<div v-if="!stagetwo">
					<SelectRepeatDates
						:gig="gig"
						:frequency-label="getFrequency"
						@update="onRepeatDataUpdate"
						@confirm="onRepeatDatesGenerated"
					/>
				</div>
				<!-- Stage 2 -->
				<div v-else>
					<ConfirmRepeatDates 
						:repeat-dates="repeatData.repeats"
						:frequency="repeatData.frequency"
						:frequency-label="getFrequency"
						:is-multiple="repeatData.isMultiple"
						:start-date="repeatData.selectedStart"
						@cancel="cancelRepeats"
						@confirm="showConfirmation"
					/>
				</div>
			</template>

			<!-- Modals -->
			<template v-if="gig">
				<RepeatConfirmModal 
					:is-open="showRepeatConfirmation"
					:gig-id="gigId"
					:dates="checkedDates"
					@closed="onModalClosed"
					@confirmed="onModalConfirmed"
					@error="onModalError"
				/>
			</template>

			<NotificationSmall ref="notification" />

		</GigPageWrapper>
</template>

<script> 

import NotificationSmall from '@/components/notifications/small.vue'; 
import GigPageWrapper from '@/components/gigs/GigPageWrapper.vue';  
import InlineNotification from '@/components/notifications/inline.vue';
import RepeatConfirmModal from '@/components/modal/gigs/RepeatConfirm.vue'; 
import SelectRepeatDates from '@/components/gigs/repeat/SelectRepeatDates';  
import ConfirmRepeatDates from '@/components/gigs/repeat/ConfirmRepeatDates.vue';  

export default {
    name: "GigRepeat",
    components:{
			GigPageWrapper,
			SelectRepeatDates,
			ConfirmRepeatDates,
			NotificationSmall,
			InlineNotification,
			RepeatConfirmModal
		}, 
		props:['gigId'],
    data (){
			return {  
				stagetwo: false,        
				checkedDates: [],
				showRepeatConfirmation: false,
				repeatData:{
					recurrence: 0,
					frequency: 0,
					isMultiple:false,
					repeats:[],
					selectedStart: Date.now()
				},
				gig:null,
				isLoading:false
			}
    }, 
		computed: { 
			getFrequency: function(){
        if (this.repeatData.recurrence == 2)
            return 'day';
        else if (this.repeatData.recurrence == 3)
            return 'week';
        else if (this.repeatData.recurrence == 4)
            return 'fortnight';
        else if (this.repeatData.recurrence == 5)
            return 'month'
				else if (this.repeatData.recurrence == 6)
            return 'date'
        else return 'day'
			},
			
    },
		
    methods:{
				onRepeatDataUpdate(repeatData){
					this.repeatData = { ...repeatData }
				},
        showConfirmation(data){
					this.checkedDates = [ ...data.confirmedDates ]
					if (this.checkedDates.length > 0){
						this.showRepeatConfirmation = true;
					}
        },
				onModalClosed(){
					this.checkedDates = []
					this.showRepeatConfirmation = false;
				},
				onModalConfirmed(){
					this.$refs.notification.createNotification(`This gig has been successfully repeated`, false);
					this.onModalClosed();
					this.cancelRepeats();
				},
				onModalError(){
					this.$refs.notification.createNotification(`Sorry, we couldnt repeat this gig. Please try again later`, true);
					this.onModalClosed();
				},
				cancelRepeats(){
					this.repeatData.recurrence = 0;
					this.repeatData.frequency = 0;
					this.repeatData.isMultiple = false;
					this.repeatData.repeats = [];
					this.repeatData.selectedStart = Date.now();
					//
					this.stagetwo = false;
				},
				onRepeatDatesGenerated(repeatData){
					this.onRepeatDataUpdate(repeatData)
					this.stagetwo = true;
				}
    },
		mounted(){
			if (this.gigId)
			{
				this.isLoading = true;
				this.$store.dispatch('gig/getCurrentGig', this.gigId)
				.then((response) => {
					this.gig = response;
					this.isLoading = false;
				})
			}
		}
}
</script>