import client from "../../utils/client"
import { getStatusFromType } from '../models/gigStatus'


export const state = {
  gig: null,
	gigStatus: null,
}

export const mutations = {
  SET_GIG(state, gig) {
    state.gig = gig
  },
	SET_GIG_STATUS(state, gigStatus) {
    state.gigStatus = gigStatus
  }
}

export const getters = {
	currentGig: state => {
    return state.gig;
  },
	currentGigStatus: state => {
    return state.gigStatus;
  },
	isCurrentGigCancelled: state => {
		return !state.gigStatus ? false : state.gigStatus.type === 'CANCELLED';
	},
	isCurrentGigPending: state => {
		return !state.gigStatus ? false : state.gigStatus.type === 'PENDING';
	},
	isCurrentGigBooked: state => {
		return !state.gigStatus ? false : state.gigStatus.type === 'CONFIRMED';
	},
	doesCurrentGigNeedBooking: state => {
		if(!state.gigStatus){
			return false;
		}
		switch(state.gigStatus.type){
			case 'PENDING':
			case 'NEEDS_RESPONSE':
			case 'NO_ARTISTS':
			case 'UNPUBLISHED':
			case 'UNCAUGHT_STATUS':
				return true;
			default:
				return false;
		}
	},
}

export const actions = {
	storeGig({ commit }, gig){
		commit('SET_GIG', gig);
		commit('SET_GIG_STATUS', getStatusFromType(gig.status));
	},
  getCurrentGig({ getters, dispatch }, gigId) {
		return new Promise((resolve) => { 
				dispatch('getGigDetails', gigId)
				.then(() => {
					resolve(getters.currentGig);
				}).catch(err => {
					console.log(err)
				}); 
		})
  },
	getGigDetails({ getters, dispatch }, gigId){//commit, 
		return new Promise((resolve) => {
			client
					.get(`/gigs/${gigId}?include=bids,stagedArtists,artistTags,gigTiers,gigSearchTiers,gigTiers,bids,bookings,venue`)
					.then((response) => {
						// commit('SET_GIG', response.data.data);
						dispatch('storeGig', response.data.data)
						resolve(getters.currentGig)
					})
					.catch(err => {
						console.log(err)
					});
		})
	},
	getGigsByRange($, range){
    return new Promise((resolve) => {
			client
				.get(`/gigs?filter[starts_between]=${range.from},${range.to}&include=venue,bookings`)
				.then(response => {
					resolve(response.data.data)
				})
				.catch(err => {
					console.log(err)
				});
		});
	},
} 