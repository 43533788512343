<template>
  <div class="lg:ml-4">
    <InlineNotification Heading="Invoices">
      <template slot="body">
        <p>
          View and download all past invoices. Download them in PDF format, or export them
          to a CSV file.
        </p>
      </template>
    </InlineNotification>

    <div class="max-w-4xl">
      <Accordian heading="Filter" class="lg:mx-0 mx-4">
        <template #contents>
          <div class="my-4 grid grid-flow-row grid-cols-2 pt-0 mt-2 gap-4 pr-0 form">
            <div class="col-span-2">
              <label>Venue</label>
              <TRichSelect
                class="trichselect thin"
                required
                id="venue-selection"
                ref="venuepicker"
                name="venue-selection"
                placeholder="Filter by venue"
                :options="venues"
                v-model="selectedVenue"
                value-attribute="id"
                text-attribute="name"
              />
            </div>
            <div class="hidden">
              <label> Status </label>
              <TSelect
                v-model="invoiceStatus"
                class="trichselect"
                :options="[
                  { value: 'all', text: 'All' },
                  { value: 'paid', text: 'Paid' },
                  { value: 'unpaid', text: 'Unpaid' },
                ]"
              ></TSelect>
            </div>
            <div>
              <label>Raised From</label>
              <TDatePicker v-model="raisedFrom"></TDatePicker>
            </div>
            <div>
              <label>Raised To</label>
              <TDatePicker v-model="raisedTo"></TDatePicker>
            </div>
            <InputText
              name="invoicenumber"
              v-model="invoiceNumber"
              ref="invoicenumber"
              class="mt-0"
              type="number"
              prefix="GP-"
              label="Invoice number"
              placeholder="00001"
            />

            <div class="col-span-2 justify-start text-left">
              <a class="inline-block cta bg-indigo-500 mr-4" @click="filter()">Filter</a>
            </div>
          </div>
        </template>
      </Accordian>
      <div class="flex justify-end"></div>
    </div>
    <div class="lg:mx-0 mx-4 flex flex-col lg:flex-row">
      <a
        class="lg:mr-4 inline-block cta bg-indigo-500 mt-4"
        @click="downloadInvoices(true)"
        >Download selected invoices ({{ selectedInvoices.length }})</a
      >
      <a
        class="inline-block cta bg-gp_pink-default-500 mt-4"
        @click="downloadInvoices(false)"
        >Download all invoices</a
      >
    </div>
    <div>
      <div class="lg:mx-0 mx-4 flex flex-col lg:flex-row">
        <a class="lg:mr-4 inline-block cta bg-indigo-500 mt-4" @click="exportCSV()"
          >Export to CSV ({{ selectedInvoices.length }})</a
        >
      </div>
    </div>
    
    <div  class="lg:mx-0 mx-4 flex flex-col lg:flex-row max-w-5xl justify-between items-center  mt-4">
      <div class="flex flex-row my-4">
        <t-toggle name="archived_invoice" v-model="excludeArchived" class="mr-4"/>
        <label>Exclude Archived Invoices</label>
      </div>
      <div class="flex items-center">
        <span class="mr-4">Sort by</span>
       <div>
         <TSelect class="trichselect thin max-w-sm" v-model="invoice_sortby" @change="updateSort()"
                  :options="[
                  { value: '0', text: 'By gig date (newest to oldest)' },
                  { value: '1', text: 'By gig date (oldest to newest)' },
                  { value: '2', text: 'By sign-off date (newest to oldest)' },
                  { value: '3', text: 'By sign-off date (oldest to newest)' },
                  { value: '4', text: 'By venue (A-Z)' },
                  { value: '5', text: 'By venue (Z-A)' },
                  { value: '6', text: 'By artist (A-Z)' },
                  { value: '7', text: 'By artist (Z-A)' },
                ]">
         </TSelect>
       </div>
      </div>
    </div>
    <div class="flex flex-col mt-4" v-if="results && results.length > 0">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="max-w-5xl shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200 text-left">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="relative px-6 py-3">
                    <input ref="selectall" type="checkbox" @change="selectAll($event.target.checked)" />
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Artist
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Venue
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  class="relative"
                  v-for="(bid, index) in results"
                  :key="index"
                  :row="bid"
                >
                  <td class="p-6">
                    <input
                      ref="check"
                      v-bind:id="bid.id"
                      type="checkbox"
                      @change="toggleOffer($event.target.checked, bid)"
                    />
                  </td>
                  <td class="p-6">
                    <router-link :to="'/gigs/' + bid.gig.id + '/invoices'" class="block"
                      >View bookings</router-link
                    >
										<a 
											class="text-gp_pink-default-500"
											@click="editBidFee(bid)"
										>Amend fee</a>
                    <a
                      @click="downloadInvoice(bid)"
                      class="block mt-2 text-gp_pink-default-500"
                      >Download</a
                    >
                    <a
                        @click="archiveInvoiceLocal(bid)"
                        class="block mt-2 text-gp_pink-default-500"
                        v-if="bid.invoice.archived_at == null"
                        >Click to archive</a
                    >
                  </td>
                  <td class="text-left mx-2 p-6 justify-center h-full items-center">
                    <span class="block">{{ getArtistsName(bid) }}</span>
                    <span>£{{ bid.fee }}</span>
                  </td>
                  <td class="p-6">
                    <strong>{{ bid.gig.venue.name }}</strong>
                    <span class="block">{{ bid.gig.name }}</span>
                    <span class="block">{{ fullDate(bid.gig.start) }}</span>
                  </td>
                  <td class="p-6">
                    <span class="block"
                      >Artist signed off by {{ bid.signed_off_by.name }}<br />on
                      {{ bid.signed_off_at }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-4" v-else-if="invoices && results.length == 0">
      <p>There are no invoices to display, please refine your filters.</p>
    </div>
    <div class="pt-4" v-else>
      <p>Loading your invoices.</p>
    </div>
    <InvoiceGeneration ref="invoice" :invoiceItems="invoiceItems" />

		<template v-if="selectedBid">
			<AmendBidFee 
				:is-open="showAmendBidFeeModal"
				:bid="selectedBid"
				@closed="cancelModal"
				@updated="onBidFeeUpdated"
			/>
		</template>

  </div>
</template>

<style type="text/css" scoped>
table {
  table-layout: auto;
  width: 1000px;
}

table td {
  min-width: min-content;
}
</style>

<script type="text/javascript">


import { apiComputed, apiMethods } from '@/state/helpers.js'
import fullDate from  '@/utils/format-date-full.js'
import timeAgo from  '@/utils/format-date-timeago.js'
import InlineNotification from '../../components/notifications/inline.vue';
import { TRichSelect, TSelect } from 'vue-tailwind/dist/components';
import InputText from '../../components/form/InputText.vue';
import TDatePicker from 'vue-tailwind/dist/t-datepicker';
import Accordian from '../../components/accordian/Accordian.vue';
import InvoiceGeneration from '../../components/invoice/wrapper.vue';
import NProgress from 'nprogress'
import AmendBidFee from '../../components/modal/gigs/AmendBidFee.vue'
import {
  TToggle
} from 'vue-tailwind/dist/components';

const SORT_KEY = "venue_invoice_sort";

export default {
    name: "Invoices",
  components:
  {
    InvoiceGeneration,
    InlineNotification,
    TRichSelect,
    InputText,
    TDatePicker,
    TSelect,
    Accordian,
		AmendBidFee,
    TToggle
},
  data() {
    return {
       invoice_sortby: "0",
       excludeArchived: false,
       selectedVenue: null,
       invoiceNumber: '',
       invoiceStatus: 'all',
       raisedFrom: null,
       raisedTo: null,
       selectedInvoices: [],
       invoiceItems: [],
       results: [],
       invoices: null,
       venues: null,
			showAmendBidFeeModal: false,
			selectedBid:null,
    }
  },
  computed: {
    ...apiComputed,
		getArtistsName(){
			return (gig) => gig && gig.artist ? gig.artist.name : "no artists details"
		},
  },
  mounted(){


    let localSort = localStorage.getItem(SORT_KEY);

    if (localSort){
      this.invoice_sortby = localSort;
    }


      NProgress.start();
    this.getInvoices().then((resp) => {
      this.invoices = resp;
    }).finally(() => {
      this.getResults();
    })

       this.getVenues().then((resp) => {
      this.venues = resp;
    });

  },
  methods: {
    exportCSV(){
      this.bookingExport(this.selectedInvoices.map(m => m.id));
    },
    selectAll($checked){
        this.selectedInvoices = [];
        this.$refs.check.forEach((c) => {
          c.checked = $checked;

          let $bid = this.invoices.find(bid => bid.id == c.id);

          this.toggleOffer($checked, $bid);
        });
    },
    async archiveInvoiceLocal(bid) {
      NProgress.start();
      try {
        const {data} = await this.archiveInvoice(bid);
        this.updateInvoice(data, bid)
        this.$notify('Invoice archived');
      } catch (err) {
        this.$notify('Error archiving invoice');
      } finally {
        NProgress.done();
      }
    },
    updateInvoice(data, bid) {
      this.results = this.results.map((item) => {
        if (item.id === bid.id) {
          item.invoice.archived_at = data.data.invoice.archived_at;
        }
        return item;
      });
      if (this.excludeArchived) {
        this.results = this.results.filter(b => b.id !== bid.id);
      }
    },
    toggleOffer: function(isOn, $bid){

      if (isOn)
      {
        this.selectedInvoices.push($bid);
      }
      else
      {
        let removeIndex = this.selectedInvoices.indexOf($bid);
        if (removeIndex !== -1){
          this.selectedInvoices.splice(removeIndex, 1);
        }
      }
    },
    downloadInvoice($bid){

      this.invoiceItems = [$bid];
      this.$refs.invoice.generate();
    },
    downloadInvoices($only_selected){

      if ($only_selected)
      {
        this.invoiceItems = this.selectedInvoices;
      }
      else{
        this.invoiceItems = this.getResults();
      }

      if (this.invoiceItems.length > 0)
        this.$refs.invoice.generate();
    },
    filter(){

      let check = this.$refs.check;
      if (check) {
        check.forEach((c) => {
          c.checked = false;
        });
      }
      let selectAll = this.$refs.selectAll;
      if (selectAll) {
        selectAll.checked = false;
      }
      this.selectedInvoices = [];
      this.getResults(); 
    },
    ...apiMethods,
    fullDate,
    timeAgo,
    updateSort(){

      NProgress.start();

      localStorage.setItem(SORT_KEY, this.invoice_sortby);

      this.getResults()
    },
    getResults() {
      if (this.invoices != null){
        this.excludeArchived = false;
        let $results = this.invoices;

        let start = new Date('2001-01-01');
        let end = new Date('2050-01-01');

        if (this.selectedVenue){

          let venue_id = this.selectedVenue;
          $results = $results.filter(g => g.gig.venue.id == venue_id);
        }

        if (this.raisedFrom){

            start = this.raisedFrom;

            $results = $results
            .filter(g =>
              new Date(g.invoice.date_raised) > start
            );

        }

        if (this.raisedTo){

            end = this.raisedTo;

            $results = $results
            .filter(g =>
              new Date(g.invoice.date_raised) > end
            );

        }

        if (this.invoiceStatus == "unpaid"){
            $results = $results
            .filter(g =>
              g.invoice_date_marked_paid == null
            );
        }

        if (this.invoiceStatus == "paid"){
            $results = $results
            .filter(g =>
              g.invoice_date_marked_paid != null
            );
        }

        if (this.invoiceNumber){

          let invoiceNumber = this.invoiceNumber
            $results = $results
            .filter(g =>
              g.invoice.invoice_number == invoiceNumber
            );
        }

        if (this.invoice_sortby == "0" || this.invoice_sortby == "1")
        {
          $results = $results.sort(function(a, b)
          {
            return new Date(b.gig.start) -  new Date(a.gig.start)
          });

          if (this.invoice_sortby == "1"){
            $results = $results.reverse();
          }
        }

        
        if (this.invoice_sortby == "2" || this.invoice_sortby == "3")
        {
          $results = $results.sort(function(a, b)
          {
            return new Date(b.signed_off_at) -  new Date(a.signed_off_at)
          });

          if (this.invoice_sortby == "3"){
            $results = $results.reverse();
          }
        }

                
        if (this.invoice_sortby == "4" || this.invoice_sortby == "5")
        {
          $results = $results.sort(function(a, b)
          {
            
            return a.gig.venue.name.localeCompare(b.gig.venue.name);
          });

          if (this.invoice_sortby == "5"){
            $results = $results.reverse();
          }
        }

                
        if (this.invoice_sortby == "6" || this.invoice_sortby == "7")
        {
          $results = $results.sort(function(a, b)
          {
            return a.artist.name.localeCompare((b.artist.name));
          });

          if (this.invoice_sortby == "7"){
            $results = $results.reverse();
          }
        }



        this.results = $results;
        NProgress.done();
        return $results;

      }


      //return rows
    },
		cancelModal(){
			this.showAmendBidFeeModal = false;
		},
		editBidFee($bid){
			this.selectedBid = $bid;
			this.showAmendBidFeeModal = true;
		},
		onBidFeeUpdated($bid){
			this.selectedBid = $bid;
			this.cancelModal();
			const selectedBidIndex = this.results.findIndex(r => r.id === this.selectedBid.id);
			if(selectedBidIndex >= 0){
				this.results[selectedBidIndex].fee = this.selectedBid.fee
			}
		},
  },
  watch: {
    excludeArchived(val) {
      if (val) {
        this.results = this.results.filter(itmes => itmes.invoice.archived_at == null);
      } else {
        this.results = this.invoices;
      }
    }
  },
};
</script>
