<template>
    <div @click="$router.push(to)" class="rounded-md cursor-pointer p-4 relative" :class="'bg-' + color_class + '-50 ' + 'hover:bg-' + color_class + '-100'" >
    <div class="flex">
        <div class="flex-shrink-0">
                <slot name="icon">

                </slot> 
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
        <p class="text-sm" :class="'text-' + color_class + '700'">{{text}}.</p>
        <p class=" text-sm items-start justify-start lg:ml-8">
            <a :class="'text-' + color_class + '700 hover:text-' + color_class + '600'" class="whitespace-nowrap font-medium">
                Details  <span aria-hidden="true">&rarr;</span>
            </a>
        </p>
        </div>
    </div>
 
    </div>
</template>

<script type="text/javascript">

    export default {
        name: "AlertWithLink",
        props: {
            text: String,
            to: String,
            color_class: String
        }
    }

</script>