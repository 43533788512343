<template>
	<div 
		class="text-center items-center px-1 sm:px-3 py-0.5 rounded-lg sm:rounded-full text-xs sm:text-sm font-medium" 
		:class="status.class"
	>
		{{ status.name }}
	</div>
</template>

<script>
export default {
	name: "StatusBadge",
	props:{
		status: Object
	}
}
</script>