<template>
	<TabsWrapper :tabs="tabs">

		<template v-slot:right-cta>
			<Button v-if="gig" @click.native="onMessagesSelect">Messages</Button>
		</template>

	</TabsWrapper>
</template>

<script>
import TabsWrapper from '../ui/tabs/TabsWrapper.vue';
import Button from '../ui/buttons/Button.vue';

export default {
    name: "GigTabs",
		components: {
			TabsWrapper,
			Button
		},
    props: 
    {
			currentRoute:{
				type:String,
				required: true,
			},
			gigId:{
				type:String,
			}
    },
		data(){
			return {
				tabs:this.setTabsData(this.gigId, this.currentRoute),
			}
		},
		computed:{
			gig(){
				return this.$store.getters['gig/currentGig']
			},
			getOfferCount(){
				// legacy...
				// this.gig.bids && this.gig.bookings_count == 0 ? this.gig.bids_count : 0
				return this.gig.bids ? this.gig.bids.filter(gb => !gb.accepted).length : 0
			}
		},
		watch: {
			gig() {
				this.tabs = this.setTabsData(this.gigId, this.currentRoute)
			},
    },
		methods:{
			setTabsData(gigId, currentRoute){
				const tabs = [{ 
						title:"Details", 
						route:this.gig ? `/gigs/${gigId}` : '/gigs/add', 
						current:currentRoute === 'index' || currentRoute === 'details'
				}]
				if(this.gig){
					tabs.push({ 
						title:"Artist instructions", 
						route:`/gigs/${gigId}/instructions`, 
						current:currentRoute === 'instructions'
					});
					tabs.push({ 
						title:"Choose artists", 
						route:`/gigs/${gigId}/choose-artists`, 
						current:currentRoute === 'choose-artists'
					});
					tabs.push({ 
						title:"Repeat gig", 
						route:`/gigs/${gigId}/repeat`, 
						current:currentRoute === 'repeat'
					});
					if(this.gig.published){
						// TODO - do not use bookings_count here, instead go on status?
						tabs.push({ 
							title:"Offers", 
							route:`/gigs/${gigId}/offers`, 
							current:currentRoute === 'offers',
							notifications:this.getOfferCount
						});
					}
					if(this.gig.bookings_count > 0){
						tabs.push({ 
							title:"Bookings", 
							route:`/gigs/${gigId}/bookings`, 
							current:currentRoute === 'bookings',
							notifications:this.gig.bookings ? this.gig.bookings_count : 0
						});
						if(this.gig.is_past){
							tabs.push({ 
								title:"Invoices", 
								route:`/gigs/${gigId}/invoices`, 
								current:currentRoute === 'invoices',
							});
						}
					}
					tabs.push({ 
						title:"Notes", 
						route:`/gigs/${gigId}/notes`, 
						current:currentRoute === 'notes',
					});
					
				}
				return tabs
			},
			onMessagesSelect(){
				this.$router.push('/messages/gig/' + this.gigId); 
			}
		},
		mounted(){
			this.tabs = this.setTabsData(this.gigId, this.currentRoute);
		}
}
</script>
