<template>
	<div class="w-full col-span-full" v-if="gigTiers && gigTiers.length">
		<div>
			<p class="col-span-full py-4 text-base "> If you know the type of artist(s) you will be booking <span v-show="isTemplate">using this template,</span> add the fee in the correct box below</p> 
		</div>

		<div class="grid col-span-full grid-cols-2 md:grid-cols-6 gap-4">
			<div class="flex flex-col justify-end" v-for="gigTier in gigTiers" :key="gigTier.tier.id">
				<input-text 
					:id="'tier_' + gigTier.id" 
					v-model="gigTier.requested_fee"  
					:ref="'tier_' + gigTier.id"  
					:hint="'Please enter a required fee for ' + gigTier.tier.name"
					:error="'The tier price for ' + gigTier.tier.name + ' must be completed'"  
					placeholder="0.00" 
					prefix="£" 
					name="internal_desc"
					:label="gigTier.tier.name"
					v-on:input="onInput"
				/>
			</div> 
		</div>


	</div>

</template>

<script type="text/javascript">

import InputText from '../form/InputText.vue';

export default {
	name: "Fees",
	components: {
			InputText
	},
	props:{
		gigTiers:{
			type:Array,
			required: false,
			default:() => []
		},
		isTemplate:{
			type:Boolean,
			default:false
		},
	},
	methods:{
		onInput(){
			this.$emit('updated')
		}
	}
}

</script>