<template>
    <div class="relative  flex-shrink-0 flex h-16 bg-white shadow "  v-click-outside="closeMenu">
      <button @click="toggleMobile" type="button" class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden">
        <span class="sr-only">Open sidebar</span>
        <!-- Heroicon name: outline/menu-alt-2 -->
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" />
        </svg>
      </button>
      <div class="flex-1 px-4 flex justify-between">
        <div class="flex-1 flex">
          <form class=" hidden w-full flex md:ml-0" action="#" method="GET">
            <label for="search-field" class="sr-only">Search</label>
            <div class="relative w-full text-gray-400 focus-within:text-gray-600">
              <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                <!-- Heroicon name: solid/search -->
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                </svg>
              </div>
              <input id="search-field" class="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm" placeholder="Search" type="search" name="search">
            </div>
          </form>
        </div>
        <div class="ml-4 flex items-center md:ml-6">
          <button  @click="toggle()" type="button" class="bg-white hidden p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">View notifications</span>
            <!-- Heroicon name: outline/bell -->
<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
</svg>
          </button>

          <!-- Profile dropdown -->
          <div class="ml-3 relative">
            <div>
              <button type="button" class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                <span class="sr-only">Open user menu</span>
                <img  v-if="$auth.loggedIn && $auth.user" class="h-8 w-8 rounded-full" v-bind:src="$auth.user.picture" alt="">
              </button>
            </div>

            <!--
              Dropdown menu, show/hide based on menu state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div hidden  :class="{ 'active' : isNotificationMenuOpen, 'hidden' : !isNotificationMenuOpen }"
                    @click.prevent="toggle"
          class="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
              <!-- Active: "bg-gray-100", Not Active: "" -->
               
              <router-link title="coming soon" v-if="$auth.isAuthenticated" to="#" class="cursor-not-allowed block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-0"></router-link>

              <a href="#"  title="coming soon" class="block px-4 py-2 text-sm text-gray-700 cursor-not-allowed" role="menuitem" tabindex="-1" id="user-menu-item-1">Settings</a>

              <a  v-if="$auth.isAuthenticated" @click="$auth.logout()"  class="block px-4 py-2 text-sm text-gray-700 hovee:font-bold" role="menuitem" tabindex="-1" id="user-menu-item-2">Sign out</a>
                
              <a v-else @click="login()" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-2">Log In / Register</a>
            </div>
            
          </div>
          <span v-if="$auth && $auth.user && $auth.user.name" class="mr-4 text-sm">Logged in as {{$auth.user.name}}</span>
                <img  v-if="$auth && $auth.user && $auth.user.picture" class="h-8 w-8 rounded-full" v-bind:src="$auth.user.picture" alt="">
              <a  v-if="$auth.isAuthenticated" @click="$auth.logout()"  class="block px-4 py-2 text-sm text-gray-700 hovee:font-bold" role="menuitem" tabindex="-1" id="user-menu-item-2">Sign out</a>
        </div>
      </div>
    </div>
</template>

<script>

import {layoutMethods} from '@/state/modules/layout.js'
export default {
    name: "NavHeader",
    components:{ 
    },
    data: () => ({
        isNotificationMenuOpen: false 
    }),
     methods: {
       ...layoutMethods,
        toggleMobile()
        {  
          console.log("opened");
          this.setToggleMobileMenu(true);
        },
        toggle() {
            this.isNotificationMenuOpen = !this.isNotificationMenuOpen
        },
        closeMenu() {
            this.isNotificationMenuOpen = false;
        }
    }
}
</script>