<template>
    <div class="mt-1 input-group" v-bind:class="classes">
        <div class="input-content">   
            <label v-bind:for="name" class="block text-sm font-medium text-gray-700">
                {{label}}
            </label>    

 <div class="mt-1 relative rounded-md shadow-sm">
    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
      <span class="text-gray-500 sm:text-sm bg-gray-100 rounded-md py-1 px-2" v-if="prefix">
          {{prefix}}
      </span>
    </div>

            <input v-bind="$attrs" class="w-full" :class="(prefix ? 'pl-16' : '') + ' ' + (suffix ? 'pr-16' : '')"
            v-bind:id="name" 
            v-on:input="handler"
            v-bind:value="value"
            v-bind:data-pristine-pattern-message="hint"   v-bind:data-pristine-url-message="hint"
            v-bind:data-pristine-required-message="error" :type="inputtype" v-bind:pattern="pattern" />  


    <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
      <span class="text-gray-500 sm:text-sm bg-gray-100 rounded-md py-1 px-2" v-if="suffix">
          {{suffix}}
      </span>
    </div>


 </div>
            <div class="input-error">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "input-text",
    inheritAttrs: true,
    computed:{
        inputtype(){
            if (!this.type)
            return "text";
            else return this.type;
        }

    }, 
    data(){
        return {
        }
    },
    props: {
        url:{
            type: Boolean
        },
            pattern:{
                type: String
            },
            type:{
                type: String
            },
            prefix: {
                type:String,
                required:false
            },
            suffix: {
                type:String,
                required:false
            },
			name: {
				type: String,
				required: true
			}, 
			label: {
				type: String,
				required: false
			},
			value: {
				type: String,
				required: false
			},
			classes: {
				type: String,
				required: false,
			},
			error: {
				type: String,
				required: false,
			},
			hint: {
				type: String,
				required: false,
			},
		},
        methods: {
        set($value){
            this.value = $value; 
        },
        handler(event) {
            this.$emit('input',
                event.target.value
            )
        }
    }
}
</script>