<template>

<ModalWrapper ref="editVenueIdentityModal" :is-open="isOpen">
		<template #header>
			<div class="text-xl"> 
				Profile
			</div>
			<p class="mt-2 text-sm">
				This information will be shown on your venue profile page to potential artists.
			</p>
		</template>

		<template #body>

			<form class="mb-24 form mt-0 gap-y-4 input-group" ref="form" novalidate> 
				<div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5 col-span-full">
            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="venue-name"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Venue name
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="venue-name"
                  id="venue-name"
                  
                  
                  v-model="form.name"
                  class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="about"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                About
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <textarea
                  id="about"
                  name="about"
                  rows="3"
                  v-model="form.about"
                  class="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                ></textarea>
                <p class="mt-2 text-sm text-gray-500">
                  Write a few sentences about your venue.
                </p>
              </div>
            </div>
            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="country"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Tags
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <select
                  id="country"
                  name="country"
                  v-model="form.venueTags"
                  autocomplete="country"
                  multiple
                  class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  <option :value=null selected disabled>-- Select one or more tags --</option>
                  <option
                    v-for="tag in venueTags"
                    :key="tag.id"
                    :value="tag.id"
                  >
                    {{ tag.name }}
                  </option>
                </select>
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="country"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Region
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <select
                  id="country"
                  name="country"
                  v-model="form.region"
                  autocomplete="country"
                  class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  <option :value=null selected disabled>-- Select a region --</option>
                  <option
                    v-for="region in regions"
                    :key="region.id"
                    :value="region.id"
                  >
                    {{ region.name }}
                  </option>
                </select>
              </div>
            </div>

						<div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5"
            >
							<profile-picture-upload 
								:image="form.icon" 
								@onImageChange="profilePictureUploaded" 
								profile-type="VenueProfilePicture"
							/>
						</div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="cover-photo"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Gallery
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
								<upload-gallery 
									:images="form.gallery"
									gallery-type="VenueGallery"
									@onGalleryChange="onGalleryChanged" 
								/>
              </div>
            </div>
          </div>

			</form>

		</template>

		<template #footer>

			<ModalFooterWrapper>

				<template #left>
					<Button @click.native="onClose" :disabled="isFormLoading" color="gray">Cancel</Button>
				</template>

				<template #right>
					<Button @click.native="onSave" :disabled="formDisabled" :loading="isFormLoading">{{ submitButtonLabel }}</Button>
				</template>

			</ModalFooterWrapper>
					
		</template>
	</ModalWrapper>


</template>
<script>
import ModalWrapper from '@/components/ui/modal/ModalWrapper.vue'
import ModalFooterWrapper from '@/components/ui/modal/ModalFooterWrapper.vue'
import Button from '@/components/ui/buttons/Button.vue'
import ProfilePictureUpload from '@/components/uploads/profile-picture-upload.vue'
import UploadGallery from '@/components/uploads/upload-gallery.vue'

export default {
	components: {
		ModalWrapper,
		ModalFooterWrapper,
		Button,
		ProfilePictureUpload,
		UploadGallery
  },
  props: {
		identity:{
			type:String,
		},
		isOpen:{
			type:Boolean,
			required: true,
			default:false
		},
    venue: {
      type: Object,
      required: true,
    },
  },
	emits:["closed", "updated"],
  data() {
    return {
			regions:null,
			venueTags:null,
			isLoading:false,

      form: {
        name: '',
        about: '',
        venueTags: [],
        region: '',
        icon: '',
				gallery:[]
      },
    }
  },
  computed: {
		hasData(){
			if(this.regions && this.venueTags){
				return true;
			}
			return false;
		},
		formDisabled() {
      return false
    },
		isFormLoading(){
			return false
		},
		submitButtonLabel(){
			return this.isFormLoading ? 'Applying' : 'Apply'
		}
  },

  mounted() {

		this.isLoading = true;
		Promise.all([
			// Venue
			this.$store.dispatch('regions/getRegions')
			.then((response) => {
				this.regions = response;
			}),
			//  Tags
			this.$store.dispatch('venue/getTags')
			.then((response) => {
				this.venueTags = response;
			})
		]).finally(() => {
			this.form.name = this.venue.name;
			this.form.venueTags = this.venue.venue_tags.map((tag) => tag.id);
			this.form.region = this.venue.regions[0].id;
			this.form.about = this.venue.about;
			this.form.icon = this.venue.icon;
			this.form.gallery = this.venue.gallery;
			//
			this.isLoading = false;
		});
  },
  methods: {
		profilePictureUploaded(image) {
			this.form.icon = image
		},
    onGalleryChanged(images) {
      this.form.gallery = images;
    },
		onClose(){
			this.$emit('closed')
		},
		onSave(){
			this.$emit('updated', this.form)
		}
  }
}
</script>
