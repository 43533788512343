<template>
  <div id="app">
    <notifications  position="top right"  :duration="1200">

    </notifications>
    <div class="home">
      <div class="min-h-screen flex bg-white-500">
        <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
        <NavMobile />

        <!-- Static sidebar for desktop -->
        <NavDesktopSidebar />

        <div class="flex flex-col w-0 flex-1 overflow-hidden">
          <NavHeader />

          <main class="flex-1 relative  focus:outline-none mb-120px">
            <div class="lg:flex flex-row min-h-screen">
              <router-view class="3xl:min-w-75% lg:min-w-full lg:mr-10 app-route  " :key="$route.fullPath" /> 
            </div> 
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavDesktopSidebar from "./components/nav/NavDesktopSidebar.vue";
import NavMobile from "./components/nav/NavMobile.vue";
import NavHeader from "./components/nav/NavHeader.vue";
import {layoutMethods} from '@/state/modules/layout.js';
import client from './utils/client';

export default {
  name: "App",
  methods: {
    ...layoutMethods,
    loadIntercom(){
          
        let $user = this.$store.getters['user/userData'];
        
        if ($user && (window.location.hostname == "app.gigpig.uk" || window.location.hostname == "localhost"))
        {

          client.get('/intercom/profiles')
            .then((resp) => { 

              let intercomObject = resp.data.data;
              intercomObject.api_base =  "https://api-iam.intercom.io";
              intercomObject.app_id = "r4g5pt4f";
               window.intercomSettings = intercomObject;
              (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/r4g5pt4f';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

            });

        } else {
            console.log("auth not initiated", $user);
        }

    }
  },
   mounted(){


  },
  computed: {
    userData(){
				return this.$store.getters['user/userData']
			},
  },
  watch: {
    '$route' () {      
          this.setToggleMobileMenu(false);
    },
    userData() {
				this.loadIntercom();
    },
    
  },
  metaInfo() {
    return { 
      title: 'GigPig',
      titleTemplate: '%s',
      meta:[
        {
          name: 'viewport',
          content:  'width=device-width,initial-scale=1,user-scalable=no'
        }
      ],
      link:[
        { rel: 'icon', href: 'favicon.svg'},
        { rel: 'alternate icon', href: 'favicon.ico'}
      ]
    }
  },
  components: {
    NavMobile,
    NavDesktopSidebar,
    NavHeader,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.form .datepick div[tabindex^='-1'] {
     max-width:50px;
}

 
</style>
