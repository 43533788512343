<template>
  <div
    transition="fadein"
    class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal "
    ref="modal"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-center justify-center min-h-screen pt-4 px-1 lg:px-4 lg:pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

    <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-12">
      <div>
        <div class="mx-auto flex items-center justify-center ">
          <img src="@/assets/images/Logo_ind.svg" alt="Gig Pig." class="w-32" />
        </div>
        <div class="mx-auto my-12 flex justify-center">
          <ul role="list" class="flex">
            <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
              <div class="flex-1 flex flex-col p-8">
                <img class="w-16 h-16 flex-shrink-0 mx-auto rounded-full" :src="$auth.user.picture" :alt="$auth.user.name" />
                <h3 class="mt-6 text-gray-900 text-sm font-medium"></h3>
                <dl class="mt-1 flex-grow flex flex-col justify-between">
                  <dt class="sr-only">Title</dt>
                  <dd class="text-gray-500 text-sm">{{$auth.user.email}}</dd>
                  <dt class="sr-only">Role</dt>
                  <dd class="mt-3">
                    <span class="pill-status positive" v-if="isArtist">Artist Manager</span>
                    <span class="pill-status positive" v-else>Event Manager</span>
                  </dd>
                </dl>
              </div>
            </li>
          </ul>
        </div>

          <div class="text-center sm:mt-5">
            <h3
              class="mt-8 font-bold leading-6 text-2xl text-gray-900"
              id="modal-title"
            >
              <transition
                appear
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
              >
                <span v-text="loadingText"></span>
              </transition>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import router from "../../router/index.js";
import { apiMethods } from '@/state/helpers'

export default {
  name: "AuthCallback",
  data: function() {
    return {
      loadingText: "Please wait...",
      isArtist: false
    };
  },
  props: {
    "usertype": String,
  },
  methods:{
    ...apiMethods
  },
  async mounted() {


    this.$notify('Looking for your user profile...');

    if (localStorage && localStorage.initialRoute == 'artist'){
      this.isArtist = true;
    }

    const token = await this.$auth.getTokenSilently();
    const claims = await this.$auth.getIdTokenClaims();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const payload = {
      id: claims.__raw,
      config: config, 
    }
 
      let self = this;

      this.authCallback(payload).then(response => {


        // eslint-disable-next-line no-undef
        self.loadingText = response.message;
 
        if (response.data.is_new == true){
          // eslint-disable-next-line no-undef
          if(typeof fbq !== 'undefined'){            
            console.log("fbq found");

          // eslint-disable-next-line no-undef
              fbq('track', 'CompleteRegistration'); // fbq defined at GTM level
              console.log("new user push fbq event");
          }
        }

				// fbq has no callback, assume 1.5s artifical delay        
            setTimeout(() => {
               
              let route;
              
              if (response.data.is_artist)
              {
                this.$notify('Taking you to the artist dashboard');
                  route = "/artist";
              }
              else if (response.data.is_venue)
              {
                this.$notify('Taking you to the venue dashboard');
                route = "/dashboard";
              }
              else{
                route = self.$global.isArtistView() ? '/artist' : '/dashboard';
              }
              
              router.push(route);       
            }, 1500);         
      });




    }
}
</script>
