<template>    
    <div class="space-y-2"> 
        <router-link to="/dashboard" class="hover:bg-indigo-600  hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md">
            <HomeIcon /> 
            <span class="ml-2">Dashboard</span>
        </router-link>


        <h2 class="ml-3 font-semibold py-4">Management</h2>

        <router-link  to="/venues" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
            <OfficeBuildingIcon/>
            <span class="ml-2">Venues</span>
        </router-link>

				<template v-if="hasAllUserData">

					<template v-if="hasViewCalendarPermission">
						<router-link to="/calendar" class="text-indigo-100 hover:bg-indigo-600  group flex items-center px-2 py-2 text-sm font-medium rounded-md">
							<CalendarIcon /> 
							<span class="ml-2">Calendar</span>
						</router-link>
					</template>
					
					
					<template v-if="hasManageGigsPermission">
						<router-link to="/gigs/add" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
							<TicketIcon/>
							<span class="ml-2">Add a new gig</span>
						</router-link>


						<router-link to="/pending-gigs" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
							<ClockIcon />
							<span class="ml-2">Pending gigs</span>
						</router-link>

						
						<router-link to="/past-gigs" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
							<MoonIcon />
							<span class="ml-2">Completed gigs</span>
						</router-link>
					</template>
					

					<template v-if="hasBillingInvoicesPermission">
						<router-link to="/invoices" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
							<CurrencyPoundIcon />
							<span class="ml-2">Billing & invoices</span>
						</router-link>
					</template>

					
				</template>
       
        <h2 class="ml-3 font-semibold py-4">Tools</h2>

				<template v-if="hasAllUserData && hasManageGigsPermission">
					<router-link to="/gigtemplates" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
							<TemplateIcon />
							<span class="ml-2">Gig templates</span>
					</router-link>
				</template>
       

        <router-link to="/artist-directory" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
            <MusicNoteIcon />
           <span class="ml-2">Artist directory</span>
        </router-link>

        <router-link to="/favourites"   class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
            <HeartIcon />
           <span class="ml-2">My favourite artists</span>
        </router-link>

        <router-link to="/messages" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
            <AnnotationIcon/>
            <span class="ml-2">Messages</span>
        </router-link>

        

        <a href="#" class="hidden text-indigo-100 hover:bg-indigo-600 group items-center px-2 py-2 text-sm font-medium rounded-md">
            <MailIcon/>
            <span class="ml-2">Email templates</span>
        </a> 

        

				
    </div>
</template>

<script>


import { HomeIcon, CalendarIcon, TemplateIcon, TicketIcon, OfficeBuildingIcon, MusicNoteIcon, AnnotationIcon, MailIcon, HeartIcon, ClockIcon, CurrencyPoundIcon, MoonIcon  } from "@vue-hero-icons/outline";


export default {
    name: "NavEvent",
    components: { HomeIcon, CalendarIcon, TemplateIcon, TicketIcon, OfficeBuildingIcon, MusicNoteIcon, AnnotationIcon, MailIcon, HeartIcon, ClockIcon, CurrencyPoundIcon, MoonIcon },

		computed:{
			hasAllUserData(){
				return this.$store.getters['user/hasAllUserDataLoaded']
			},
			hasManageGigsPermission(){
				return this.$store.getters['user/hasManageGigsPermission']
			},
			hasViewCalendarPermission(){
				return this.$store.getters['user/hasViewCalendarPermission']
			},
			hasBillingInvoicesPermission(){
				return this.$store.getters['user/hasBillingInvoicesPermission']
			},
			hasManageTeamMembersPermission(){
				return this.$store.getters['user/hasManageTeamMembersPermission']
			}
		}

}
</script>