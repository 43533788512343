<template>

	<div>
		
		<form class="form" ref="form" novalidate>
									
				<div class="sm:col-span-8 col-span-full form-select lg:grid grid-flow-row">
						<label for="recurrence" class="mb-4">
								Recurrence type
						</label>
						<t-select name="recurrence" ref="recurrence" class="w-full" v-model="recurrence"
						@change="onUpdate"
						placeholder="Select an option"
						value-attribute="value"
						text-attribute="text"
						:options="[
							{ value: 6, text: 'Select date(s)'},
							// { value: 1, text: 'Single date'},
							{ value: 2, text: 'Daily'},
							{ value: 3, text: 'Weekly'},
							{ value: 4, text: 'Fortnightly'},
							{ value: 5, text: 'Monthly'} 
						]"></t-select>
				</div>
				<template v-if="recurrence">
					<div class="datepick sm:col-span-8 grid-cols-1 lg:grid-cols-2 lg:grid grid-flow-col gap-4" >  
						<div class="col-span-full lg:col-span-1 input-content ">               
							<template v-if="!canSelectMultiple">
								<label for="event-start">
										{{recurrence == 1 ? 'Date to generate' : 'Start from'}}
								</label>
								<TDatePicker 
									dateFormat="Z" 
									userFormat="D d/m/Y" 
									initialTime 
									required 
									v-model="start" 
									:multiple="canSelectMultiple"
									:timepicker="false" 
									@change="onStartDateSelected"
								/>
							</template>
							<template v-else>
									<label>Date(s)</label>
									<TDatePicker 
										dateFormat="Z" 
										userFormat="D d/m/Y" 
										:multiple="canSelectMultiple"
										:timepicker="false" 
										required 
										v-model="repeats" 
										placeholder="Select date(s)"
									/>
							</template>
						</div>
							
						<div v-if="canRepeatFrequency">
							<InputText 
								required 
								id="repeatFrequency" 
								max="52" 
								ref="repeatFrequency" 
								v-on:input="frequency = $event;"
								label="Recurrence length" 
								hint="The frequency must be completed."
								error="The frequency must be completed." 
								type="number" 
								placeholder="0" 
								name="repeatFrequency" 
								prefix="for" 
								:suffix="frequencyLabel + '(s)'"
								@input="onUpdate"
							/>
						</div>
					</div>
				</template>
				
		</form>


						

			
		<div class="action-bar">   
			<GPButton 
				@click.native="generateRepeats" 
				:disabled="getPreviewDisabled" 
				:loading="isLoading"
				size="lg"
			>{{ getPreviewLabel }}</GPButton> 
		</div>


	</div>
	

</template>


<script> 


import { DateTime } from 'luxon';
import normalizeDate from '../../../utils/normalizeDate';
import InputText from '../../form/InputText.vue';
import TDatePicker from 'vue-tailwind/dist/t-datepicker'; 
import { TSelect, } from 'vue-tailwind/dist/components';
import GPButton from '../../ui/buttons/Button.vue';

export default {
    name: "GigRepeat",
    components:{
			TSelect,
			InputText,
			TDatePicker,
			GPButton,
		}, 
		props:{
			frequencyLabel:{
				type:String,
				required: true,
			},
			gig:{
				type:Object,
				required: true,
			},
		},
		emits:["cancel", "confirm", "update"],
    data (){
			return {  
				isLoading:false,
				repeats: [],
				recurrence: 0,
				frequency: 0,
				start: Date.now(),
				selectedStart: Date.now(),
				hasStartDateBeenSelected:false
			}
    }, 
		computed: { 
			canRepeatFrequency(){
				if(!this.recurrence){
					return false
				}
				const recInt  = parseInt(this.recurrence)
				return recInt !== 1 && recInt !== 6;
			},
			canSelectMultiple(){
				if(!this.recurrence){
					return false
				}
				return parseInt(this.recurrence) === 6;
			},
			getPreviewDisabled(){
				const recInc = parseInt(this.recurrence);
				if(recInc === 6){
					return !this.repeats.length > 0;
				}else{
					return false;
				}
			},
			getPreviewLabel(){
				return !parseInt(this.recurrence) ? 'Generate date'  : 'Preview dates'
			},
    },
		watch:{
			gig(updatedGig){
				this.updateStartTime(updatedGig)
			}
		},
    methods:{
			updateStartTime(gig){
				console.log("gig.start")
				console.log(gig.start);
				// console.log(normalizeDate(gig.start))
				console.log(DateTime.fromISO(gig.start, {zone: 'utc'}))
				// console.log(DateTime.fromISO(normalizeDate(gig.start)).toUTC())
				// this.start = [ DateTime.fromISO(gig.start, {zone: 'utc'}).toUTC() ];
				this.start = normalizeDate(gig.start);
			},
			getRepeatData(){
				return {
					recurrence: this.recurrence,
					frequency: this.frequency,
					isMultiple: this.canSelectMultiple,
					repeats: this.repeats,
					start: this.start,
					selectedStart: this.selectedStart,
				}
			},
			onUpdate(){
				const repeatData = this.getRepeatData();
				this.$emit('update', repeatData)
			},
			onStartDateSelected(){
				if(!this.hasStartDateBeenSelected){
					this.hasStartDateBeenSelected = true;
				}
			},
			generateRepeats(){

				const hours = new Date(normalizeDate(this.gig.start)).getHours();
				const minutes = new Date(normalizeDate(this.gig.start)).getMinutes();

				if(this.canSelectMultiple){
					// convert repeats to UTC
					this.repeats = this.repeats.map(d => {
						let utc = DateTime.fromISO(d).toUTC();
						utc = utc.set({hours: hours, minute: minutes });
						return utc
					})
				}else{
					this.repeats = [];
					let date = DateTime.fromISO(this.start).toUTC();  
					if(!this.hasStartDateBeenSelected){
						// if the start date has not been selected then use the gig date setting the utc zone
						date = DateTime.fromISO(this.gig.start, {zone: 'utc'}).toUTC()
					}
					//
					date = date.set({hours: hours, minute: minutes });
					// 
					this.selectedStart = date; 
					console.log(">");
					console.log(this.selectedStart);
					// 
					let frequency = parseInt(this.frequency); 
					let recurrence = parseInt(this.recurrence);

					if (frequency == undefined){
							frequency = 1;
					}

					if (frequency === 0){ 
							this.repeats.push(date);
					}

					for (let i = 0; i < frequency; i++)
					{
						if (recurrence === 2)
						{
							if (i > 0) // if repeating 1 day, just choose initial day
							{                    
								date = date.plus({ days: 1});  
							} 
						}
						else if (recurrence === 3)
						{  
							date = date.plus({ days: 7});  
							console.log(date)
						}
						else if (recurrence === 4)
						{ 
							date = date.plus({ days: 14 }); 
						}
						else if (recurrence === 5)
						{
							date = date.plus({ months: 1}); 
						} 
						this.repeats.push(date);
					}
				}
				//
				const repeatData = this.getRepeatData();
				this.$emit('confirm', repeatData)
			}  
    },
		mounted(){
			this.updateStartTime(this.gig);
		}
}
</script>